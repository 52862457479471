.banner-js {
    position: relative;

    .main-banner {
        // min-height: calc(100vh - 232.5px);
        min-height: 650px;
        @include commonBackground();
        overflow: hidden;

        .container {
            > div {
                min-height: 450px;

                img {
                    object-fit: contain;
                    object-position: top;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .active-banner {
            width: 100%;
            position: relative;
            background-color: #ccc;
            background: $medGray;

            .active-image {
                width: 100%;
                height: 650px;
                min-height: 400px;
                transition: $transitionFast;
                overflow: hidden;
                position: relative;

                .text-details {
                    color: #fff;
                    flex: 1;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background-color: rgba(0, 0, 0, 0.6);

                    .search-form {
                        i {
                            transition: $transitionFast;

                            &.scaled {
                                transform: scale(1.5);
                            }
                        }

                        .input-group {
                            background-color: #fff;
                            padding: 20px 30px;
                            border-radius: 20px 0 0 20px;

                            input {
                                border: none;
                                outline: none;
                            }

                            @media (max-width: 767px) {
                                padding: 10px 15px;
                                border-radius: 10px 0 0 10px;

                                input {
                                    font-size: 1rem;
                                }
                            }
                        }

                        button {
                            &.btn-primary {
                                border-radius: 0 20px 20px 0;
                                height: 100%;
                                padding-left: 40px;
                                padding-right: 40px;

                                @media (max-width: 767px) {
                                    padding-left: 20px;
                                    padding-right: 20px;
                                    border-radius: 0 10px 10px 0;
                                }
                            }
                        }
                    }
                }

                .image-details {
                    height: 100%;
                    width: 100%;
                    position: relative;

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        object-fit: cover;
                        object-position: center;
                        width: 100%;
                        height: 100%;

                        &.image-transition {
                            opacity: 0;
                        }
                    }

                    .embed_video {
                        position: absolute;
                        left: 0;
                        top: 0;
                        object-fit: contain;
                        object-position: center;
                        width: 100%;
                        height: 100%;
                        background: #111;

                        .iframe-container {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;

                            iframe {
                                position: absolute;
                                height: 100%;
                                width: 100%;
                            }
                        }
                    }
                }
            }

            // .transition-overlay {
            // 	position: absolute;
            // 	width: 0;
            // 	height: 100%;
            // 	left: 0;
            // 	top: 0;
            // 	// background: $defaultGradient;
            // 	overflow: hidden;

            // 	> div {
            // 		position: relative;
            // 		width: 100%;
            // 		height: 100%;

            // 		.text-details {
            // 			color: #fff;
            // 			flex: 1;
            // 		}

            // 		.image-details {
            // 			height: 100%;
            // 			position: relative;

            // 			img {
            // 				position: absolute;
            // 				left: 0;
            // 				top: 0;
            // 				object-fit: contain;
            // 				object-position: center;
            // 				width: 100%;
            // 				height: 100%;
            // 			}

            // 			.embed_video {
            // 				position: absolute;
            // 				left: 0;
            // 				top: 0;
            // 				width: 100%;
            // 				height: 100%;
            // 				background: #111;
            // 			}
            // 		}
            // 	}
            // }

            &.in-transition {
                .active-image {
                    // .text-details {
                    // 	opacity: 0;
                    // 	transform: translateY(20%);
                    // 	animation-name: flyIn;
                    // 	animation-duration: 0.6s;
                    // 	animation-delay: 0.5s;
                    // 	animation-fill-mode: forwards;
                    // }

                    .image-details {
                        // opacity: 0;
                        // transform: translateY(20%);
                        // animation-name: flyIn;
                        // animation-duration: 0.6s;
                        // animation-delay: 0.8s;
                        // animation-fill-mode: forwards;

                        img {
                            &:not(.image-transition) {
                                opacity: 0;
                                animation-name: fadeIn;
                                animation-duration: 0.6s;
                                animation-fill-mode: forwards;
                            }

                            &.image-transition {
                                opacity: 1;
                            }
                        }
                    }
                }

                // .transition-overlay {
                // 	width: 100%;

                // 	> div {

                // 		.text-details {
                // 			transition: 0.6s;
                // 			transform: translateY(-20%);
                // 			opacity: 0;
                // 		}

                // 		.image-details {
                // 			transition: 0.6s;
                // 			transition-delay: 0.3s;
                // 			transform: translateY(-20%);
                // 			opacity: 0;
                // 		}
                // 	}
                // }
            }
        }
    }

    .banner-thumbnails {
        position: absolute;
        width: 100%;
        bottom: 20px;
        left: 0;

        .thumb {
            width: 50px;
            height: 5px;
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.5);
            margin: 0 10px;
            position: relative;
            overflow: hidden;

            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: #fff;
                transform: translateX(100%);
            }

            &.active {
                pointer-events: none;

                &::after {
                    transform: translateX(0);
                }
            }
        }

        &.next {
            pointer-events: none;

            .thumb {
                &.active-out {
                    &::after {
                        transform: translateX(0);
                        animation-name: thumbOutNext;
                        animation-duration: 0.5s;
                        animation-fill-mode: forwards;
                    }
                }

                &.active {
                    &::after {
                        transform: translateX(-100%);
                        animation-name: thumbInNext;
                        animation-duration: 0.5s;
                        animation-fill-mode: forwards;
                    }
                }
            }
        }

        &.prev {
            pointer-events: none;

            .thumb {
                &.active-out {
                    &::after {
                        transform: translateX(0);
                        animation-name: thumbOutPrev;
                        animation-duration: 0.5s;
                        animation-fill-mode: forwards;
                    }
                }

                &.active {
                    &::after {
                        transform: translateX(-100%);
                        animation-name: thumbInPrev;
                        animation-duration: 0.5s;
                        animation-fill-mode: forwards;
                    }
                }
            }
        }
    }

    &.banner-slider {
        @media (max-width: 991px) {
            .main-banner {
                position: relative;

                .active-banner {
                    .active-image,
                    .transition-overlay {
                        > div {
                            flex-direction: column-reverse;

                            .image-details {
                                width: 100%;
                                margin: 20px 0;
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 767px) {
            .main-banner {
            }

            .banner-thumbnails {
                position: relative;
                margin-bottom: 20px;
                width: 100%;
            }

            .text-details {
                padding: 0 10px;

                .margin-left-50 {
                    margin-left: 10px;
                }

                .margin-right-50 {
                    margin-right: 10px;
                }
            }
        }

        @media (max-width: 400px) {
            .main-banner {
            }

            .banner-thumbnails {
                position: relative;
                margin-bottom: 20px;
                width: 100%;
            }

            .text-details {
                .txt-header-2 {
                    font-size: 14px;
                }

                .txt-oversized {
                    font-size: 20px;
                }
            }
        }
    }

    // @media screen and (max-width: 767px) {
    // 	&.main-banner {
    // 	}
    // }

    @keyframes flyIn {
        from {
            opacity: 0;
            transform: translateY(20%);
        }

        to {
            opacity: 1;
            transform: translateY(0%);
        }
    }

    @keyframes thumbOutNext {
        from {
            transform: translateX(0%);
        }

        to {
            transform: translateX(100%);
        }
    }

    @keyframes thumbOutPrev {
        from {
            transform: translateX(0%);
        }

        to {
            transform: translateX(-100%);
        }
    }

    @keyframes thumbInNext {
        from {
            transform: translateX(-100%);
        }

        to {
            transform: translateX(0%);
        }
    }

    @keyframes thumbInPrev {
        from {
            transform: translateX(100%);
        }

        to {
            transform: translateX(0%);
        }
    }
}
