.login-container {
    background-color: $lYellow;
    position: relative;

    .login {
        max-width: 430px;
        width: 100%;
        margin: auto;

        .icon-badge {
            text-align: center;

            i {
                width: 80px;
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 5px solid $orange;
                background: $orange;
                color: #fff;
                transform: translateY(-50%);
                border-radius: 100%;
                font-size: 40px;
                margin: auto;
                box-shadow: 0 5px 10px -3px rgba(0, 0, 0, 0.3);
            }
        }

        .login-header {
            font-weight: bold;
        }

        .login-form {
            padding: 20px;

            .form-group {
                margin-bottom: 10px;
            }
        }

        input[type="password"] {
            letter-spacing: 3px;
        }

        input[type="password"]::placeholder {
            letter-spacing: normal;
        }

        .submit-btn {
            margin-top: 20px;
        }

        .registration-link {
            margin-top: 20px;

            .arti-link {
                &:hover {
                    color: darken($lGreen, 10%);
                }
            }
        }

        .separator {
            position: relative;
            padding-top: 5px;
            padding-bottom: 5px;
            text-align: center;

            &::before {
                content: "";
                border-bottom: 1px solid $lighterGray;
                position: absolute;
                width: 100%;
                top: 55%;
                left: 0;
            }

            span {
                position: relative;
                background-color: $lYellow;
                padding-left: 30px;
                padding-right: 30px;
                color: $lGreen;
                font-size: 12px;
            }
        }

        .fa-facebook-f,
        .fa-google {
            width: 17px;
            height: 17px;
            border-radius: 100px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 5px;
            padding: 10px;
        }

        .fa-facebook-f {
            background-color: #4267b2;
            color: $white;
            transform: scale(1.2);
        }

        .fa-google {
            transform: scale(1.5);
        }

        @media (max-width: 767px) {
            .icon-badge {
                i {
                    width: 60px;
                    height: 60px;
                    font-size: 30px;
                }
            }
        }

        @media (max-width: 450px) {
            .login-options-container {
                display: block;

                .forgot-password {
                    text-align: center !important;
                    border: 1px solid $medGray;
                    padding: 5px;
                    margin-top: 20px;
                }
            }
        }
    }
}
