// .order-status-page{
//     padding-top: $navTopSpacing;
//     min-height: 500px;
//     h1 {
//         font-family: $quicksand-regular;
//     }
//     @media (max-width: 991px) {
//         padding-top: $navTopMobileSpacing;
//     }
//     .order-image-list {
//         display: -webkit-box;
//         display: flex;
//         justify-content: center;
//         flex-wrap: wrap;
//         flex-direction: row;
//         max-width: 460px;
//         margin: auto;
//         padding-top: 15px;
//         margin-bottom: 75px;
//         li {
//             max-width: 80px;
//             height: 80px;
//             -webkit-box-flex: 1;
//             -ms-flex: 1 1 80px;
//             flex: 1 1 80px;
//             background-repeat: no-repeat;
//             background-size: cover;
//             background-position: center center;
//             margin-right: 15px;
//             margin-bottom: 15px;
//         }
//     }
// }

.order-status-page {
    .txt-green {
        color: #1d600c;
    }
    .container {
        .bg-yellow {
            background-color: #fcf7ea;
        }

        .txt-14-px {
            font-size: 14px !important;
        }

        .customer-info {
            display: grid;
            grid-template-columns: 1fr 1fr;
            border: 1px solid #cbcbcb;

            > div:not(:last-child) {
                margin-bottom: 20px;
            }
            div {
                line-height: 20px;
            }

            h5,
            p {
                font-family: $cerapro-bold;
                color: $black;
                margin-bottom: 0.25rem;
                font-size: $medium;
                line-height: 20px;
            }

            p {
                font-family: $cerapro-regular;
                color: #6f6f6f;
            }
        }

        .order-summary {
            background-color: #f4f4f4;
            padding: 15px 10px;
            border-radius: 4px;

            .items {
                .item {
                    display: grid;
                    grid-template-columns: 60px 1fr;
                    gap: 10px;

                    img {
                        width: 100%;
                        object-position: center;
                        object-fit: contain;
                    }
                }
            }
        }
    }
    @media (max-width: 430px) {
        .container .customer-info {
            grid-template-columns: 1fr;
        }
    }
}
