.footer {
    background-color: $green;
    position: relative;

    &--bg-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
        object-position: center;
        opacity: 0.1;
    }

    .landscape {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -32%);
        pointer-events: none;
    }

    > .page-container {
        position: relative;
        padding: 0 0 50px 0;

        .footer--quicklink {
            opacity: 0.8;
            transition: $transitionFast;

            i {
                width: 20px;
            }

            &:hover {
                opacity: 1;
            }
        }

        .footer-store-details {
            width: 100%;

            > div {
                // padding-left: 25%;
            }

            > :first-child {
                border-bottom: 1px solid #cbcbcb;
                padding-bottom: 20px;
            }

            > :nth-child(2) {
                margin-top: 20px;
            }

            .copy-right {
                max-width: 240px;
                // text-align: left;
                img{                   
                    height: 60px;
                }
            }

            .contacts {
                max-width: 240px;
                text-align: left;
            }
            .grid-150-1fr {
                display: grid;
                grid-template-columns: 300px 1fr;
                grid-gap: 10px;
                // justify-content: space-between;
                // flex-wrap: wrap;
                padding-left: 20%;
                padding-right: 20%;
            }
            .shops,
            .soc-med {
                flex-wrap: wrap;
                padding-left: unset;
                padding-right: unset;
            }
            .btn {
                margin-bottom: 20px;
            }
        }

        .cc-details {
            img {
                max-width: 70px;
                width: 100%;
                margin-right: 10px;
            }
        }

        .shops {
            a {
                height: 40px;
                width: 150px;
                display: flex;
                align-content: center;
                justify-content: center;

                img {
                    width: 100px;
                    height: 21px;
                    object-fit: contain;
                    object-position: center;
                }
            }
        }
    }

    @media (max-width: 1530px) {
        > .page-container .footer-store-details .grid-150-1fr {
            padding-left: 15%;
            padding-right: 10%;
        }
    }
    @media (max-width: 1250px) {
        > .page-container .footer-store-details .grid-150-1fr {
            padding-left: 10%;
        }
    }
    @media (max-width: 1185px) {
        > .page-container .footer-store-details .grid-150-1fr {
            padding-left: 5%;
        }
    }

    @media (max-width: 991px) {
        .justify-unset {
            justify-content: unset !important;
        }
        > .page-container {
            max-width: 100%;
            padding: 0 10px;

            .footer-store-details {
                max-width: 100%;
                text-align: center;

                .flex {
                    justify-content: center;
                    padding-left: 10%;
                    padding-right: 10%;
                }
                .grid-150-1fr {
                    grid-template-columns: 1fr;
                    grid-gap: 20px;
                    justify-items: center;
                    padding-left: unset;
                    padding-right: unset;
                }
                // .upper{
                //     justify-content: center;
                // }
                .margin-right-50 {
                    margin-right: 15px !important;
                }
            }

            .pad-top-100 {
                padding-top: 30px;
            }
        }
    }

    @media (max-width: 767px) {
        .quicklinks {
            text-align: center;
            padding-bottom: 30px;

            .category-header {
                padding-top: 30px;
            }
        }
        .landscape {
            top: -20px;
        }
    }

    @media (max-width: 500px) {
        > .page-container .footer-store-details .flex {
            padding-left: unset;
            padding-right: unset;
        }
    }
}
