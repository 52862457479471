.float-in-up-down {
    animation-name: floatInUpDown;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    opacity: 0;
    transform: translateY(50%);
}

.fade-in {
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    opacity: 0;
}

.fade-in-up {
    animation-name: fadeInUp;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.fade-in-down {
    animation-name: fadeInDown;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.pulse {
    animation-name: pulse;
    animation-duration: 0.3s;

    &-inwards {
        animation-name: pulse-inwards;
        animation-duration: 0.3s;
    }

    &-heart {
        animation-name: pulseHeart;
        animation-duration: 5s;
    }
}

.infinite-animation {
    animation-iteration-count: infinite;
}

.scale-in {
    animation-name: scaleIn;
    animation-duration: 0.5s;
    animation-duration: linear;
    animation-fill-mode: forwards;
    transform: scale(0);
}

@keyframes scaleIn {
    from {
        transform: scale(0);
    }

    25% {
        transform: scale(1.3);   
    }

    35% {
        transform: scale(1.4);
    }

    to {
        transform: scale(1);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20%);
    }

    to {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20%);
    }

    to {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes growIn {
    from {
        transform: scale(0);
    }
    
    to {
        transform: scale(1);
    }
}

@keyframes slideLeft {
    from {
        width: 100%;
    }
    
    to {
        width: 0%;
    }
}

@keyframes float {
    from,
    to {
        transform: translateY(10%);
    }

    25% {
        transform: translateY(0%);
    }

    50% {
        transform: translateY(-10%);
    }

    75% {
        transform: translateY(0%);
    }
}

@keyframes floatInUpDown {
    from {
        opacity: 0;
        transform: translateY(50%);
    }

    50% {
        transform: translateY(-10%);
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes floatInDown {
    from {
        top: -10px;
    }

    to {
        top: 0;
    }
}

@keyframes pulse {
    from,
    to {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }
}

@keyframes pulse-inwards {
    from,
    to {
        transform: scale(1);
    }

    50% {
        transform: scale(0.5);
    }
}

@keyframes pulseHeart {
    from,
    to {
        transform: scale(1);
    }

    4% {
        transform: scale(1.5);
    }

    8% {
        transform: scale(1);
    }

    12% {
        transform: scale(1.5);
    }

    16%,
    to {
        transform: scale(1);
    }
}

@keyframes jump {
    from,
    to {
        transform: translateY(-10%);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
    }

    50% {
        transform: translateY(0);
        box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.5);
    }
}