.blogs {
    padding-bottom: 50px;

    .page-banner-image {
        .banner-text {
            max-width: 800px;
            width: 100%;

            .background-overlay {
                background: rgba(0, 0, 0, 0.5);
                padding: 20px;
            }
        }
    }

    .blog-list {
        grid-gap: 30px;
    }

    .share-list {
        i {
            width: 35px;
            height: 35px;
            background: $lightGray;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            transition: $transitionFast;

            &:hover {
                transform: scale(1.2);
                background: $black;
                color: #fff;
            }
        }
    }

    .blog-content {
        min-width: 800px;

        .author-details {
            
            .author-image {
                
                img {
                    width: 150px;
                    height: 150px;
                    border-radius: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }

    .related-products {
        max-width: 300px;
        width: 100%;
    }

    .related-articles {
        border-top: 2px solid $lightGray;
    }

    @media (max-width: 1200px) {
        .blog-content {
            min-width: 700px;
        }
    }

    @media (max-width: 991px) {

        .banner-text {
            padding: 50px;
        }

        .blog-view-outer-container {
            display: block;

            .blog-content {
                min-width: initial;
                margin: auto;
            }
        }

        .related-products {
            max-width: 100%;

            .related-products-container {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: 20px;
            }
        }
    }

    @media (max-width: 767px) {

        .banner-text {
            padding: 20px;
        }

        .grid-3 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        .blog-content {
            .author-details {
                text-align: center;
                display: block;

                .margin-left-20 {
                    margin-left: 0px;
                    margin-top: 10px;
                }
            }
        }
    }

    @media (max-width: 550px) {
        
        .grid-3 {
            grid-template-columns: 1fr;

            .article-card {
                margin-top: 30px;

                .btn {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        .related-products {

            .related-products-container {
                grid-template-columns: 1fr 1fr;
            }
        }
    }

    @media (max-width: 360px) {

        .banner-text {
            
            .btn {
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .related-products {
            
            .related-products-container {
                grid-template-columns: 1fr;
            }
        }
    }
}