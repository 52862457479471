.contact-us {
    background-color: $lYellow;
    position: relative;
    overflow: hidden;
    
    &-container {
        @include commonBackground();
        padding-left: 0;
        padding-right: 0;
        margin-top: 50px;
    }

    .floating-leaf{
        position: absolute;
        pointer-events: none;
        // bottom: 0;

        &.left{
            left: 0;
            &-bottom {
                transform: translate(-21%, 9%);
            }
            &.faded{
                &-75{
                    opacity: 0.75;
                }
            }
        }

        &.right{
            right: 0;
            &-bottom {
                transform: translate(21%, 9%);
            }
            &.faded{
                &-75{
                    opacity: 0.75;
                }
            }
        }
    }

    .with-top-margin{
        margin-top: 20px;
    }

    .contact-information{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 330px;
        .info{
            display: grid;
            grid-gap: 20px;
            grid-template-columns: 30px 1fr;
            .contact-info{
                display: contents;
            }
            img{
                width: 28px;
            }
        }
    }
    
    .grid{
        position: relative;
        z-index: 2;
        display: grid;
        grid-template-columns: 55% 1fr;
        grid-gap: 20px;
    }
    
    .contact-info {
        i {
            width: 20px;
            margin-right: 5px;
        }
    }

    .contact-form {
        max-width: 100%;
        width: 100%;
        border-right: 1px dashed $lightMedGray;
        .text-right{
            text-align: right !important;
        }
        .submit-btn {
            font-weight: 500;
            border: none;
            border-radius: 25px;
            padding: 10px 45px;
            background: $lGreen;
            color: $dirtyWhite;
            max-width: 300px;
        }
        .error{
            position: absolute;
            padding-left: 12px;
            // top: 50px;
        }
    }

    @media (max-width: 991px) {

        &-container {
            display: block;
            padding: 20px 50px;

            > div {
                padding: 0;

                &.form-header {
                    text-align: center;

                    .contact-info {
                        display: flex;
                        justify-content: center;
                        margin-top: 20px;
                    }
                }

                &.contact-form {
                    margin: 50px auto 0;
                }
            }
        }
    }

    @media (max-width: 767px) {

        &-container {
            display: block;
            padding: 20px;

            > div {
                padding: 0;

                &.form-header {
                    .contact-info {
                        display: flex;
                        justify-content: center;
                        margin-top: 20px;
                        text-align: center;
                        font-size: 12px;

                        i {
                            display: block;
                            margin: 15px auto 5px;
                            font-size: 20px;
                        }
                    }
                }

                &.contact-form {
                    margin: 50px auto 0;
                }
            }
        }
        .floating-leaf.left-bottom {
            transform: translate(-22%, 23%);
        }
        .floating-leaf.right-bottom {
            transform: translate(22%, 23%);
        }
        .grid {
            grid-template-columns: 1fr;
        }
        .contact-form {
            padding-right: 0 !important;
            border: none;
        }
        .contact-information {
            min-height: unset;
        }
    }
}