.replacement-options {

    .replacement-option {
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 10px;
        transition: 0.3s;
        
        .icon-holder {
            box-shadow: inset 0 0 2px #111;
            border-radius: 100%;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                transform: scale(0);
                transition: 0.3s;
            }
        }

        &:hover {
            opacity: 0.5;
        }

        &.active {
            .icon-holder {
                i {
                    transform: scale(1);
                }
            }
        }
    }
}

.my-cart-page-container {

    .custom-table {
        display: grid;
        grid-template-columns: auto 1fr 2fr auto 1.5fr auto;
        border: solid 1px $lightGray;
        border-bottom: 0;

        > div {
            padding: 15px;
            border-bottom: solid 1px $lightGray;
        }

        .table-header {
            background-color: $lightGray;
            font-family: $quicksand-bold;
        }
        .image {
            .product-image {
                max-width: 50px;
            }
        }
        .edit-qty-container {
            display: grid;
            grid-template-columns: 40px 70px 40px;

            .btn {
                padding: 5px 0;
                border-radius: 0;

                i {
                    transition: $transitionFast;
                }

                &:hover {

                    i {
                        transform: scale(1.4);
                        color: inherit;
                    }
                }
            }
            input {
                text-align: center;
            }
        }
    }

    .prescription-form {

        .prescriptions {
            display: flex;
            flex-wrap: wrap;

            > div {

                img {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;    
                }
                
                .btn {
                    background: $red;
                    width: 30px;
                    height: 30px;
                    color: #fff;
                    position: absolute;
                    right: 5px;
                    top: 5px;
                }
            }
        }

        .empty-prescription {
            width: 100%;
            height: 150px;
            border: 2px dashed #aaa;
            border-radius: 10px;
            position: relative;

            input {
                opacity: 0;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                position: absolute;
            }
        }
    }

    .mobile-controls {
        display: none;
    }

    @media (max-width: 767px) {

        .custom-table {
            grid-template-columns: 1fr 2fr auto 1fr auto;

            .table-header,
            .sku,
            .product-description {
                display: none;
            }
        }
    }

    @media (max-width: 600px) {

        .custom-table {
            grid-template-columns: auto 2fr;

            .item-price {
                display: none;
            }

            .edit-qty-container {
                grid-template-columns: 30px 40px 30px;
    
                .btn {
                    padding: 5px 0;
                }
            }
        }

        .desktop-controls {
            display: none;
        }

        .mobile-controls {
            display: block;

            &.flex {
                display: flex;
            }
        }

        .button-container {
            display: block;

            .btn {
                display: block;
                margin: 10px auto;
                width: 100%;
            }
        }
    }

    @media (max-width: 380px) {

        .mobile-controls {

            &.flex {
                display: block;

                .total {
                    text-align: left !important;
                    margin-top: 20px;
                }
            }
        }
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}