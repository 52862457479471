/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #009ee2;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #e20612;
  --orange: #fe4f00;
  --yellow: #ffc107;
  --green: #164e36;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #164e36;
  --secondary: #fe4f00;
  --success: #164e36;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #e20612;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #164e36;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #05120d;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2rem; }

h2, .h2 {
  font-size: 1.5rem; }

h3, .h3 {
  font-size: 1.17rem; }

h4, .h4 {
  font-size: 1rem; }

h5, .h5 {
  font-size: 0.83rem; }

h6, .h6 {
  font-size: 0.67rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -webkit-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -webkit-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -webkit-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -webkit-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -webkit-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -webkit-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-order: -1;
          order: -1; }

.order-last {
  -webkit-order: 13;
          order: 13; }

.order-0 {
  -webkit-order: 0;
          order: 0; }

.order-1 {
  -webkit-order: 1;
          order: 1; }

.order-2 {
  -webkit-order: 2;
          order: 2; }

.order-3 {
  -webkit-order: 3;
          order: 3; }

.order-4 {
  -webkit-order: 4;
          order: 4; }

.order-5 {
  -webkit-order: 5;
          order: 5; }

.order-6 {
  -webkit-order: 6;
          order: 6; }

.order-7 {
  -webkit-order: 7;
          order: 7; }

.order-8 {
  -webkit-order: 8;
          order: 8; }

.order-9 {
  -webkit-order: 9;
          order: 9; }

.order-10 {
  -webkit-order: 10;
          order: 10; }

.order-11 {
  -webkit-order: 11;
          order: 11; }

.order-12 {
  -webkit-order: 12;
          order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-order: -1;
            order: -1; }
  .order-sm-last {
    -webkit-order: 13;
            order: 13; }
  .order-sm-0 {
    -webkit-order: 0;
            order: 0; }
  .order-sm-1 {
    -webkit-order: 1;
            order: 1; }
  .order-sm-2 {
    -webkit-order: 2;
            order: 2; }
  .order-sm-3 {
    -webkit-order: 3;
            order: 3; }
  .order-sm-4 {
    -webkit-order: 4;
            order: 4; }
  .order-sm-5 {
    -webkit-order: 5;
            order: 5; }
  .order-sm-6 {
    -webkit-order: 6;
            order: 6; }
  .order-sm-7 {
    -webkit-order: 7;
            order: 7; }
  .order-sm-8 {
    -webkit-order: 8;
            order: 8; }
  .order-sm-9 {
    -webkit-order: 9;
            order: 9; }
  .order-sm-10 {
    -webkit-order: 10;
            order: 10; }
  .order-sm-11 {
    -webkit-order: 11;
            order: 11; }
  .order-sm-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-order: -1;
            order: -1; }
  .order-md-last {
    -webkit-order: 13;
            order: 13; }
  .order-md-0 {
    -webkit-order: 0;
            order: 0; }
  .order-md-1 {
    -webkit-order: 1;
            order: 1; }
  .order-md-2 {
    -webkit-order: 2;
            order: 2; }
  .order-md-3 {
    -webkit-order: 3;
            order: 3; }
  .order-md-4 {
    -webkit-order: 4;
            order: 4; }
  .order-md-5 {
    -webkit-order: 5;
            order: 5; }
  .order-md-6 {
    -webkit-order: 6;
            order: 6; }
  .order-md-7 {
    -webkit-order: 7;
            order: 7; }
  .order-md-8 {
    -webkit-order: 8;
            order: 8; }
  .order-md-9 {
    -webkit-order: 9;
            order: 9; }
  .order-md-10 {
    -webkit-order: 10;
            order: 10; }
  .order-md-11 {
    -webkit-order: 11;
            order: 11; }
  .order-md-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-order: -1;
            order: -1; }
  .order-lg-last {
    -webkit-order: 13;
            order: 13; }
  .order-lg-0 {
    -webkit-order: 0;
            order: 0; }
  .order-lg-1 {
    -webkit-order: 1;
            order: 1; }
  .order-lg-2 {
    -webkit-order: 2;
            order: 2; }
  .order-lg-3 {
    -webkit-order: 3;
            order: 3; }
  .order-lg-4 {
    -webkit-order: 4;
            order: 4; }
  .order-lg-5 {
    -webkit-order: 5;
            order: 5; }
  .order-lg-6 {
    -webkit-order: 6;
            order: 6; }
  .order-lg-7 {
    -webkit-order: 7;
            order: 7; }
  .order-lg-8 {
    -webkit-order: 8;
            order: 8; }
  .order-lg-9 {
    -webkit-order: 9;
            order: 9; }
  .order-lg-10 {
    -webkit-order: 10;
            order: 10; }
  .order-lg-11 {
    -webkit-order: 11;
            order: 11; }
  .order-lg-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-order: -1;
            order: -1; }
  .order-xl-last {
    -webkit-order: 13;
            order: 13; }
  .order-xl-0 {
    -webkit-order: 0;
            order: 0; }
  .order-xl-1 {
    -webkit-order: 1;
            order: 1; }
  .order-xl-2 {
    -webkit-order: 2;
            order: 2; }
  .order-xl-3 {
    -webkit-order: 3;
            order: 3; }
  .order-xl-4 {
    -webkit-order: 4;
            order: 4; }
  .order-xl-5 {
    -webkit-order: 5;
            order: 5; }
  .order-xl-6 {
    -webkit-order: 6;
            order: 6; }
  .order-xl-7 {
    -webkit-order: 7;
            order: 7; }
  .order-xl-8 {
    -webkit-order: 8;
            order: 8; }
  .order-xl-9 {
    -webkit-order: 9;
            order: 9; }
  .order-xl-10 {
    -webkit-order: 10;
            order: 10; }
  .order-xl-11 {
    -webkit-order: 11;
            order: 11; }
  .order-xl-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #becdc7; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #86a396; }

.table-hover .table-primary:hover {
  background-color: #b0c2bb; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b0c2bb; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #ffceb8; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #fea37a; }

.table-hover .table-secondary:hover {
  background-color: #ffbc9f; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #ffbc9f; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #becdc7; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #86a396; }

.table-hover .table-success:hover {
  background-color: #b0c2bb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b0c2bb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f7b9bd; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f07e84; }

.table-hover .table-danger:hover {
  background-color: #f4a2a7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f4a2a7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #32b17b;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(22, 78, 54, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #164e36; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(22, 78, 54, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #164e36;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23164e36' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #164e36;
    box-shadow: 0 0 0 0.2rem rgba(22, 78, 54, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #164e36;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23164e36' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #164e36;
    box-shadow: 0 0 0 0.2rem rgba(22, 78, 54, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #164e36; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #164e36; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #164e36; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #217652;
  background-color: #217652; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(22, 78, 54, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #164e36; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #164e36; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #164e36;
  box-shadow: 0 0 0 0.2rem rgba(22, 78, 54, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e20612; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(226, 6, 18, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #e20612;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23e20612' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23e20612' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #e20612;
    box-shadow: 0 0 0 0.2rem rgba(226, 6, 18, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #e20612;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23e20612' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23e20612' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #e20612;
    box-shadow: 0 0 0 0.2rem rgba(226, 6, 18, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e20612; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #e20612; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #e20612; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f9222e;
  background-color: #f9222e; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(226, 6, 18, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e20612; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e20612; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e20612;
  box-shadow: 0 0 0 0.2rem rgba(226, 6, 18, 0.25); }

.form-inline {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-items: center;
          align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -webkit-flex;
      display: flex;
      -webkit-flex: 0 0 auto;
              flex: 0 0 auto;
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap;
      -webkit-align-items: center;
              align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(22, 78, 54, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #164e36;
  border-color: #164e36; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0e3021;
    border-color: #0b261a; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(57, 105, 84, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #164e36;
    border-color: #164e36; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0b261a;
    border-color: #081c14; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(57, 105, 84, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #fe4f00;
  border-color: #fe4f00; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #d84300;
    border-color: #cb3f00; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(254, 105, 38, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #fe4f00;
    border-color: #fe4f00; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #cb3f00;
    border-color: #be3b00; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(254, 105, 38, 0.5); }

.btn-success {
  color: #fff;
  background-color: #164e36;
  border-color: #164e36; }
  .btn-success:hover {
    color: #fff;
    background-color: #0e3021;
    border-color: #0b261a; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(57, 105, 84, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #164e36;
    border-color: #164e36; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #0b261a;
    border-color: #081c14; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(57, 105, 84, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #e20612;
  border-color: #e20612; }
  .btn-danger:hover {
    color: #fff;
    background-color: #bd050f;
    border-color: #b0050e; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(230, 43, 54, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #e20612;
    border-color: #e20612; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #b0050e;
    border-color: #a4040d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(230, 43, 54, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #164e36;
  border-color: #164e36; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #164e36;
    border-color: #164e36; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(22, 78, 54, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #164e36;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #164e36;
    border-color: #164e36; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(22, 78, 54, 0.5); }

.btn-outline-secondary {
  color: #fe4f00;
  border-color: #fe4f00; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #fe4f00;
    border-color: #fe4f00; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(254, 79, 0, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #fe4f00;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #fe4f00;
    border-color: #fe4f00; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(254, 79, 0, 0.5); }

.btn-outline-success {
  color: #164e36;
  border-color: #164e36; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #164e36;
    border-color: #164e36; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(22, 78, 54, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #164e36;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #164e36;
    border-color: #164e36; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(22, 78, 54, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #e20612;
  border-color: #e20612; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #e20612;
    border-color: #e20612; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(226, 6, 18, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #e20612;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #e20612;
    border-color: #e20612; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(226, 6, 18, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #164e36;
  text-decoration: none; }
  .btn-link:hover {
    color: #05120d;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #164e36; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-flex;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: stretch;
          align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: -webkit-flex;
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #164e36;
    background-color: #164e36; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(22, 78, 54, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #32b17b; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #4acc95;
    border-color: #4acc95; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #164e36;
  background-color: #164e36; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(22, 78, 54, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(22, 78, 54, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(22, 78, 54, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(22, 78, 54, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #32b17b;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(22, 78, 54, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #32b17b;
    box-shadow: 0 0 0 0.2rem rgba(22, 78, 54, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(22, 78, 54, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(22, 78, 54, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(22, 78, 54, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #164e36;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #4acc95; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #164e36;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #4acc95; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #164e36;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #4acc95; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #164e36; }

.nav-fill .nav-item {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-align-items: center;
          align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-basis: auto;
            flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: -webkit-flex;
        display: flex;
        -webkit-flex: 1 0;
                flex: 1 0;
        -webkit-flex-direction: column;
                flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap; }
      .card-group > .card {
        -webkit-flex: 1 0;
                flex: 1 0;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: -webkit-flex;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #164e36;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #05120d;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(22, 78, 54, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #164e36;
  border-color: #164e36; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #164e36; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0b261a; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(22, 78, 54, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #fe4f00; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #cb3f00; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(254, 79, 0, 0.5); }

.badge-success {
  color: #fff;
  background-color: #164e36; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #0b261a; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(22, 78, 54, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #e20612; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #b0050e; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(226, 6, 18, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #0b291c;
  background-color: #d0dcd7;
  border-color: #becdc7; }
  .alert-primary hr {
    border-top-color: #b0c2bb; }
  .alert-primary .alert-link {
    color: #000101; }

.alert-secondary {
  color: #842900;
  background-color: #ffdccc;
  border-color: #ffceb8; }
  .alert-secondary hr {
    border-top-color: #ffbc9f; }
  .alert-secondary .alert-link {
    color: #511900; }

.alert-success {
  color: #0b291c;
  background-color: #d0dcd7;
  border-color: #becdc7; }
  .alert-success hr {
    border-top-color: #b0c2bb; }
  .alert-success .alert-link {
    color: #000101; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #760309;
  background-color: #f9cdd0;
  border-color: #f7b9bd; }
  .alert-danger hr {
    border-top-color: #f4a2a7; }
  .alert-danger .alert-link {
    color: #440205; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -webkit-flex;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #164e36;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start; }

.media-body {
  -webkit-flex: 1 1;
          flex: 1 1; }

.list-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #164e36;
    border-color: #164e36; }

.list-group-horizontal {
  -webkit-flex-direction: row;
          flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #0b291c;
  background-color: #becdc7; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #0b291c;
    background-color: #b0c2bb; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #0b291c;
    border-color: #0b291c; }

.list-group-item-secondary {
  color: #842900;
  background-color: #ffceb8; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #842900;
    background-color: #ffbc9f; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #842900;
    border-color: #842900; }

.list-group-item-success {
  color: #0b291c;
  background-color: #becdc7; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #0b291c;
    background-color: #b0c2bb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0b291c;
    border-color: #0b291c; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #760309;
  background-color: #f7b9bd; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #760309;
    background-color: #f4a2a7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #760309;
    border-color: #760309; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }

.modal-dialog-scrollable {
  display: -webkit-flex;
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    -webkit-flex-shrink: 0;
            flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
          animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
          animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #164e36 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0b261a !important; }

.bg-secondary {
  background-color: #fe4f00 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #cb3f00 !important; }

.bg-success {
  background-color: #164e36 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #0b261a !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #e20612 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #b0050e !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #164e36 !important; }

.border-secondary {
  border-color: #fe4f00 !important; }

.border-success {
  border-color: #164e36 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #e20612 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -webkit-flex !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-flex !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -webkit-flex-direction: row !important;
          flex-direction: row !important; }

.flex-column {
  -webkit-flex-direction: column !important;
          flex-direction: column !important; }

.flex-row-reverse {
  -webkit-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important; }

.flex-wrap {
  -webkit-flex-wrap: wrap !important;
          flex-wrap: wrap !important; }

.flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important; }

.flex-fill {
  -webkit-flex: 1 1 auto !important;
          flex: 1 1 auto !important; }

.flex-grow-0 {
  -webkit-flex-grow: 0 !important;
          flex-grow: 0 !important; }

.flex-grow-1 {
  -webkit-flex-grow: 1 !important;
          flex-grow: 1 !important; }

.flex-shrink-0 {
  -webkit-flex-shrink: 0 !important;
          flex-shrink: 0 !important; }

.flex-shrink-1 {
  -webkit-flex-shrink: 1 !important;
          flex-shrink: 1 !important; }

.justify-content-start {
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important; }

.justify-content-end {
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important; }

.justify-content-center {
  -webkit-justify-content: center !important;
          justify-content: center !important; }

.justify-content-between {
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important; }

.justify-content-around {
  -webkit-justify-content: space-around !important;
          justify-content: space-around !important; }

.align-items-start {
  -webkit-align-items: flex-start !important;
          align-items: flex-start !important; }

.align-items-end {
  -webkit-align-items: flex-end !important;
          align-items: flex-end !important; }

.align-items-center {
  -webkit-align-items: center !important;
          align-items: center !important; }

.align-items-baseline {
  -webkit-align-items: baseline !important;
          align-items: baseline !important; }

.align-items-stretch {
  -webkit-align-items: stretch !important;
          align-items: stretch !important; }

.align-content-start {
  -webkit-align-content: flex-start !important;
          align-content: flex-start !important; }

.align-content-end {
  -webkit-align-content: flex-end !important;
          align-content: flex-end !important; }

.align-content-center {
  -webkit-align-content: center !important;
          align-content: center !important; }

.align-content-between {
  -webkit-align-content: space-between !important;
          align-content: space-between !important; }

.align-content-around {
  -webkit-align-content: space-around !important;
          align-content: space-around !important; }

.align-content-stretch {
  -webkit-align-content: stretch !important;
          align-content: stretch !important; }

.align-self-auto {
  -webkit-align-self: auto !important;
          align-self: auto !important; }

.align-self-start {
  -webkit-align-self: flex-start !important;
          align-self: flex-start !important; }

.align-self-end {
  -webkit-align-self: flex-end !important;
          align-self: flex-end !important; }

.align-self-center {
  -webkit-align-self: center !important;
          align-self: center !important; }

.align-self-baseline {
  -webkit-align-self: baseline !important;
          align-self: baseline !important; }

.align-self-stretch {
  -webkit-align-self: stretch !important;
          align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-sm-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-sm-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-sm-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-sm-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-sm-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-sm-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-sm-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-sm-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-sm-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-sm-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-sm-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-sm-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-sm-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-sm-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-md-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-md-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-md-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-md-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-md-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-md-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-md-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-md-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-md-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-md-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-md-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-md-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-md-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-md-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-md-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-md-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-md-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-lg-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-lg-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-lg-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-lg-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-lg-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-lg-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-lg-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-lg-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-lg-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-lg-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-lg-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-lg-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-lg-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-lg-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xl-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-xl-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-xl-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-xl-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-xl-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-xl-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-xl-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-xl-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-xl-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-xl-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-xl-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-xl-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-xl-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-xl-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #164e36 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #05120d !important; }

.text-secondary {
  color: #fe4f00 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #b23700 !important; }

.text-success {
  color: #164e36 !important; }

a.text-success:hover, a.text-success:focus {
  color: #05120d !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #e20612 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #97040c !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

@font-face {
  font-family: "quicksand-regular";
  src: url("../fonts/Quicksand-Regular.otf") format("opentype"); }

@font-face {
  font-family: "quicksand-dash";
  src: url("../fonts/Quicksand_Dash.otf") format("opentype"); }

@font-face {
  font-family: "quicksand-bold";
  src: url("../fonts/Quicksand-Bold.otf") format("opentype"); }

@font-face {
  font-family: "quicksand-bold-italic";
  src: url("../fonts/Quicksand-BoldItalic.otf") format("opentype"); }

@font-face {
  font-family: "quicksand-italic";
  src: url("../fonts/Quicksand-Italic.otf") format("opentype"); }

@font-face {
  font-family: "quicksand-light";
  src: url("../fonts/Quicksand-Light.otf") format("opentype"); }

@font-face {
  font-family: "quicksand-light-italic";
  src: url("../fonts/Quicksand-LightItalic.otf") format("opentype"); }

@font-face {
  font-family: "poppins-regular";
  src: url("../fonts/Poppins-Regular.otf") format("opentype"); }

@font-face {
  font-family: "poppins-bold";
  src: url("../fonts/Poppins-Bold.otf") format("opentype"); }

@font-face {
  font-family: "poppins-italic";
  src: url("../fonts/Poppins-Italic.otf") format("opentype"); }

@font-face {
  font-family: "poppins-light";
  src: url("../fonts/Poppins-Light.otf") format("opentype"); }

@font-face {
  font-family: "poppins-light-italic";
  src: url("../fonts/Poppins-LightItalic.otf") format("opentype"); }

@font-face {
  font-family: "poppins-semi-bold";
  src: url("../fonts/Poppins-SemiBold.otf") format("opentype"); }

@font-face {
  font-family: "cerapro-regular";
  src: url("../fonts/CeraPro-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "cerapro-bold";
  src: url("../fonts/CeraPro-Bold.ttf") format("truetype"); }

@font-face {
  font-family: "cerapro-medium";
  src: url("../fonts/CeraPro-Medium.ttf") format("truetype"); }

@font-face {
  font-family: "cerapro-black";
  src: url("../fonts/CeraPro-Black.ttf") format("truetype"); }

.txt-giantsize {
  font-size: 120px; }

.txt-oversized {
  font-size: 70px; }

.txt-upsized {
  font-size: 60px; }

.txt-header {
  font-size: 48px; }
  .txt-header-2 {
    font-size: 36px; }

.txt-large {
  font-size: 18px; }

.txt-xlarge {
  font-size: 20px; }

.txt-xxlarge {
  font-size: 30px; }

.txt-medium {
  font-size: 15px; }

.txt-small {
  font-size: 12px; }

.txt-primary {
  color: #164e36 !important; }

.txt-light-green {
  color: #58a300 !important; }

.txt-light-red {
  color: #ff8464 !important; }

.txt-error {
  color: #e20612 !important; }

.txt-success {
  color: #164e36 !important; }

.txt-warning {
  color: #fe4f00 !important; }

.txt-gray {
  color: #4e4e4e; }
  .txt-gray-med {
    color: #5e5e5e; }
    .txt-gray-med-light {
      color: #6f6f6f; }
  .txt-gray-light {
    color: #c9c9c9; }
  .txt-gray-lighter {
    color: #cbcbcb; }
  .txt-gray-dark {
    color: #1d1d1b; }
  .txt-gray-2 {
    color: #6e6e6e; }

.txt-white {
  color: #fff; }

.txt-link {
  color: #356077; }

.txt-dark-link {
  color: #284356; }

.txt-bold {
  font-weight: bold; }
  .txt-bold-qs-bold {
    font-family: "quicksand-bold"; }
  .txt-bold-pn-semi {
    font-family: "poppins-semi-bold"; }
  .txt-bold-black {
    font-weight: 800; }

.txt-regular {
  font-weight: normal; }

.txt-light {
  font-family: "quicksand-light"; }

.txt-cerapro-regular {
  font-family: "cerapro-regular"; }

.txt-cerapro-medium {
  font-family: "cerapro-medium"; }

.txt-cerapro-bold {
  font-family: "cerapro-bold"; }

.txt-cerapro-black {
  font-family: "cerapro-black"; }

.txt-uppercase {
  text-transform: uppercase; }

.txt-capitalize {
  text-transform: capitalize; }

.txt-underlined {
  text-decoration: underline; }

.txt-strike {
  text-decoration: line-through; }
  .txt-strike-element {
    position: relative; }
    .txt-strike-element::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: #c9c9c9;
      margin: auto 0; }

.txt-lineheight-1 {
  line-height: 1; }

@media (max-width: 991px) {
  .txt-oversized {
    font-size: 48px; }
  .txt-header {
    font-size: 36px; }
    .txt-header-2 {
      font-size: 30px; }
  .txt-large {
    font-size: 15px; }
  .txt-xlarge {
    font-size: 18px; }
  .txt-xxlarge {
    font-size: 20px; }
  .txt-medium {
    font-size: 12px; } }

@media (max-width: 767px) {
  .txt-oversized {
    font-size: 36px; }
  .txt-header {
    font-size: 30px; }
    .txt-header-2 {
      font-size: 20px; }
  .txt-large {
    font-size: 15px; }
  .txt-xlarge {
    font-size: 15px; }
  .txt-xxlarge {
    font-size: 18px; } }

.flex {
  display: -webkit-flex;
  display: flex; }
  .flex .flex-1 {
    -webkit-flex: 1 1;
            flex: 1 1; }
  .flex.align-center {
    -webkit-align-items: center;
            align-items: center; }
  .flex.justify-center {
    -webkit-justify-content: center;
            justify-content: center; }
  .flex.justify-between {
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .flex.justify-around {
    -webkit-justify-content: space-around;
            justify-content: space-around; }
  .flex.flex-end {
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
  .flex.direction-column {
    -webkit-flex-direction: column;
            flex-direction: column; }

.flex-flow-column {
  -webkit-flex-flow: column;
          flex-flow: column; }

.align-items-center {
  -webkit-align-items: center;
          align-items: center; }

.justify-content-end {
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }

.align-all-center {
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

@media (max-width: 991px) {
  .flex-rm-md {
    display: block; } }

@media (max-width: 767px) {
  .flex-rm-xs {
    display: block; } }

.float-in-up-down {
  -webkit-animation-name: floatInUpDown;
          animation-name: floatInUpDown;
  -webkit-animation-duration: 0.6s;
          animation-duration: 0.6s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  opacity: 0;
  -webkit-transform: translateY(50%);
          transform: translateY(50%); }

.fade-in {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  opacity: 0; }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0; }

.fade-in-down {
  -webkit-animation-name: fadeInDown;
          animation-name: fadeInDown;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0; }

.pulse {
  -webkit-animation-name: pulse;
          animation-name: pulse;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s; }
  .pulse-inwards {
    -webkit-animation-name: pulse-inwards;
            animation-name: pulse-inwards;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s; }
  .pulse-heart {
    -webkit-animation-name: pulseHeart;
            animation-name: pulseHeart;
    -webkit-animation-duration: 5s;
            animation-duration: 5s; }

.infinite-animation {
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.scale-in {
  -webkit-animation-name: scaleIn;
          animation-name: scaleIn;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-duration: linear;
          animation-duration: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-transform: scale(0);
          transform: scale(0); }

@-webkit-keyframes scaleIn {
  from {
    -webkit-transform: scale(0);
            transform: scale(0); }
  25% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); }
  35% {
    -webkit-transform: scale(1.4);
            transform: scale(1.4); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes scaleIn {
  from {
    -webkit-transform: scale(0);
            transform: scale(0); }
  25% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); }
  35% {
    -webkit-transform: scale(1.4);
            transform: scale(1.4); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(20%);
            transform: translateY(20%); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(20%);
            transform: translateY(20%); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes growIn {
  from {
    -webkit-transform: scale(0);
            transform: scale(0); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes growIn {
  from {
    -webkit-transform: scale(0);
            transform: scale(0); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes slideLeft {
  from {
    width: 100%; }
  to {
    width: 0%; } }

@keyframes slideLeft {
  from {
    width: 100%; }
  to {
    width: 0%; } }

@-webkit-keyframes float {
  from,
  to {
    -webkit-transform: translateY(10%);
            transform: translateY(10%); }
  25% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); }
  50% {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%); }
  75% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@keyframes float {
  from,
  to {
    -webkit-transform: translateY(10%);
            transform: translateY(10%); }
  25% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); }
  50% {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%); }
  75% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes floatInUpDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%); }
  50% {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes floatInUpDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%); }
  50% {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes floatInDown {
  from {
    top: -10px; }
  to {
    top: 0; } }

@keyframes floatInDown {
  from {
    top: -10px; }
  to {
    top: 0; } }

@-webkit-keyframes pulse {
  from,
  to {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); } }

@keyframes pulse {
  from,
  to {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); } }

@-webkit-keyframes pulse-inwards {
  from,
  to {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5); } }

@keyframes pulse-inwards {
  from,
  to {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5); } }

@-webkit-keyframes pulseHeart {
  from,
  to {
    -webkit-transform: scale(1);
            transform: scale(1); }
  4% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  8% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  12% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  16%,
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes pulseHeart {
  from,
  to {
    -webkit-transform: scale(1);
            transform: scale(1); }
  4% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  8% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  12% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  16%,
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes jump {
  from,
  to {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5); }
  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.5); } }

@keyframes jump {
  from,
  to {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5); }
  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.5); } }

.ellipsis-loader {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .ellipsis-loader div {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background: #fff;
    -webkit-animation-name: ellipsisLoading;
            animation-name: ellipsisLoading;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    margin: 0 10px; }
  .ellipsis-loader.dot-black div {
    background: #ccc; }
  .ellipsis-loader.el-black {
    background: #000000; }

@-webkit-keyframes ellipsisLoading {
  from,
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); } }

@keyframes ellipsisLoading {
  from,
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); } }

.fullwidth-product-card {
  background-color: #5e5e5e;
  padding: 25px;
  position: relative; }
  .fullwidth-product-card .main-image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }
    .fullwidth-product-card .main-image img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: cover;
      object-position: center; }
  .fullwidth-product-card .transition-overlay-image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    max-width: 100%;
    overflow: hidden; }
    .fullwidth-product-card .transition-overlay-image img {
      position: absolute;
      left: 0;
      top: 0;
      object-fit: cover;
      object-position: center; }
  .fullwidth-product-card .product-details {
    background-color: #fff;
    padding: 30px;
    margin-top: 200px;
    max-width: 60%;
    position: relative; }
  .fullwidth-product-card.in-transition .transition-overlay-image {
    opacity: 1;
    transition: max-width 0.6s ease-out;
    max-width: 0%; }
    .fullwidth-product-card.in-transition .transition-overlay-image.prev {
      left: auto;
      left: initial;
      right: 0; }
      .fullwidth-product-card.in-transition .transition-overlay-image.prev img {
        left: auto;
        left: initial;
        right: 0; }
  @media (max-width: 991px) {
    .fullwidth-product-card .product-details {
      max-width: 100%; } }
  @media (max-width: 767px) {
    .fullwidth-product-card {
      padding: 15px 5px; }
      .fullwidth-product-card .product-details {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
        background: none;
        margin-top: 100px;
        padding: 0; }
        .fullwidth-product-card .product-details .details-container {
          background: #fff;
          padding: 10px; }
        .fullwidth-product-card .product-details .btn-container {
          text-align: center; } }

.related-products-slider {
  height: 600px;
  position: relative; }
  .related-products-slider .product-details {
    position: absolute;
    left: 30px;
    bottom: 30px;
    max-width: 60%;
    width: 100%; }
  @media (max-width: 991px) {
    .related-products-slider {
      height: 500px; }
      .related-products-slider .product-details {
        max-width: calc(100% - 60px); } }
  @media (max-width: 767px) {
    .related-products-slider {
      height: 300px; }
      .related-products-slider .product-details {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
        background: none;
        margin-top: 100px;
        padding: 0;
        max-width: calc(100% - 10px);
        left: 5px;
        bottom: 15px; }
        .related-products-slider .product-details .details-container {
          background: #fff;
          padding: 10px; }
        .related-products-slider .product-details .btn-container {
          text-align: center; }
        .related-products-slider .product-details .list-control {
          margin-left: 0;
          -webkit-justify-content: center;
                  justify-content: center;
          position: absolute;
          bottom: 0;
          left: 0;
          -webkit-transform: translateY(200%);
                  transform: translateY(200%);
          width: 100%; }
          .related-products-slider .product-details .list-control .btn.margin-left-10 {
            margin-left: 20px; } }

@media (max-width: 767px) {
  .related-products-slider-container {
    margin-bottom: 100px !important; } }

#comingSoon {
  background: #000;
  min-height: 100vh;
  position: relative;
  overflow: auto; }
  #comingSoon .img-background-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    left: 0;
    top: 0; }
    #comingSoon .img-background-overlay .img-overlay {
      position: absolute;
      width: 110%;
      height: 110%;
      object-fit: cover;
      object-position: center;
      -webkit-transform: translate(-2.5%, -2.5%);
              transform: translate(-2.5%, -2.5%);
      transition: 1s; }
      #comingSoon .img-background-overlay .img-overlay.blurred {
        opacity: 0.6;
        -webkit-filter: blur(22px);
                filter: blur(22px); }
  #comingSoon img.logo {
    width: 200px; }
  #comingSoon .content {
    position: relative;
    color: #fff;
    opacity: 0;
    transition: 0.5s; }
    #comingSoon .content .big-txt {
      font-size: 55px;
      margin-bottom: 20px; }
      #comingSoon .content .big-txt sup {
        font-size: 0.5em; }
    #comingSoon .content .txt-content {
      font-size: 20px; }
    #comingSoon .content .btn {
      background: #000;
      padding: 20px 30px;
      margin-top: 70px;
      max-width: 200px;
      width: 100%;
      font-weight: bold; }
  #comingSoon .subscription-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    transition: 0s;
    transition-delay: 0.3s;
    background: rgba(0, 0, 0, 0.5); }
    #comingSoon .subscription-modal .box {
      background: #000;
      color: #fff;
      position: relative;
      -webkit-transform: translateY(10%);
              transform: translateY(10%);
      opacity: 0;
      transition: 0.3s; }
      #comingSoon .subscription-modal .box > :first-child {
        border-right: 1px solid #666; }
      #comingSoon .subscription-modal .box .btn {
        background: #000;
        border: 1px solid #fff;
        max-width: 200px;
        width: 100%;
        font-weight: bold; }
        #comingSoon .subscription-modal .box .btn:hover {
          color: inherit; }
        #comingSoon .subscription-modal .box .btn.soc-btn {
          max-width: none;
          max-width: initial;
          width: 300px;
          border: none;
          font-weight: 500;
          display: block; }
          #comingSoon .subscription-modal .box .btn.soc-btn.fb {
            background: #4267b2; }
          #comingSoon .subscription-modal .box .btn.soc-btn.google {
            background: #dc4e41; }
          #comingSoon .subscription-modal .box .btn.soc-btn.twitter {
            background: #5baaf4; }
      #comingSoon .subscription-modal .box .dismiss-btn {
        border: none;
        position: absolute;
        right: 10px;
        top: 10px;
        width: auto; }
      #comingSoon .subscription-modal .box .success-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        color: #fff;
        background-color: #000;
        overflow: hidden;
        -webkit-transform: scale(0);
                transform: scale(0);
        transition: .3s; }
        #comingSoon .subscription-modal .box .success-overlay .img-overlay {
          opacity: 0.08;
          position: absolute; }
        #comingSoon .subscription-modal .box .success-overlay .message-container {
          position: relative; }
        #comingSoon .subscription-modal .box .success-overlay.show {
          -webkit-transform: scale(1);
                  transform: scale(1); }
      #comingSoon .subscription-modal .box .loading-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        -webkit-transform: scale(0);
                transform: scale(0);
        transition: opacity 0.3s; }
        #comingSoon .subscription-modal .box .loading-overlay.show {
          opacity: 1;
          -webkit-transform: scale(1);
                  transform: scale(1); }
    #comingSoon .subscription-modal.show {
      -webkit-transform: scale(1);
              transform: scale(1);
      transition-delay: 0s; }
      #comingSoon .subscription-modal.show .box {
        transition-delay: 0.1s;
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0); }
  #comingSoon.show .img-overlay:not(.blurred) {
    opacity: 0; }
  #comingSoon.show .content {
    opacity: 1; }
  @media (max-width: 991px) {
    #comingSoon .content {
      padding: 15px; }
      #comingSoon .content .big-txt {
        font-size: 40px; }
      #comingSoon .content .txt-content {
        font-size: 14px; } }

@media (max-width: 991px) and (max-height: 504px) {
  #comingSoon .subscription-modal {
    overflow: scroll; }
    #comingSoon .subscription-modal .box {
      margin: auto; } }
  @media (max-width: 850px) {
    #comingSoon .subscription-modal .box {
      display: block;
      width: 90%; }
      #comingSoon .subscription-modal .box .pad-40 {
        padding: 20px 30px; }
      #comingSoon .subscription-modal .box > :first-child {
        border-right: none;
        padding-bottom: 0; }
      #comingSoon .subscription-modal .box > :nth-child(2) {
        padding-top: 0; }
      #comingSoon .subscription-modal .box label {
        font-size: 12px; }
      #comingSoon .subscription-modal .box input {
        margin-bottom: 5px; }
      #comingSoon .subscription-modal .box .txt-xxlarge {
        font-size: 18px; }
      #comingSoon .subscription-modal .box .btn {
        margin: 20px auto;
        max-width: 300px;
        width: 100%;
        display: block; }
        #comingSoon .subscription-modal .box .btn.soc-btn {
          max-width: 300px;
          width: 100%; }
      #comingSoon .subscription-modal .box .dismiss-btn {
        margin: 0;
        width: auto; } }

@media (max-width: 850px) and (max-height: 645px) {
  #comingSoon .subscription-modal {
    overflow: scroll; }
    #comingSoon .subscription-modal .box {
      margin: auto; } }
  @media (max-width: 767px) {
    #comingSoon img.logo {
      width: 150px; }
    #comingSoon .content {
      padding: 15px; }
      #comingSoon .content .big-txt {
        font-size: 35px; }
      #comingSoon .content .txt-content {
        font-size: 12px; }
    #comingSoon .subscription-modal {
      overflow: scroll; }
      #comingSoon .subscription-modal .box {
        display: block;
        width: 90%; }
        #comingSoon .subscription-modal .box .margin-top-40 {
          margin-top: 0; }
        #comingSoon .subscription-modal .box .register-socmed > div {
          margin: 0 5px; }
        #comingSoon .subscription-modal .box .dismiss-btn {
          margin: 0;
          width: auto; } }
  @media (max-width: 452px) {
    #comingSoon img.logo {
      width: 100px; }
    #comingSoon .content .big-txt {
      font-size: 25px; }
    #comingSoon .content .txt-content br {
      display: none; } }
  @media (max-width: 320px) {
    #comingSoon .content .big-txt {
      font-size: 20px; } }

.slider {
  min-height: 300px;
  background: #111;
  position: relative; }
  .slider .active-slide,
  .slider .last-active-slide {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-animation-delay: 0s;
            animation-delay: 0s; }
    .slider .active-slide > div,
    .slider .last-active-slide > div {
      max-width: 50%; }
  .slider .last-active-slide {
    opacity: 1;
    -webkit-transform: scale(0);
            transform: scale(0);
    transition: opacity 0.3s; }
  .slider .slider-control {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    border: none;
    background: #fff;
    border-radius: 100%;
    color: #000000;
    height: 70px;
    width: 70px;
    transition: 0.3s; }
    .slider .slider-control.prev-btn {
      left: -35px;
      box-shadow: 0px 10px 25px #04060517; }
    .slider .slider-control.next-btn {
      right: -35px;
      box-shadow: 0px 10px 25px #04060517; }
    .slider .slider-control:hover {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
  .slider .slider-thumbnails {
    position: absolute;
    width: 100%;
    bottom: 20px;
    left: 0; }
    .slider .slider-thumbnails .thumb {
      width: 50px;
      height: 5px;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.5);
      margin: 0 10px;
      position: relative;
      overflow: hidden; }
      .slider .slider-thumbnails .thumb::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #fff;
        -webkit-transform: translateX(100%);
                transform: translateX(100%); }
      .slider .slider-thumbnails .thumb.active {
        pointer-events: none; }
        .slider .slider-thumbnails .thumb.active::after {
          -webkit-transform: translateX(0);
                  transform: translateX(0); }
    .slider .slider-thumbnails.next {
      pointer-events: none; }
      .slider .slider-thumbnails.next .thumb.active-out::after {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        -webkit-animation-name: thumbOutNext;
                animation-name: thumbOutNext;
        -webkit-animation-duration: 0.5s;
                animation-duration: 0.5s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
      .slider .slider-thumbnails.next .thumb.active::after {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
        -webkit-animation-name: thumbInNext;
                animation-name: thumbInNext;
        -webkit-animation-duration: 0.5s;
                animation-duration: 0.5s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
    .slider .slider-thumbnails.prev {
      pointer-events: none; }
      .slider .slider-thumbnails.prev .thumb.active-out::after {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        -webkit-animation-name: thumbOutPrev;
                animation-name: thumbOutPrev;
        -webkit-animation-duration: 0.5s;
                animation-duration: 0.5s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
      .slider .slider-thumbnails.prev .thumb.active::after {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
        -webkit-animation-name: thumbInPrev;
                animation-name: thumbInPrev;
        -webkit-animation-duration: 0.5s;
                animation-duration: 0.5s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
  .slider.in-transition .last-active-slide {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; }
  @media (max-width: 991px) {
    .slider .active-slide,
    .slider .last-active-slide {
      padding: 30px; }
      .slider .active-slide > div,
      .slider .last-active-slide > div {
        max-width: 70%; }
    .slider .slider-control {
      -webkit-transform: scale(0.7);
              transform: scale(0.7); }
      .slider .slider-control:hover {
        -webkit-transform: scale(0.8);
                transform: scale(0.8); } }
  @media (max-width: 767px) {
    .slider {
      margin-left: -16px;
      margin-right: -16px; }
      .slider .active-slide > div,
      .slider .last-active-slide > div {
        max-width: 100%; }
      .slider .slider-control {
        -webkit-transform: scale(0.5);
                transform: scale(0.5); }
        .slider .slider-control:hover {
          -webkit-transform: scale(0.6);
                  transform: scale(0.6); } }
  @media (max-width: 450px) {
    .slider .slider-control {
      -webkit-transform: scale(0.5);
              transform: scale(0.5); }
      .slider .slider-control:hover {
        -webkit-transform: scale(0.6);
                transform: scale(0.6); }
      .slider .slider-control.prev-btn {
        left: -15px; }
      .slider .slider-control.next-btn {
        right: -15px; } }

html {
  font-size: 16px;
  color: #000000;
  overflow-x: hidden; }

body {
  margin: 0;
  padding: 0;
  font-family: "cerapro-regular";
  overflow-x: hidden;
  color: #000000; }

.modal {
  z-index: 999999; }
  .modal-open .main-navbar nav > .container {
    padding-right: 32px; }
  .modal .dismiss-btn {
    position: absolute;
    right: 5px;
    top: 5px; }

.show-xs,
.show-md {
  display: none; }

button {
  outline: none !important; }

@media (max-width: 500px) {
  .txt-oneline-title-500px {
    font-size: 7vw !important; } }

.radio-circle {
  width: 20px;
  height: 20px;
  border: 1px solid #009ee2;
  border-radius: 100%;
  position: relative; }
  .radio-circle::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background: #009ee2;
    border-radius: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transition: 0.3s;
    -webkit-transform: scale(0);
            transform: scale(0); }
  .radio-circle.active::after {
    -webkit-transform: scale(1);
            transform: scale(1); }

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  outline: none; }

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit; }

ul {
  padding-left: 0;
  margin: 0; }

li {
  list-style: none; }

.bold {
  font-weight: bold; }

.italic {
  font-style: italic; }

.hidden {
  display: none; }

.transitioned {
  transition: 0.6s; }
  .transitioned-fast {
    transition: 0.3s; }

.clickable {
  cursor: pointer; }

.no-pointer {
  pointer-events: none; }

.scale-hide {
  -webkit-transform: scale(0);
          transform: scale(0); }

.separator {
  margin: 0 5px; }

.bg-green {
  background: #164e36; }

.bg-light-yellow {
  background: #fcf7ea; }

.bg-orange {
  background: #fe4f00; }

.bg-red {
  background: #e20612; }
  .bg-red-pastel {
    background: #e52632; }

.bg-gray {
  background: #4e4e4e; }
  .bg-gray-dark {
    background: #1d1d1b; }
  .bg-gray-light {
    background: #c9c9c9; }
  .bg-gray-almost-white {
    background: #f8f8f8; }

.relative-container {
  position: relative; }

.margin {
  margin: 5px; }
  .margin-x-auto {
    margin-left: auto;
    margin-right: auto; }
  .margin-auto {
    margin: auto; }
  .margin-0 {
    margin: 0px !important;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px; }
  .margin-10 {
    margin: 10px; }
  .margin-15 {
    margin: 15px; }
  .margin-20 {
    margin: 20px; }
  .margin-25 {
    margin: 25px; }
  .margin-30 {
    margin: 30px; }
  .margin-35 {
    margin: 35px; }
  .margin-40 {
    margin: 40px; }
  .margin-45 {
    margin: 45px; }
  .margin-50 {
    margin: 50px; }
  .margin-left {
    margin-left: 5px; }
    .margin-left-10 {
      margin-left: 10px; }
    .margin-left-15 {
      margin-left: 15px; }
    .margin-left-20 {
      margin-left: 20px; }
    .margin-left-25 {
      margin-left: 25px; }
    .margin-left-30 {
      margin-left: 30px; }
    .margin-left-35 {
      margin-left: 35px; }
    .margin-left-40 {
      margin-left: 40px; }
    .margin-left-45 {
      margin-left: 45px; }
    .margin-left-50 {
      margin-left: 50px; }
    .margin-left-100 {
      margin-left: 100px; }
  .margin-right {
    margin-right: 5px; }
    .margin-right-10 {
      margin-right: 10px; }
    .margin-right-15 {
      margin-right: 15px; }
    .margin-right-20 {
      margin-right: 20px; }
    .margin-right-25 {
      margin-right: 25px; }
    .margin-right-30 {
      margin-right: 30px; }
    .margin-right-35 {
      margin-right: 35px; }
    .margin-right-40 {
      margin-right: 40px; }
    .margin-right-45 {
      margin-right: 45px; }
    .margin-right-50 {
      margin-right: 50px; }
    .margin-right-100 {
      margin-right: 100px; }
  .margin-top {
    margin-top: 5px; }
    .margin-top-10 {
      margin-top: 10px; }
    .margin-top-15 {
      margin-top: 15px; }
    .margin-top-20 {
      margin-top: 20px; }
    .margin-top-25 {
      margin-top: 25px; }
    .margin-top-30 {
      margin-top: 30px; }
    .margin-top-35 {
      margin-top: 35px; }
    .margin-top-40 {
      margin-top: 40px; }
    .margin-top-45 {
      margin-top: 45px; }
    .margin-top-50 {
      margin-top: 50px; }
    .margin-top-100 {
      margin-top: 100px; }
  .margin-bottom {
    margin-bottom: 5px; }
    .margin-bottom-10 {
      margin-bottom: 10px; }
    .margin-bottom-15 {
      margin-bottom: 15px; }
    .margin-bottom-20 {
      margin-bottom: 20px; }
    .margin-bottom-25 {
      margin-bottom: 25px; }
    .margin-bottom-30 {
      margin-bottom: 30px; }
    .margin-bottom-35 {
      margin-bottom: 35px; }
    .margin-bottom-40 {
      margin-bottom: 40px; }
    .margin-bottom-45 {
      margin-bottom: 45px; }
    .margin-bottom-50 {
      margin-bottom: 50px; }
    .margin-bottom-100 {
      margin-bottom: 100px; }
  .margin-none {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0; }

.pad {
  padding: 5px; }
  .pad-10 {
    padding: 10px; }
  .pad-15 {
    padding: 15px; }
  .pad-20 {
    padding: 20px; }
  .pad-25 {
    padding: 25px; }
  .pad-30 {
    padding: 30px; }
  .pad-35 {
    padding: 35px; }
  .pad-40 {
    padding: 40px; }
  .pad-45 {
    padding: 45px; }
  .pad-50 {
    padding: 50px; }
  .pad-100 {
    padding: 100px; }
  .pad-left {
    padding-left: 5px; }
    .pad-left-10 {
      padding-left: 10px; }
    .pad-left-15 {
      padding-left: 15px; }
    .pad-left-20 {
      padding-left: 20px; }
    .pad-left-25 {
      padding-left: 25px; }
    .pad-left-30 {
      padding-left: 30px; }
    .pad-left-35 {
      padding-left: 35px; }
    .pad-left-40 {
      padding-left: 40px; }
    .pad-left-45 {
      padding-left: 45px; }
    .pad-left-50 {
      padding-left: 50px; }
    .pad-left-100 {
      padding-left: 100px; }
  .pad-right {
    padding-right: 5px; }
    .pad-right-10 {
      padding-right: 10px; }
    .pad-right-15 {
      padding-right: 15px; }
    .pad-right-20 {
      padding-right: 20px; }
    .pad-right-25 {
      padding-right: 25px; }
    .pad-right-30 {
      padding-right: 30px; }
    .pad-right-35 {
      padding-right: 35px; }
    .pad-right-40 {
      padding-right: 40px; }
    .pad-right-45 {
      padding-right: 45px; }
    .pad-right-50 {
      padding-right: 50px; }
    .pad-right-100 {
      padding-right: 100px; }
  .pad-top {
    padding-top: 5px; }
    .pad-top-10 {
      padding-top: 10px; }
    .pad-top-15 {
      padding-top: 15px; }
    .pad-top-20 {
      padding-top: 20px; }
    .pad-top-25 {
      padding-top: 25px; }
    .pad-top-30 {
      padding-top: 30px; }
    .pad-top-35 {
      padding-top: 35px; }
    .pad-top-40 {
      padding-top: 40px; }
    .pad-top-45 {
      padding-top: 45px; }
    .pad-top-50 {
      padding-top: 50px; }
    .pad-top-100 {
      padding-top: 100px; }
    .pad-top-150 {
      padding-top: 150px; }
    .pad-top-200 {
      padding-top: 200px; }
  .pad-bottom {
    padding-bottom: 5px; }
    .pad-bottom-10 {
      padding-bottom: 10px; }
    .pad-bottom-15 {
      padding-bottom: 15px; }
    .pad-bottom-20 {
      padding-bottom: 20px; }
    .pad-bottom-25 {
      padding-bottom: 25px; }
    .pad-bottom-30 {
      padding-bottom: 30px; }
    .pad-bottom-35 {
      padding-bottom: 35px; }
    .pad-bottom-40 {
      padding-bottom: 40px; }
    .pad-bottom-45 {
      padding-bottom: 45px; }
    .pad-bottom-50 {
      padding-bottom: 50px; }
    .pad-bottom-100 {
      padding-bottom: 100px; }
    .pad-bottom-150 {
      padding-bottom: 150px; }
    .pad-bottom-200 {
      padding-bottom: 200px; }

.modal-align-vertical {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  background: transparent;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: auto;
  justify-content: center;
  align-items: center;
  -webkit-animation-duration: 0.6s;
          animation-duration: 0.6s;
  max-width: 50% !important; }
  .modal-align-vertical .modal-dialog {
    margin: auto; }

.pointer-events-none {
  pointer-events: none; }

.remove-bootstrap-animation.modal.fade .modal-dialog {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  transition: none; }

.ph-item {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  opacity: 0.5; }
  .ph-item > * {
    padding: 0; }
    .ph-item > * .ph-row div {
      height: 15px;
      border-radius: 5px; }
      .ph-item > * .ph-row div.big {
        height: 25px; }
  .ph-item::before {
    -webkit-animation-duration: 2s;
            animation-duration: 2s; }

.page-body-container .page-banner-image {
  position: relative;
  min-height: 500px;
  background-color: #ccc;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; }

.page-body-container .page-banner-text {
  padding: 30px; }
  .page-body-container .page-banner-text p {
    opacity: 0.4;
    font-size: 18px; }
    .page-body-container .page-banner-text p.txt-header {
      font-size: 36px;
      opacity: 1; }

.page-body-container .page-container {
  max-width: 90%;
  width: 100%;
  margin: auto;
  padding-top: 50px; }

.pad-to-nav {
  padding-top: 120px; }

.partner-list .partner-card {
  text-align: center;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  transition: 0.6s; }
  .partner-list .partner-card img {
    max-height: 80px;
    max-width: 100%;
    margin: auto;
    object-fit: contain;
    object-position: center; }
  .partner-list .partner-card:hover {
    -webkit-filter: grayscale(0);
            filter: grayscale(0); }

.skeleton-loading {
  position: relative;
  overflow: hidden;
  background-color: #dddbdd; }
  .skeleton-loading::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
    -webkit-animation: shimmer 5s infinite;
            animation: shimmer 5s infinite;
    content: ""; }

@-webkit-keyframes shimmer {
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

@keyframes shimmer {
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

.slick-arrow.slick-prev:before {
  font-family: "Font Awesome 5 Pro";
  content: "\F104";
  font-size: 30px; }

.slick-arrow.slick-next:before {
  font-family: "Font Awesome 5 Pro";
  content: "\F105";
  font-size: 30px; }

.section-header {
  margin: auto;
  padding-top: 90px; }

.section-title {
  font-size: 1.25rem;
  font-family: "quicksand-bold";
  padding-bottom: 10px; }

h1 {
  font-family: "quicksand-bold"; }
  h1.reg-header {
    font-family: "quicksand-regular"; }
  h1.section-title {
    font-size: 2.5rem;
    font-family: "poppins-bold"; }

.section-description {
  font-family: "quicksand-regular";
  padding-bottom: 30px; }

.green-background {
  background-color: #164e36; }

.simple-image-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 30px; }
  .simple-image-list > li {
    height: 0;
    padding-top: 60%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }

.grid-fix-3-col {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 15px; }

.grid-3-col {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-gap: 30px; }

.grid-4-col {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 30px; }

.added-product-container {
  display: grid;
  padding: 0 15px;
  grid-template-columns: 1fr; }
  .added-product-container .image-container {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }
  .added-product-container .details {
    padding: 15px 0; }
  .added-product-container .line {
    background-color: #c9c9c9; }

.slick-dots li {
  height: auto;
  width: auto; }
  .slick-dots li button {
    background-color: #fff;
    opacity: 0.6;
    border-radius: 100%;
    width: 10px;
    height: 10px;
    transition: all 0.3s ease;
    border: 1px solid #4e4e4e; }
    .slick-dots li button:before {
      content: none; }
  .slick-dots li.slick-active button, .slick-dots li:hover button {
    background-color: #fe4f00;
    border: 1px solid #fe4f00;
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }

.slick-line .slick-dots li button {
  border-radius: 10px;
  width: 40px;
  height: 5px;
  padding: 0; }

.max-width-90 {
  max-width: 1200px;
  width: 90%;
  margin: auto; }

.full-width {
  width: 100%; }

.inline-block {
  display: inline-block; }

.line-height-1 {
  line-height: 1; }

.gap-20 {
  grid-gap: 20px;
  gap: 20px; }

.gap-30 {
  grid-gap: 30px;
  gap: 30px; }

.grid {
  display: grid; }
  .grid.justify-center {
    -webkit-justify-content: center;
            justify-content: center; }
  .grid.align-center {
    -webkit-align-items: center;
            align-items: center; }
  .grid-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)); }
    .grid-2-auto {
      grid-template-columns: auto auto; }
    .grid-2-auto-1fr {
      grid-template-columns: auto 1fr; }
  .grid-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)); }
    .grid-3-auto {
      grid-template-columns: auto auto auto; }
  .grid-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)); }
    .grid-4-auto {
      grid-template-columns: auto auto auto auto; }
  .grid-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)); }
    .grid-5-auto {
      grid-template-columns: auto auto auto auto auto; }
  .grid-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)); }
    .grid-8-auto {
      grid-template-columns: auto auto auto auto auto auto auto auto; }
  .grid-gap-20 {
    grid-gap: 20px; }
    .grid-gap-20-50 {
      grid-gap: 20px 50px; }
  .grid-gap-30 {
    grid-gap: 30px; }
  .grid-gap-40 {
    grid-gap: 40px; }
  .grid-gap-50 {
    grid-gap: 50px; }
    .grid-gap-50-20 {
      grid-gap: 50px 20px; }
  .grid-autofit-250 {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); }

.whole-page-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10; }

.no-whitespace {
  white-space: nowrap; }

.more-products-container .grid-3-auto {
  grid-template-columns: minmax(auto, 300px) minmax(auto, 300px) minmax(auto, 300px); }

.more-products-container .grid-4-auto {
  grid-template-columns: repeat(4, minmax(0, 1fr)); }

.more-products-container .group-link i {
  transition: 0.3s; }

.more-products-container .group-link:hover i {
  -webkit-transform: scale(1.5);
          transform: scale(1.5); }

.dropdown-card {
  -webkit-animation-name: floatInDown;
          animation-name: floatInDown;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  top: -10px; }

.collapse-container {
  transition: 0.6s;
  max-height: 0;
  overflow: hidden; }

.screen-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999999999999999999; }

.pagination-links .pagination-btn {
  width: 35px;
  height: 35px;
  font-size: 16px;
  border-radius: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: none;
  box-shadow: none;
  margin: 0 5px;
  transition: 0.3s;
  background: none; }
  .pagination-links .pagination-btn.disabled {
    pointer-events: none; }
  .pagination-links .pagination-btn.page-arrow {
    background: #c9c9c9; }
  .pagination-links .pagination-btn:hover, .pagination-links .pagination-btn.active {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    background: #009ee2;
    color: #fff; }

.star-group {
  position: relative; }
  .star-group .left-part,
  .star-group .right-part {
    position: absolute;
    top: 0;
    width: 50%;
    overflow: hidden; }
  .star-group .left-part {
    left: 0; }
  .star-group .right-part {
    right: 0; }
    .star-group .right-part i {
      float: right; }

.review-form {
  background: #f5f5f5;
  border-radius: 10px; }
  .review-form textarea {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 10px;
    height: 150px;
    outline: none;
    padding: 10px; }

.review-card {
  border-radius: 20px;
  border: 1px solid #eee;
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background: #fff; }
  .review-card .customer-details img {
    max-width: 70px;
    width: 70px;
    background-color: #ccc;
    border-radius: 100%;
    margin: 0 auto;
    overflow: hidden; }

.tooltipped {
  position: relative; }
  .tooltipped::after {
    content: attr(tooltiptext);
    position: absolute;
    background: #111;
    color: #fff;
    top: 0;
    right: 0;
    font-size: 12px;
    white-space: nowrap;
    padding: 3px;
    border-radius: 3px;
    pointer-events: none;
    transition: 0.3s;
    opacity: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .tooltipped:hover::after {
    -webkit-transform: translateY(calc(-100% - 5px));
            transform: translateY(calc(-100% - 5px));
    opacity: 1; }

.change-password-form {
  min-height: 50vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .change-password-form > div {
    max-width: 600px;
    width: 100%; }

#dataPolicy {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  overflow: auto;
  transition: all 0.15s ease-in-out; }
  #dataPolicy > div {
    max-width: 80%;
    width: 100%;
    margin: 10px 10%;
    display: inline-block;
    background: #333; }
  #dataPolicy p {
    margin: 0; }
  #dataPolicy .see-more {
    display: none; }
  #dataPolicy.short {
    height: 150px;
    overflow: hidden; }
    #dataPolicy.short > div {
      max-width: 100%;
      height: 100%;
      margin: 0;
      padding: 10px 300px 0;
      display: grid;
      grid-template-columns: 1fr 180px;
      grid-gap: 50px; }
      #dataPolicy.short > div > div .policy-title {
        margin-bottom: 10px; }
      #dataPolicy.short > div > div .policy-content {
        text-overflow: ellipsis;
        height: 4.5rem;
        overflow: hidden; }
      #dataPolicy.short > div > div.button-container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        #dataPolicy.short > div > div.button-container .see-more {
          display: inline-block;
          margin-bottom: 10px;
          text-decoration: underline; }
  @media (max-width: 1440px) {
    #dataPolicy.short > div {
      padding: 10px 200px 0; } }
  @media (max-width: 1200px) {
    #dataPolicy.short > div {
      padding: 10px 100px 0; } }
  @media (max-width: 991px) {
    #dataPolicy.short {
      height: 135px; }
      #dataPolicy.short > div > div .policy-content {
        height: 4rem; } }
  @media (max-width: 745px) {
    #dataPolicy.short > div {
      padding: 10px 20px 0;
      grid-template-columns: 1fr 125px;
      grid-gap: 10px; } }
  @media (max-width: 400px) {
    #dataPolicy.short > div {
      padding: 10px 10px 0;
      grid-template-columns: 1fr 125px;
      grid-gap: 0; }
      #dataPolicy.short > div :first-child {
        margin-right: 0; } }

.category-filter-container {
  width: 100%;
  max-width: 400px; }
  .category-filter-container .category-filter {
    border-radius: 20px;
    overflow: hidden;
    position: relative; }
    .category-filter-container .category-filter .category-header {
      background: none;
      box-shadow: none;
      border: none;
      outline: none;
      width: 100%;
      text-align: left; }
      .category-filter-container .category-filter .category-header img {
        width: 50px;
        height: 50px;
        object-fit: contain;
        object-position: center; }
    .category-filter-container .category-filter .bg-color-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0.2; }
    .category-filter-container .category-filter .children-container {
      transition: 0.5s;
      overflow: hidden; }
      .category-filter-container .category-filter .children-container .subcat-header {
        transition: 0.3s;
        position: relative;
        overflow: hidden; }
        .category-filter-container .category-filter .children-container .subcat-header .indicator {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 3px;
          height: 100%;
          transition: 0.3s;
          -webkit-transform: translateY(-100%);
                  transform: translateY(-100%);
          background: var(--tooltip-color); }
        .category-filter-container .category-filter .children-container .subcat-header:hover h1, .category-filter-container .category-filter .children-container .subcat-header.active-subcat h1 {
          font-family: quicksand-bold; }
        .category-filter-container .category-filter .children-container .subcat-header:hover .indicator, .category-filter-container .category-filter .children-container .subcat-header.active-subcat .indicator {
          -webkit-transform: translateY(0);
                  transform: translateY(0); }
    .category-filter-container .category-filter.grocery {
      background: #e4f2d2; }
    .category-filter-container .category-filter.health {
      background: #ffdccc; }
    .category-filter-container .category-filter.pharmacy {
      background: #f9cdd0; }
  .category-filter-container .category-m-selector {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 10;
    transition: 0.3s;
    pointer-events: none;
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0;
    display: none; }
    .category-filter-container .category-m-selector .header img {
      width: 30px;
      height: 30px;
      object-fit: contain;
      object-position: center;
      margin-right: 5px; }
    .category-filter-container .category-m-selector .cat-m-option {
      background-position: center;
      background-size: cover;
      transition: 0.3s; }
      .category-filter-container .category-m-selector .cat-m-option > div {
        background-color: rgba(0, 0, 0, 0.5); }
      .category-filter-container .category-m-selector .cat-m-option:hover {
        opacity: 0.5; }
    .category-filter-container .category-m-selector .overflow-options {
      overflow: auto; }
    .category-filter-container .category-m-selector.show {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
      pointer-events: auto; }
    @media (max-width: 991px) {
      .category-filter-container .category-m-selector {
        display: -webkit-flex;
        display: flex; } }
  @media (max-width: 991px) {
    .category-filter-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 5px;
      max-width: 100%; }
      .category-filter-container .category-filter {
        margin-right: 0;
        border-radius: 5px; }
        .category-filter-container .category-filter .relative-container {
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
                  align-items: center;
          height: 100%; }
        .category-filter-container .category-filter .category-header {
          padding: 10px;
          width: 100%; }
          .category-filter-container .category-filter .category-header img {
            width: 30px;
            height: 30px; }
        .category-filter-container .category-filter .children-container {
          display: none; } }
  @media (max-width: 650px) {
    .category-filter-container {
      grid-template-columns: 1fr;
      margin-bottom: 20px; }
      .category-filter-container .category-filter {
        margin-bottom: 0; } }

.circle-loader {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  -webkit-transform: scale(0.7);
          transform: scale(0.7); }
  .circle-loader > div {
    position: absolute;
    width: 100%;
    height: 50%;
    overflow: hidden;
    left: 0;
    top: 0;
    -webkit-animation-name: rotateLoader;
            animation-name: rotateLoader;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0.9; }
    .circle-loader > div::after {
      content: "";
      position: absolute;
      width: 150px;
      height: 150px;
      border: 20px solid #fe4f00;
      border-radius: 100%;
      box-shadow: inset 0 5px 5px rgba(241, 170, 170, 0.2); }
    .circle-loader > div:nth-child(2) {
      -webkit-animation-duration: 4s;
              animation-duration: 4s; }
      .circle-loader > div:nth-child(2)::after {
        border-color: #000000;
        border-width: 15px; }
    .circle-loader > div:last-child {
      -webkit-animation-duration: 3s;
              animation-duration: 3s;
      animation-direction: reverse; }
      .circle-loader > div:last-child::after {
        border-color: white;
        border-width: 10px; }

@-webkit-keyframes rotateLoader {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotateLoader {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.triangle-loader {
  width: 100px;
  height: 100px;
  position: relative; }
  .triangle-loader div {
    position: absolute;
    width: 5px;
    height: 100%;
    bottom: 0;
    overflow: hidden; }
    .triangle-loader div::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #fff;
      left: 0;
      top: 0; }
    .triangle-loader div:first-child {
      left: -1px;
      bottom: 3px;
      -webkit-transform: rotate(30deg);
              transform: rotate(30deg);
      -webkit-transform-origin: 0 100%;
              transform-origin: 0 100%; }
      .triangle-loader div:first-child::after {
        -webkit-transform: translateY(100%);
                transform: translateY(100%);
        -webkit-animation-name: slantedAnimation;
                animation-name: slantedAnimation;
        -webkit-animation-duration: 1.5s;
                animation-duration: 1.5s;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite; }
    .triangle-loader div:nth-child(2) {
      right: -2px;
      -webkit-transform: rotate(-30deg);
              transform: rotate(-30deg);
      -webkit-transform-origin: 0 100%;
              transform-origin: 0 100%; }
      .triangle-loader div:nth-child(2)::after {
        -webkit-transform: translateY(-100%);
                transform: translateY(-100%);
        -webkit-animation-name: slantedAnimationReverse;
                animation-name: slantedAnimationReverse;
        -webkit-animation-duration: 1.5s;
                animation-duration: 1.5s;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
        -webkit-animation-delay: 0.5s;
                animation-delay: 0.5s; }
    .triangle-loader div:last-child {
      width: 100%;
      height: 5px;
      left: 0; }
      .triangle-loader div:last-child::after {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
        -webkit-animation-name: horizontalAnimation;
                animation-name: horizontalAnimation;
        -webkit-animation-duration: 1.5s;
                animation-duration: 1.5s;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
        -webkit-animation-delay: 1s;
                animation-delay: 1s; }

@-webkit-keyframes slantedAnimation {
  from {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  33% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); }
  to {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); } }

@keyframes slantedAnimation {
  from {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  33% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); }
  to {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); } }

@-webkit-keyframes slantedAnimationReverse {
  from {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  33% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); }
  to {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); } }

@keyframes slantedAnimationReverse {
  from {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  33% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); }
  to {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); } }

@-webkit-keyframes horizontalAnimation {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  33.33% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

@keyframes horizontalAnimation {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  33.33% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

@media (max-width: 1024px) {
  .grid-lg-1 {
    grid-template-columns: 1fr; }
  .grid-lg-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)); }
  .grid-lg-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)); }
  .grid-lg-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)); } }

@media (max-width: 1284px) {
  .show-md {
    display: block; }
    .show-md-flex {
      display: -webkit-flex;
      display: flex; }
  .hide-md {
    display: none; } }

@media (max-width: 991px) {
  .show-md {
    display: block; }
    .show-md-flex {
      display: -webkit-flex;
      display: flex; }
  .hide-md {
    display: none; }
  .page-body-container .page-banner-image {
    min-height: 400px; }
  .modal-align-vertical {
    max-width: 80% !important; }
  .more-products-container .grid-3-auto {
    grid-template-columns: minmax(auto, 300px) minmax(auto, 300px); }
  .more-products-container .grid-4-auto {
    grid-template-columns: minmax(auto, 300px) minmax(auto, 300px) minmax(auto, 300px); }
  .more-products-container .product-card-holder {
    max-width: 300px; }
  .grid-md-1 {
    grid-template-columns: 1fr; }
  .grid-md-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)); }
  .grid-md-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)); }
  .grid-md-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)); } }

@media (max-width: 768px) {
  .hide-xs {
    display: none; }
  .show-xs {
    display: block; }
    .show-xs-flex {
      display: -webkit-flex;
      display: flex; }
  .margin-30 {
    margin: 25px; }
  .margin-35 {
    margin: 25px; }
  .margin-40 {
    margin: 30px; }
  .margin-45 {
    margin: 35px; }
  .margin-50 {
    margin: 35px; }
  .margin-left-30 {
    margin-left: 25px; }
  .margin-left-35 {
    margin-left: 25px; }
  .margin-left-40 {
    margin-left: 30px; }
  .margin-left-45 {
    margin-left: 35px; }
  .margin-left-50 {
    margin-left: 35px; }
  .margin-left-100 {
    margin-left: 50px; }
  .margin-right-30 {
    margin-right: 25px; }
  .margin-right-35 {
    margin-right: 25px; }
  .margin-right-40 {
    margin-right: 30px; }
  .margin-right-45 {
    margin-right: 35px; }
  .margin-right-50 {
    margin-right: 35px; }
  .margin-right-100 {
    margin-right: 50px; }
  .margin-top-30 {
    margin-top: 25px; }
  .margin-top-35 {
    margin-top: 25px; }
  .margin-top-40 {
    margin-top: 30px; }
  .margin-top-45 {
    margin-top: 35px; }
  .margin-top-50 {
    margin-top: 35px; }
  .margin-top-100 {
    margin-top: 50px; }
  .margin-bottom-30 {
    margin-bottom: 25px; }
  .margin-bottom-35 {
    margin-bottom: 25px; }
  .margin-bottom-40 {
    margin-bottom: 30px; }
  .margin-bottom-45 {
    margin-bottom: 35px; }
  .margin-bottom-50 {
    margin-bottom: 35px; }
  .margin-bottom-100 {
    margin-bottom: 50px; }
  .margin-xs-0 {
    margin: 0; }
  .pad {
    padding: 5px; }
    .pad-25, .pad-30, .pad-35, .pad-40, .pad-45, .pad-50, .pad-100 {
      padding: 20px; }
    .pad-left-25 {
      padding-left: 20px; }
    .pad-left-30 {
      padding-left: 20px; }
    .pad-left-35 {
      padding-left: 25px; }
    .pad-left-40 {
      padding-left: 30px; }
    .pad-left-45 {
      padding-left: 35px; }
    .pad-left-50 {
      padding-left: 35px; }
    .pad-left-100 {
      padding-left: 40px; }
    .pad-right-25 {
      padding-right: 20px; }
    .pad-right-30 {
      padding-right: 20px; }
    .pad-right-35 {
      padding-right: 25px; }
    .pad-right-40 {
      padding-right: 30px; }
    .pad-right-45 {
      padding-right: 35px; }
    .pad-right-50 {
      padding-right: 35px; }
    .pad-right-100 {
      padding-right: 40px; }
    .pad-top-25 {
      padding-top: 20px; }
    .pad-top-30 {
      padding-top: 20px; }
    .pad-top-35 {
      padding-top: 25px; }
    .pad-top-40 {
      padding-top: 30px; }
    .pad-top-45 {
      padding-top: 35px; }
    .pad-top-50 {
      padding-top: 35px; }
    .pad-top-100 {
      padding-top: 40px; }
    .pad-bottom-25 {
      padding-bottom: 20px; }
    .pad-bottom-30 {
      padding-bottom: 20px; }
    .pad-bottom-35 {
      padding-bottom: 25px; }
    .pad-bottom-40 {
      padding-bottom: 30px; }
    .pad-bottom-45 {
      padding-bottom: 35px; }
    .pad-bottom-50 {
      padding-bottom: 35px; }
    .pad-bottom-100 {
      padding-bottom: 40px; }
  .pad-xs-0 {
    padding: 0 !important; }
  .container {
    max-width: 100%;
    width: 100%; }
  .page-body-container .page-banner-image {
    min-height: 300px; }
  .page-body-container .page-banner-text {
    padding: 15px; }
    .page-body-container .page-banner-text p {
      opacity: 0.4;
      font-size: 18px; }
      .page-body-container .page-banner-text p.txt-header {
        font-size: 36px;
        opacity: 1; }
  .page-body-container .page-container {
    margin-top: 20px; }
    .page-body-container .page-container-full-xs {
      max-width: 100%;
      padding: 0; }
  .modal-align-vertical {
    max-width: 90% !important; }
  .grid-xs-1 {
    grid-template-columns: 1fr; }
  .grid-xs-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)); }
  .grid-xs-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)); }
  .grid-xs-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)); }
  .grid-gap-50 {
    grid-gap: 10px; }
  .more-products-container .grid-4-auto {
    grid-template-columns: minmax(auto, 300px) minmax(auto, 300px) minmax(auto, 300px); } }

@media (max-width: 675px) {
  .more-products-container .grid-4-auto {
    grid-template-columns: minmax(auto, 300px) minmax(auto, 300px); } }

@media (max-width: 600px) {
  .added-product-container .button-container {
    display: block; }
    .added-product-container .button-container .btn {
      margin: 10px auto;
      font-size: 12px;
      display: block;
      max-width: 300px;
      width: 100%; } }

@media (max-width: 576px) {
  .container {
    max-width: 100%;
    width: 100%; }
  .more-products-container .grid-4-auto {
    grid-template-columns: repeat(2, minmax(0, 1fr)); }
  .more-products-container > .flex {
    display: block; } }

@media (max-height: 550px) {
  .modal .modal-align-vertical {
    margin: 50px auto 10px;
    height: auto;
    min-height: 100%; }
  .added-product-container {
    grid-template-columns: 1fr;
    grid-gap: 5px; }
    .added-product-container .image-container {
      height: 100px;
      margin-top: 5px; } }

.with-leaf {
  position: relative; }
  .with-leaf img {
    position: absolute;
    bottom: 0;
    -webkit-transform: translate(-50%, 150%);
            transform: translate(-50%, 150%);
    left: 50%; }

.z-index-1 {
  z-index: 1; }

.z-index-2 {
  z-index: 2; }

button {
  font-size: 15px;
  letter-spacing: 0.75px; }

.btn {
  border-radius: 29px;
  font-family: "poppins-regular";
  padding: 0.625rem 1.5rem;
  color: #fff;
  background: #4e4e4e;
  font-size: 15px;
  transition: 0.3s;
  letter-spacing: 0.75px !important; }
  .btn.with-icon {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
  .btn-icon {
    width: auto;
    height: auto;
    background: transparent;
    padding: 0;
    width: 40px;
    height: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .btn.btn-primary {
    background: #58a300;
    border-color: #58a300; }
    .btn.btn-primary:disabled {
      background: #6f6f6f;
      border-color: #6f6f6f; }
  .btn.btn-secondary {
    background: #58a300;
    border-color: #58a300; }
    .btn.btn-secondary-dark {
      background: #d9580a; }
      .btn.btn-secondary-dark:hover {
        color: #fff; }
  .btn.btn-green {
    background: #58a300;
    border: 2px solid #58a300; }
    .btn.btn-green:hover {
      background: transparent;
      color: #164e36; }
  .btn.btn-gray {
    background: #4e4e4e; }
  .btn.btn-lgray {
    border-color: #c9c9c9;
    background: #c9c9c9;
    color: #164e36; }
  .btn.btn-lgray-white {
    border-color: #c9c9c9;
    background: #c9c9c9;
    color: white; }
    .btn.btn-lgray-white:hover {
      background: #6e6e6e; }
  .btn.btn-lyellow {
    border-color: #fcf7ea;
    background: #fcf7ea;
    color: #103E29; }
    .btn.btn-lyellow:hover {
      background: #f9ebc7; }
  .btn.btn-mgray {
    background: #5e5e5e;
    color: #111; }
  .btn.btn-violet {
    background: #c9287d; }
    .btn.btn-violet:hover {
      color: #fff; }
  .btn.btn-clear {
    background: transparent;
    color: #58a300;
    border: 3px solid #58a300; }
    .btn.btn-clear.black {
      color: #000000;
      border: 1px solid #000000; }
    .btn.btn-clear.clear-black {
      color: #000000; }
    .btn.btn-clear.secondary {
      color: #fe4f00;
      border: 1px solid #fe4f00; }
    .btn.btn-clear-green {
      background: transparent;
      border: 2px solid #58a300;
      color: #58a300; }
      .btn.btn-clear-green:hover {
        background: #58a300;
        color: #fff; }
    .btn.btn-clear.white {
      color: white;
      border: 1px solid white; }
    .btn.btn-clear.link {
      color: #356077;
      border: 1px solid #356077; }
  .btn.btn-black {
    background: #000000; }
    .btn.btn-black:hover {
      color: #fff; }
  .btn.btn-white {
    background: #fff;
    color: #fe4f00; }
    .btn.btn-white:active {
      background-color: #fff !important; }
  .btn.btn-oblivion {
    background: #000; }
    .btn.btn-oblivion:hover {
      color: #fff; }
  .btn.btn-fb {
    background: #3b5998;
    color: #fff; }
  .btn.btn-twitter {
    background: #38a1f3;
    color: #fff; }
  .btn.btn-google {
    background: #ff3e30;
    color: #fff; }
  .btn.btn-sm, .btn-group-sm > .btn {
    font-size: 12px; }
  .btn.btn-lg, .btn-group-lg > .btn {
    font-size: 18px; }
  .btn.btn-pad-large {
    padding: 10px 30px; }
  .btn.btn-pad-xlarge {
    padding: 15px 30px; }
  .btn.no-padding {
    padding: 0; }
  .btn.btn-block {
    display: block; }
    .btn.btn-block-center {
      margin: auto; }
  .btn.btn-sqr {
    border-radius: 0; }
  .btn.btn-bold {
    font-weight: bold; }
  .btn .icon-container {
    padding-left: 20px; }
  .btn.btn-nostyles {
    padding: 0;
    background: none; }
  .btn:active:not(.no-transition) {
    transition: 0.1s;
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.5) !important;
    background: inherit; }

.btn:focus:not(:active) {
  box-shadow: none !important; }

.button-loader {
  border-radius: 25px;
  overflow: hidden;
  margin: auto;
  position: relative;
  transition: 0.5s;
  max-width: 500px; }
  .button-loader .loading-state,
  .button-loader .sent-state {
    position: absolute;
    width: 43px;
    height: 43px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    color: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 20px;
    -webkit-transform: scale(0);
            transform: scale(0);
    border-radius: 100%; }
  .button-loader .loading-state {
    background: #fe4f00; }
    .button-loader .loading-state i {
      -webkit-animation-name: float;
              animation-name: float;
      -webkit-animation-duration: 2s;
              animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
              animation-timing-function: linear; }
  .button-loader .sent-state {
    background: #164e36; }
  .button-loader.loading {
    max-width: 43px; }
    .button-loader.loading .loading-state {
      transition: 0.5s;
      -webkit-transform: scale(1);
              transform: scale(1); }
  .button-loader.sent {
    max-width: 43px; }
    .button-loader.sent .loading-state {
      -webkit-transform: scale(1);
              transform: scale(1); }
    .button-loader.sent .sent-state {
      transition: 0.5s;
      -webkit-transform: scale(1);
              transform: scale(1); }

.underline-hover {
  position: relative; }
  .underline-hover::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: #fff;
    bottom: 0;
    left: 0;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
    transition: 0.3s; }
  .underline-hover.uh-black::after {
    background: #000000; }
  .underline-hover:hover::after, .underline-hover.active::after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); }

.scale-hover {
  transition: 0.3s; }
  .scale-hover:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }

[material-checkbox="primary"] {
  color: #009ee2 !important;
  padding: 5px !important; }

@media (max-width: 1415px) {
  .btn.btn-pad-xlarge {
    padding: 15px 10px; } }

@media (max-width: 1024px) {
  .btn.btn-pad-xlarge {
    padding: 10px; } }

@media (max-width: 767px) {
  .btn {
    font-size: 12px; }
    .btn.btn-lg, .btn-group-lg > .btn {
      font-size: 15px; }
    .btn.btn-pad-large {
      padding: 5px 15px; }
    .btn.btn-pad-xlarge {
      padding: 10px 15px; } }

@media (max-width: 500px) {
  .btn.btn-pad-large {
    padding: 5px; }
  .btn.btn-pad-xlarge {
    padding: 10px; } }

.main-navbar .nav-fixed {
  width: 100%;
  z-index: 99999; }

.main-navbar .nav-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  cursor: pointer;
  z-index: 1; }
  .main-navbar .nav-overlay.open {
    display: block; }

.main-navbar .mobile-search-container {
  display: none;
  padding: 10px;
  background: #fe4f00; }
  .main-navbar .mobile-search-container > .search-container {
    background: #fff; }

.main-navbar .nav-disclaimer {
  background: linear-gradient(90deg, #e85523, #f47a21, #f19f19);
  color: white;
  padding: 30px;
  position: relative; }
  .main-navbar .nav-disclaimer img {
    position: absolute;
    width: 400px;
    height: 100%;
    object-fit: contain;
    object-position: right bottom;
    right: 82px;
    top: 0; }

.main-navbar .navbar {
  background-color: #fff;
  padding: 0;
  transition: 0.3s; }
  .main-navbar .navbar .user-indicator {
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: translateX(20%) translateY(-20%);
            transform: translateX(20%) translateY(-20%);
    background: #fff;
    color: #e20612 !important;
    pointer-events: none; }
  .main-navbar .navbar .hamburger {
    width: 22px;
    height: 15px;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    z-index: 1;
    margin-right: 15px; }
    .main-navbar .navbar .hamburger span {
      display: block;
      background-color: #58a300;
      border-radius: 50px;
      position: absolute;
      height: 3px;
      width: 100%;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      transition: 0.25s ease-in-out; }
      .main-navbar .navbar .hamburger span:nth-child(1) {
        top: 0px; }
      .main-navbar .navbar .hamburger span:nth-child(2), .main-navbar .navbar .hamburger span:nth-child(3) {
        top: 7px; }
      .main-navbar .navbar .hamburger span:nth-child(3) {
        top: 14px; }
  .main-navbar .navbar .leaf {
    position: absolute;
    top: 0;
    height: 100%;
    opacity: 0.4;
    max-width: none !important;
    width: auto !important; }
    .main-navbar .navbar .leaf-left {
      left: 0; }
    .main-navbar .navbar .leaf-right {
      right: 0; }
  .main-navbar .navbar .container {
    max-width: 95%;
    width: 100%;
    padding: 15px 0;
    position: relative; }
    .main-navbar .navbar .container > * {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-align-self: center;
              align-self: center; }
    .main-navbar .navbar .container .auto-suggest-container {
      transition: 0.3s;
      opacity: 0;
      -webkit-transform: translateY(-30px);
              transform: translateY(-30px); }
      .main-navbar .navbar .container .auto-suggest-container.show {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        opacity: 1; }
      .main-navbar .navbar .container .auto-suggest-container .dismiss-search {
        position: absolute;
        right: 10px;
        top: 10px;
        display: none; }
      .main-navbar .navbar .container .auto-suggest-container .mobile-label {
        display: none; }
    .main-navbar .navbar .container .search-container {
      min-width: 500px;
      width: 100%; }
    .main-navbar .navbar .container .navbar-link {
      margin: 0;
      padding: 0;
      font-size: 1rem;
      margin-left: 20px;
      font-family: "poppins-light";
      position: relative;
      color: #fff; }
      .main-navbar .navbar .container .navbar-link:nth-child(2) {
        margin-left: 40px; }
      .main-navbar .navbar .container .navbar-link::after {
        content: "";
        position: absolute;
        bottom: -14px;
        left: 50%;
        width: 100%;
        max-width: 0;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        height: 6px;
        border-radius: 10px;
        opacity: 0;
        background-color: #58a300;
        transition: max-width 0.3s ease; }
      .main-navbar .navbar .container .navbar-link:hover::after, .main-navbar .navbar .container .navbar-link.active::after {
        max-width: 50px;
        opacity: 1; }
    .main-navbar .navbar .container .navbar-header .navbar-brand {
      margin: 0;
      padding: 0; }
      .main-navbar .navbar .container .navbar-header .navbar-brand img {
        max-height: 47px;
        height: 100%;
        object-fit: contain; }
    .main-navbar .navbar .container .navbar-right .navbar-link {
      margin-left: 0;
      margin-right: 20px; }
      .main-navbar .navbar .container .navbar-right .navbar-link .fa-user {
        font-size: 12px;
        -webkit-transform: translateY(-11%);
                transform: translateY(-11%); }
      .main-navbar .navbar .container .navbar-right .navbar-link .fa-circle {
        font-size: 8px;
        -webkit-transform: translateY(-35%);
                transform: translateY(-35%);
        color: #58a300; }
    @media (max-width: 430px) {
      .main-navbar .navbar .container .navbar-right {
        -webkit-justify-content: space-between;
                justify-content: space-between; } }
    .main-navbar .navbar .container .nav--search-btn {
      display: none; }
  .main-navbar .navbar .nav-stickout {
    position: fixed;
    left: 0;
    top: 0;
    background: #fff;
    width: 100%;
    padding: 0;
    z-index: 100;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    transition: 0.3s; }
    .main-navbar .navbar .nav-stickout .container {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      padding: 10px; }
      .main-navbar .navbar .nav-stickout .container .navbar-header {
        height: 40px; }

.main-navbar .profile-dropdown button {
  background: none;
  border: none; }
  .main-navbar .profile-dropdown button:active {
    background: none !important;
    box-shadow: none !important;
    border: none !important;
    -webkit-transform: none !important;
            transform: none !important; }

.main-navbar .profile-dropdown ul {
  padding: 0 15px; }
  .main-navbar .profile-dropdown ul li a {
    display: block;
    padding: 5px 0; }
  .main-navbar .profile-dropdown ul li:last-of-type a {
    border-top: 1px solid #cbcbcb; }

.main-navbar .nav-sidebar-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
  transition: 0.3s;
  transition-delay: 0.3s;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  color: #000000;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  opacity: 0;
  color: #000000; }
  .main-navbar .nav-sidebar-container .link-container {
    background: #fcf7ea;
    min-width: 300px;
    max-width: 400px;
    max-height: 100%;
    width: 100%;
    height: 100%;
    box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  .main-navbar .nav-sidebar-container .action-container {
    min-height: 30px; }
    .main-navbar .nav-sidebar-container .action-container .customer-details {
      box-shadow: inset 0 -10px 10px rgba(0, 0, 0, 0.1);
      text-align: center;
      background: #164e36;
      color: #fff; }
      .main-navbar .nav-sidebar-container .action-container .customer-details img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 100%; }
    .main-navbar .nav-sidebar-container .action-container .sidenav-toggle-btn {
      position: absolute;
      right: 10px;
      top: 10px;
      color: #fff; }
      .main-navbar .nav-sidebar-container .action-container .sidenav-toggle-btn i {
        font-size: 18px; }
  .main-navbar .nav-sidebar-container .menu-list {
    overflow: auto;
    position: relative; }
    .main-navbar .nav-sidebar-container .menu-list ul li {
      font-size: 14px;
      font-family: "cerapro-bold";
      position: relative; }
      .main-navbar .nav-sidebar-container .menu-list ul li a {
        position: relative;
        display: block;
        z-index: 2;
        padding: 12px 50px; }
    .main-navbar .nav-sidebar-container .menu-list > ul li a {
      position: relative;
      transition: 0.3s; }
      .main-navbar .nav-sidebar-container .menu-list > ul li a:after {
        content: "";
        bottom: 0;
        left: 0;
        height: 100%;
        width: 8px;
        background-color: #164e36;
        position: absolute;
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
        transition: 0.3s;
        color: #fff; }
      .main-navbar .nav-sidebar-container .menu-list > ul li a.active, .main-navbar .nav-sidebar-container .menu-list > ul li a:hover {
        color: #164e36; }
        .main-navbar .nav-sidebar-container .menu-list > ul li a.active:after, .main-navbar .nav-sidebar-container .menu-list > ul li a:hover:after {
          -webkit-transform: translateX(0);
                  transform: translateX(0); }
    .main-navbar .nav-sidebar-container .menu-list .mobile-linkouts {
      border-top: 1px solid #ccc; }
  .main-navbar .nav-sidebar-container .quick-links {
    border-top: 1px solid #dfdfdf; }
    .main-navbar .nav-sidebar-container .quick-links a {
      display: block;
      color: #9d9d9d;
      padding: 12px 50px;
      transition: 0.3s; }
      .main-navbar .nav-sidebar-container .quick-links a:hover {
        color: #000000; }
  .main-navbar .nav-sidebar-container .click-out {
    position: fixed;
    right: 0;
    width: calc(100vw - 400px);
    height: 100%; }
  .main-navbar .nav-sidebar-container.open {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
    transition: opacity 0.3s;
    transition-delay: 0s; }
    .main-navbar .nav-sidebar-container.open .link-container {
      transition-delay: 0.3s;
      -webkit-transform: translateX(0);
              transform: translateX(0); }

.main-navbar.moved .navbar .nav-stickout {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  box-shadow: 0 0px 12px 8px rgba(0, 0, 0, 0.3); }

@media (max-width: 1450px) {
  .main-navbar .nav-disclaimer img {
    right: 0; }
  .main-navbar .nav-links {
    padding: 0; }
    .main-navbar .nav-links .link {
      padding: 10px; } }

@media (max-width: 991px) {
  .main-navbar .nav-disclaimer img {
    width: 200px; } }

@media (max-width: 767px) {
  .main-navbar .navbar .container {
    grid-gap: 5px;
    margin: auto;
    width: 95%;
    max-width: 100%;
    padding: 10px; }
    .main-navbar .navbar .container .modal-registration-button,
    .main-navbar .navbar .container .checkout-btn,
    .main-navbar .navbar .container .line-separator,
    .main-navbar .navbar .container .modal-login-btn-container,
    .main-navbar .navbar .container .nav--checkout-btn {
      display: none; }
    .main-navbar .navbar .container .auto-suggest-container,
    .main-navbar .navbar .container .auto-suggest-container.show {
      position: fixed;
      width: 100%;
      height: 100%;
      background: #fff;
      left: 0;
      top: 0;
      z-index: 10;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      padding: 20px;
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
      opacity: 0;
      pointer-events: none; }
      .main-navbar .navbar .container .auto-suggest-container .search-container,
      .main-navbar .navbar .container .auto-suggest-container.show .search-container {
        position: relative; }
      .main-navbar .navbar .container .auto-suggest-container .dismiss-search,
      .main-navbar .navbar .container .auto-suggest-container .mobile-label,
      .main-navbar .navbar .container .auto-suggest-container.show .dismiss-search,
      .main-navbar .navbar .container .auto-suggest-container.show .mobile-label {
        display: block; }
      .main-navbar .navbar .container .auto-suggest-container.mobile-show,
      .main-navbar .navbar .container .auto-suggest-container.show.mobile-show {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
        pointer-events: auto; } }

@media (max-width: 500px) {
  .main-navbar .mobile-search-container {
    display: none; }
  .main-navbar .nav-disclaimer img {
    width: 200px; }
  .main-navbar .navbar .container {
    grid-gap: 10px; }
    .main-navbar .navbar .container .search-container {
      min-width: 0; }
    .main-navbar .navbar .container .nav--search-btn {
      display: block; } }

@media (max-width: 420px) {
  .main-navbar .navbar .container .modal-login-button i {
    font-size: 20px;
    margin: 0; }
  .main-navbar .navbar .container .modal-login-button span {
    display: none; } }

@media (max-width: 355px) {
  .main-navbar .navbar .container .navbar-header .navbar-brand img {
    max-height: 35px; } }

.cart-dropdown button {
  background: none;
  border: none; }
  .cart-dropdown button:active {
    background: none !important;
    box-shadow: none !important;
    border: none !important;
    -webkit-transform: none !important;
            transform: none !important; }

.cart-dropdown .nav-circle-container i {
  font-size: 18px; }

.cart-dropdown .nav-circle-container .cart-indicator {
  position: absolute;
  top: 2px;
  right: 15px;
  border: 1px solid #fff;
  border-radius: 100%;
  height: 12px;
  width: 12px;
  background-color: #e52632; }

.cart-dropdown .dropdown-menu {
  -webkit-transform: translate3d(-90px, 42px, 0px) !important;
          transform: translate3d(-90px, 42px, 0px) !important; }
  .cart-dropdown .dropdown-menu.show {
    width: 300px !important; }

.cart-items .cart-container {
  padding: 10px; }
  .cart-items .cart-container .mb-10 {
    margin-bottom: 10px; }
  .cart-items .cart-container .product-details {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .cart-items .cart-container .item {
    border: 1px solid #c9c9c9;
    padding: 5px; }
    .cart-items .cart-container .item img {
      margin-top: 24px;
      height: 80px;
      width: 80px; }
    .cart-items .cart-container .item .delete-item {
      text-align: right;
      cursor: pointer; }
  .cart-items .cart-container .item-details {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }

.cart-items .cart-popup-footer {
  padding: 0 10px 10px 10px; }
  .cart-items .cart-popup-footer .price-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .cart-items .cart-popup-footer .btn-container {
    margin-top: 5px;
    text-align: right; }

.footer {
  background-color: #164e36;
  position: relative; }
  .footer--bg-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    object-position: center;
    opacity: 0.1; }
  .footer .landscape {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -32%);
            transform: translate(-50%, -32%);
    pointer-events: none; }
  .footer > .page-container {
    position: relative;
    padding: 0 0 50px 0; }
    .footer > .page-container .footer--quicklink {
      opacity: 0.8;
      transition: 0.3s; }
      .footer > .page-container .footer--quicklink i {
        width: 20px; }
      .footer > .page-container .footer--quicklink:hover {
        opacity: 1; }
    .footer > .page-container .footer-store-details {
      width: 100%; }
      .footer > .page-container .footer-store-details > :first-child {
        border-bottom: 1px solid #cbcbcb;
        padding-bottom: 20px; }
      .footer > .page-container .footer-store-details > :nth-child(2) {
        margin-top: 20px; }
      .footer > .page-container .footer-store-details .copy-right {
        max-width: 240px; }
        .footer > .page-container .footer-store-details .copy-right img {
          height: 60px; }
      .footer > .page-container .footer-store-details .contacts {
        max-width: 240px;
        text-align: left; }
      .footer > .page-container .footer-store-details .grid-150-1fr {
        display: grid;
        grid-template-columns: 300px 1fr;
        grid-gap: 10px;
        padding-left: 20%;
        padding-right: 20%; }
      .footer > .page-container .footer-store-details .shops,
      .footer > .page-container .footer-store-details .soc-med {
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        padding-left: unset;
        padding-right: unset; }
      .footer > .page-container .footer-store-details .btn {
        margin-bottom: 20px; }
    .footer > .page-container .cc-details img {
      max-width: 70px;
      width: 100%;
      margin-right: 10px; }
    .footer > .page-container .shops a {
      height: 40px;
      width: 150px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-content: center;
              align-content: center;
      -webkit-justify-content: center;
              justify-content: center; }
      .footer > .page-container .shops a img {
        width: 100px;
        height: 21px;
        object-fit: contain;
        object-position: center; }
  @media (max-width: 1530px) {
    .footer > .page-container .footer-store-details .grid-150-1fr {
      padding-left: 15%;
      padding-right: 10%; } }
  @media (max-width: 1250px) {
    .footer > .page-container .footer-store-details .grid-150-1fr {
      padding-left: 10%; } }
  @media (max-width: 1185px) {
    .footer > .page-container .footer-store-details .grid-150-1fr {
      padding-left: 5%; } }
  @media (max-width: 991px) {
    .footer .justify-unset {
      -webkit-justify-content: unset !important;
              justify-content: unset !important; }
    .footer > .page-container {
      max-width: 100%;
      padding: 0 10px; }
      .footer > .page-container .footer-store-details {
        max-width: 100%;
        text-align: center; }
        .footer > .page-container .footer-store-details .flex {
          -webkit-justify-content: center;
                  justify-content: center;
          padding-left: 10%;
          padding-right: 10%; }
        .footer > .page-container .footer-store-details .grid-150-1fr {
          grid-template-columns: 1fr;
          grid-gap: 20px;
          justify-items: center;
          padding-left: unset;
          padding-right: unset; }
        .footer > .page-container .footer-store-details .margin-right-50 {
          margin-right: 15px !important; }
      .footer > .page-container .pad-top-100 {
        padding-top: 30px; } }
  @media (max-width: 767px) {
    .footer .quicklinks {
      text-align: center;
      padding-bottom: 30px; }
      .footer .quicklinks .category-header {
        padding-top: 30px; }
    .footer .landscape {
      top: -20px; } }
  @media (max-width: 500px) {
    .footer > .page-container .footer-store-details .flex {
      padding-left: unset;
      padding-right: unset; } }

.form-control.form-control-lg {
  height: calc(1.5em + 1rem + 10px);
  font-size: 0.875rem;
  font-family: "quicksand-regular";
  color: #4e4e4e; }

.form-control.with-bottom-margin {
  margin-bottom: 20px; }

.form-control:focus {
  border-color: #ced4da;
  box-shadow: none; }

.form-group {
  margin-bottom: 0;
  width: 100%; }
  .form-group > select.form-control.loading {
    background: url("../../assets/img/Loading_icon.gif") no-repeat right #ced4da;
    -webkit-appearance: none;
    background-size: 20px;
    background-position-x: calc(100% - 15px); }
  .form-group label {
    font-size: 15px; }
    .form-group label.lbl-sm {
      font-size: 12px; }

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus, .btn-primary:focus, .btn-primary.focus {
  box-shadow: none; }

.btn.loading {
  pointer-events: none;
  position: relative; }

.btn .icon-container {
  padding-left: 20px; }
  .btn .icon-container.right {
    padding-right: 20px;
    padding-left: 0; }

.input-with-icon-container {
  display: grid;
  grid-template-columns: 1fr auto; }
  .input-with-icon-container input {
    border-radius: 20px; }
  .input-with-icon-container.right-icon input {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0; }
  .input-with-icon-container.right-icon .icon {
    background-color: #164e36;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: #fff;
    padding: 0 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    cursor: pointer; }

.banner-js {
  position: relative; }
  .banner-js .main-banner {
    min-height: 650px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden; }
    .banner-js .main-banner .container > div {
      min-height: 450px; }
      .banner-js .main-banner .container > div img {
        object-fit: contain;
        object-position: top;
        width: 100%;
        height: 100%; }
    .banner-js .main-banner .active-banner {
      width: 100%;
      position: relative;
      background-color: #ccc;
      background: #5e5e5e; }
      .banner-js .main-banner .active-banner .active-image {
        width: 100%;
        height: 650px;
        min-height: 400px;
        transition: 0.3s;
        overflow: hidden;
        position: relative; }
        .banner-js .main-banner .active-banner .active-image .text-details {
          color: #fff;
          -webkit-flex: 1 1;
                  flex: 1 1;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-color: rgba(0, 0, 0, 0.6); }
          .banner-js .main-banner .active-banner .active-image .text-details .search-form i {
            transition: 0.3s; }
            .banner-js .main-banner .active-banner .active-image .text-details .search-form i.scaled {
              -webkit-transform: scale(1.5);
                      transform: scale(1.5); }
          .banner-js .main-banner .active-banner .active-image .text-details .search-form .input-group {
            background-color: #fff;
            padding: 20px 30px;
            border-radius: 20px 0 0 20px; }
            .banner-js .main-banner .active-banner .active-image .text-details .search-form .input-group input {
              border: none;
              outline: none; }
            @media (max-width: 767px) {
              .banner-js .main-banner .active-banner .active-image .text-details .search-form .input-group {
                padding: 10px 15px;
                border-radius: 10px 0 0 10px; }
                .banner-js .main-banner .active-banner .active-image .text-details .search-form .input-group input {
                  font-size: 1rem; } }
          .banner-js .main-banner .active-banner .active-image .text-details .search-form button.btn-primary {
            border-radius: 0 20px 20px 0;
            height: 100%;
            padding-left: 40px;
            padding-right: 40px; }
            @media (max-width: 767px) {
              .banner-js .main-banner .active-banner .active-image .text-details .search-form button.btn-primary {
                padding-left: 20px;
                padding-right: 20px;
                border-radius: 0 10px 10px 0; } }
        .banner-js .main-banner .active-banner .active-image .image-details {
          height: 100%;
          width: 100%;
          position: relative; }
          .banner-js .main-banner .active-banner .active-image .image-details img {
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%; }
            .banner-js .main-banner .active-banner .active-image .image-details img.image-transition {
              opacity: 0; }
          .banner-js .main-banner .active-banner .active-image .image-details .embed_video {
            position: absolute;
            left: 0;
            top: 0;
            object-fit: contain;
            object-position: center;
            width: 100%;
            height: 100%;
            background: #111; }
            .banner-js .main-banner .active-banner .active-image .image-details .embed_video .iframe-container {
              position: absolute;
              width: 100%;
              height: 100%;
              display: -webkit-flex;
              display: flex;
              -webkit-align-items: center;
                      align-items: center; }
              .banner-js .main-banner .active-banner .active-image .image-details .embed_video .iframe-container iframe {
                position: absolute;
                height: 100%;
                width: 100%; }
      .banner-js .main-banner .active-banner.in-transition .active-image .image-details img:not(.image-transition) {
        opacity: 0;
        -webkit-animation-name: fadeIn;
                animation-name: fadeIn;
        -webkit-animation-duration: 0.6s;
                animation-duration: 0.6s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
      .banner-js .main-banner .active-banner.in-transition .active-image .image-details img.image-transition {
        opacity: 1; }
  .banner-js .banner-thumbnails {
    position: absolute;
    width: 100%;
    bottom: 20px;
    left: 0; }
    .banner-js .banner-thumbnails .thumb {
      width: 50px;
      height: 5px;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.5);
      margin: 0 10px;
      position: relative;
      overflow: hidden; }
      .banner-js .banner-thumbnails .thumb::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #fff;
        -webkit-transform: translateX(100%);
                transform: translateX(100%); }
      .banner-js .banner-thumbnails .thumb.active {
        pointer-events: none; }
        .banner-js .banner-thumbnails .thumb.active::after {
          -webkit-transform: translateX(0);
                  transform: translateX(0); }
    .banner-js .banner-thumbnails.next {
      pointer-events: none; }
      .banner-js .banner-thumbnails.next .thumb.active-out::after {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        -webkit-animation-name: thumbOutNext;
                animation-name: thumbOutNext;
        -webkit-animation-duration: 0.5s;
                animation-duration: 0.5s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
      .banner-js .banner-thumbnails.next .thumb.active::after {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
        -webkit-animation-name: thumbInNext;
                animation-name: thumbInNext;
        -webkit-animation-duration: 0.5s;
                animation-duration: 0.5s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
    .banner-js .banner-thumbnails.prev {
      pointer-events: none; }
      .banner-js .banner-thumbnails.prev .thumb.active-out::after {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        -webkit-animation-name: thumbOutPrev;
                animation-name: thumbOutPrev;
        -webkit-animation-duration: 0.5s;
                animation-duration: 0.5s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
      .banner-js .banner-thumbnails.prev .thumb.active::after {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
        -webkit-animation-name: thumbInPrev;
                animation-name: thumbInPrev;
        -webkit-animation-duration: 0.5s;
                animation-duration: 0.5s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
  @media (max-width: 991px) {
    .banner-js.banner-slider .main-banner {
      position: relative; }
      .banner-js.banner-slider .main-banner .active-banner .active-image > div,
      .banner-js.banner-slider .main-banner .active-banner .transition-overlay > div {
        -webkit-flex-direction: column-reverse;
                flex-direction: column-reverse; }
        .banner-js.banner-slider .main-banner .active-banner .active-image > div .image-details,
        .banner-js.banner-slider .main-banner .active-banner .transition-overlay > div .image-details {
          width: 100%;
          margin: 20px 0; } }
  @media (max-width: 767px) {
    .banner-js.banner-slider .banner-thumbnails {
      position: relative;
      margin-bottom: 20px;
      width: 100%; }
    .banner-js.banner-slider .text-details {
      padding: 0 10px; }
      .banner-js.banner-slider .text-details .margin-left-50 {
        margin-left: 10px; }
      .banner-js.banner-slider .text-details .margin-right-50 {
        margin-right: 10px; } }
  @media (max-width: 400px) {
    .banner-js.banner-slider .banner-thumbnails {
      position: relative;
      margin-bottom: 20px;
      width: 100%; }
    .banner-js.banner-slider .text-details .txt-header-2 {
      font-size: 14px; }
    .banner-js.banner-slider .text-details .txt-oversized {
      font-size: 20px; } }

@-webkit-keyframes flyIn {
  from {
    opacity: 0;
    -webkit-transform: translateY(20%);
            transform: translateY(20%); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@keyframes flyIn {
  from {
    opacity: 0;
    -webkit-transform: translateY(20%);
            transform: translateY(20%); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes thumbOutNext {
  from {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  to {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

@keyframes thumbOutNext {
  from {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  to {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

@-webkit-keyframes thumbOutPrev {
  from {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

@keyframes thumbOutPrev {
  from {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

@-webkit-keyframes thumbInNext {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@keyframes thumbInNext {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes thumbInPrev {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  to {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@keyframes thumbInPrev {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  to {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

.product-card-js.product-card {
  position: relative;
  cursor: pointer;
  height: 100%; }
  .product-card-js.product-card .image-container {
    margin-bottom: 15px;
    position: relative;
    background: #fff;
    overflow: hidden; }
    .product-card-js.product-card .image-container img {
      width: 100%;
      object-fit: contain;
      object-position: center;
      transition: 0.3s;
      -webkit-transform: scale(1);
              transform: scale(1);
      position: relative; }
    .product-card-js.product-card .image-container .image-overlays {
      position: absolute;
      top: 0;
      right: 0; }
      .product-card-js.product-card .image-container .image-overlays > div {
        border-radius: 10px;
        padding-top: 7px;
        padding-bottom: 7px; }
    .product-card-js.product-card .image-container .loading-image-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #ccc; }
    .product-card-js.product-card .image-container .hover-overlay {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      background: #282828;
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
      transition: 0.3s; }
  .product-card-js.product-card .discount-badge {
    position: absolute;
    -webkit-transform: translateX(-42.5%);
            transform: translateX(-42.5%);
    top: 0;
    width: 400px; }
    .product-card-js.product-card .discount-badge > div {
      -webkit-transform: rotate(-45deg) scale(0.6);
              transform: rotate(-45deg) scale(0.6);
      background: #e20612;
      width: 100%; }
  .product-card-js.product-card .category {
    color: #c9c9c9;
    font-size: 0.625rem;
    font-family: "quicksand-bold";
    margin: 0; }
  .product-card-js.product-card .product-title {
    margin: 0;
    padding: 10px 0 5px; }
  .product-card-js.product-card .product-price {
    color: #000000;
    padding-bottom: 10px; }
  .product-card-js.product-card .product-star {
    color: #fe4f00; }
    .product-card-js.product-card .product-star i {
      margin-right: 2px; }
  .product-card-js.product-card:hover .image-container img {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  .product-card-js.product-card:hover .image-container .hover-overlay {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  .product-card-js.product-card.ph-item .image-container {
    background-color: #c9c9c9; }

@media (max-width: 550px) {
  .product-card-js.product-card .discount-badge {
    -webkit-transform: translateX(-46%);
            transform: translateX(-46%); }
    .product-card-js.product-card .discount-badge .txt-medium {
      display: none; } }

.product-modal-container {
  display: grid;
  grid-template-columns: 250px 1fr; }
  .product-modal-container .product-image .image-container img {
    max-width: 100%; }
  .product-modal-container .product-info {
    padding: 20px; }

.product-list {
  grid-gap: 30px 50px;
  padding-bottom: 50px; }

.auto-suggest.search-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 13px;
  border: solid 1px #c9c9c9;
  overflow: hidden; }
  .auto-suggest.search-container > i {
    color: #164e36;
    margin-left: 15px; }
  .auto-suggest.search-container button {
    border-radius: 0; }
  .auto-suggest.search-container input {
    border: 0;
    height: 100%;
    width: 100%;
    padding: 10px 15px; }
    .auto-suggest.search-container input:focus {
      box-shadow: none; }

.elements.free-shipping-container {
  display: -webkit-flex;
  display: flex;
  color: #164e36; }
  .elements.free-shipping-container > i {
    font-size: 26px;
    -webkit-align-self: center;
            align-self: center; }
  .elements.free-shipping-container .text {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-size: 12px;
    padding: 5px 10px; }
    .elements.free-shipping-container .text .main {
      font-family: "quicksand-bold";
      font-size: 14px;
      line-height: 14px; }

.skeleton {
  display: block;
  min-height: 10px;
  width: 100%;
  position: relative;
  background-color: #c9c9c9;
  opacity: 0.95;
  overflow: hidden; }
  .skeleton.center {
    margin-left: auto;
    margin-right: auto; }
  .skeleton.clear {
    opacity: 0.5; }
  .skeleton::before {
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    -webkit-animation: phAnimation 2s linear infinite;
    animation: phAnimation 2s linear infinite;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.35) 50%, rgba(255, 255, 255, 0) 54%) 50% 50%; }
  .skeleton.width-50 {
    width: 50px; }

.custom-dropdown-select {
  padding: 5px 10px;
  border-radius: 100px;
  border: 1px solid #d6d6d6;
  background: #f7f7f7; }
  .custom-dropdown-select .selection {
    position: absolute;
    left: 0;
    top: 100%;
    border-radius: 10px;
    border: 1px solid #d6d6d6;
    background: #f7f7f7;
    padding: 5px 0;
    min-width: 100%;
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    pointer-events: none;
    transition: 0.3s;
    z-index: 2; }
    .custom-dropdown-select .selection .select-option {
      padding: 0 10px; }
  .custom-dropdown-select.open .selection {
    pointer-events: auto;
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    opacity: 1; }

.modal-login-button {
  cursor: pointer; }
  .modal-login-button i {
    transition: 0.3s; }
  .modal-login-button:hover i {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); }

.cart-bag-container .bag-icon-container {
  height: 100%;
  color: #4e4e4e;
  position: relative;
  font-size: 25px;
  cursor: pointer; }
  .cart-bag-container .bag-icon-container .bag-count {
    background-color: white;
    color: var(--tooltip-color);
    border-radius: 100%;
    border: 1px solid var(--tooltip-color);
    width: 20px;
    height: 20px;
    font-size: 12px;
    font-weight: bold;
    right: -5px;
    top: -5px;
    position: absolute;
    transition: 0.3s; }
  .cart-bag-container .bag-icon-container i {
    color: var(--tooltip-color); }
  .cart-bag-container .bag-icon-container:hover .bag-count {
    top: -10px;
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }

.cart-bag-container .cart-bag-dropdown-menu {
  margin-top: 0.5rem;
  padding: 20px 15px; }
  .cart-bag-container .cart-bag-dropdown-menu .cart-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .cart-bag-container .cart-bag-dropdown-menu .cart-header > div:first-child {
      fleX: 1; }
  .cart-bag-container .cart-bag-dropdown-menu .item-list {
    max-height: 300px;
    overflow: auto; }
  .cart-bag-container .cart-bag-dropdown-menu .cart-item {
    position: relative;
    border-radius: 5px;
    border: 1px solid #c9c9c9;
    margin-bottom: 10px;
    padding: 10px;
    width: 370px; }
    .cart-bag-container .cart-bag-dropdown-menu .cart-item .image-container {
      width: 50px;
      height: 50px;
      position: relative;
      margin-right: 10px; }
      .cart-bag-container .cart-bag-dropdown-menu .cart-item .image-container img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: contain;
        object-position: center;
        background-color: #aaa; }
    .cart-bag-container .cart-bag-dropdown-menu .cart-item .item-details {
      -webkit-flex: 1 1;
              flex: 1 1;
      position: relative; }
      .cart-bag-container .cart-bag-dropdown-menu .cart-item .item-details .delete-item {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        transition: 0.6s;
        opacity: 1; }
        .cart-bag-container .cart-bag-dropdown-menu .cart-item .item-details .delete-item:hover {
          opacity: .6; }
      .cart-bag-container .cart-bag-dropdown-menu .cart-item .item-details .item-title {
        font-size: 0.875rem; }
      .cart-bag-container .cart-bag-dropdown-menu .cart-item .item-details .item-qty {
        font-size: 0.875rem; }
      .cart-bag-container .cart-bag-dropdown-menu .cart-item .item-details .item-price {
        font-size: 0.75rem; }
  .cart-bag-container .cart-bag-dropdown-menu .seperator {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    height: 1px;
    background-color: #c9c9c9; }
  .cart-bag-container .cart-bag-dropdown-menu .cart-subtotal-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px 0; }
    .cart-bag-container .cart-bag-dropdown-menu .cart-subtotal-container .subtotal-number {
      text-align: right; }
  .cart-bag-container .cart-bag-dropdown-menu .button-container .btn:last-child {
    margin-left: auto; }

.cart-bag-container .user-links-dropdown {
  margin-top: 0.5rem;
  padding: 20px 15px;
  max-width: 400px;
  font-size: 12px; }
  .cart-bag-container .user-links-dropdown ul li {
    padding: 10px 0;
    position: relative; }
    .cart-bag-container .user-links-dropdown ul li i {
      width: 20px;
      color: #4e4e4e;
      transition: 0.3s; }
    .cart-bag-container .user-links-dropdown ul li:hover i:not(.user-indicator) {
      -webkit-transform: scale(1.3);
              transform: scale(1.3);
      color: #fe4f00; }

@media (max-width: 550px) {
  .cart-bag-container .cart-bag-dropdown-menu .cart-item {
    max-width: 270px; } }

@media (max-height: 500px) {
  .cart-bag-container .cart-bag-dropdown-menu .item-list {
    max-height: 200px; } }

.template1 {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative; }
  .template1:before {
    content: '';
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); }
  .template1 > .container {
    color: #fff;
    padding-top: 120px;
    padding-bottom: 150px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    position: relative;
    grid-gap: 20px; }
    .template1 > .container .title {
      font-family: "poppins-bold";
      font-size: 2.5rem;
      line-height: 2rem;
      grid-column: 1/3; }
    .template1 > .container .description {
      font-size: 0.875rem;
      max-width: 440px; }
    .template1 > .container .link-container {
      -webkit-align-self: center;
              align-self: center; }
      .template1 > .container .link-container .btn {
        font-size: 0.75rem;
        width: 162px; }
  @media (max-width: 768px) {
    .template1 > .container {
      grid-template-columns: 1fr; }
      .template1 > .container .title {
        grid-column: unset; } }

.template2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  overflow: hidden; }
  .template2 .background-wrapper {
    position: relative;
    overflow: hidden; }
    .template2 .background-wrapper .background {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      transition: 0.6s; }
    .template2 .background-wrapper:hover .background {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
  .template2 a {
    padding: 70px 60px;
    color: #fff;
    position: relative;
    z-index: 1;
    font-family: "quicksand-bold";
    font-size: 1.125rem;
    line-height: 1.125rem; }
    .template2 a .margin-right {
      margin-right: 20px; }

.product-review-container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 15px 60px; }
  .product-review-container .btn {
    max-width: 200px;
    margin: auto; }
  .product-review-container .user-image-container .user-image {
    width: 75px;
    height: 75px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 100%; }
  .product-review-container .product-review-content .product-review-form .rating-buttons {
    padding-bottom: 20px; }
  .product-review-container .product-review-content .product-review-form .form-group {
    margin-bottom: 30px; }
  .product-review-container h1 {
    font-size: 1.5rem;
    padding-top: 20px;
    margin: 0;
    font-family: "quicksand-regular";
    grid-column: 1/3;
    color: #164e36; }
  .product-review-container .product-review-list {
    grid-column: 1/3; }
    .product-review-container .product-review-list li {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 15px 60px;
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: solid 1px #c9c9c9; }
      .product-review-container .product-review-list li:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0; }
      .product-review-container .product-review-list li .review-header {
        display: grid;
        grid-template-columns: 1fr 1fr; }
        .product-review-container .product-review-list li .review-header .name {
          font-family: "quicksand-light-italic"; }
        .product-review-container .product-review-list li .review-header .date {
          font-size: 0.625rem;
          color: #c9c9c9;
          text-align: right; }
      .product-review-container .product-review-list li .review-description {
        font-size: 0.8125rem;
        padding-top: 15px; }
  @media (max-width: 600px) {
    .product-review-container {
      grid-template-columns: 1fr; }
      .product-review-container h1, .product-review-container .product-review-list {
        grid-column: unset; }
      .product-review-container .product-review-list li {
        grid-template-columns: 1fr; } }

.article-card .img-container {
  background: #ccc; }
  .article-card .img-container img {
    width: 100%;
    object-fit: cover;
    object-position: center; }

.home {
  background-color: #fcf7ea;
  position: relative; }
  .home .position-relative {
    position: relative;
    z-index: 10; }
  .home .floating-leaf {
    position: absolute;
    pointer-events: none; }
    .home .floating-leaf.left {
      left: 0; }
      .home .floating-leaf.left-top {
        top: 0;
        -webkit-transform: translateX(5px);
                transform: translateX(5px); }
      .home .floating-leaf.left-vine {
        top: 0;
        -webkit-transform: translate(-20%, -3%);
                transform: translate(-20%, -3%); }
      .home .floating-leaf.left-wax {
        -webkit-transform: translate(-68%, 138%);
                transform: translate(-68%, 138%); }
      .home .floating-leaf.left-leaf {
        -webkit-transform: translate(-21%, 4%);
                transform: translate(-21%, 4%); }
      .home .floating-leaf.left-slider-leaf {
        bottom: 50%;
        -webkit-transform: translate(-67%, 33%);
                transform: translate(-67%, 33%); }
      .home .floating-leaf.left-slider-lower {
        top: 100%;
        -webkit-transform: translate(10%, -55%);
                transform: translate(10%, -55%); }
      .home .floating-leaf.left-wave {
        -webkit-transform: translate(-40%, 0);
                transform: translate(-40%, 0);
        mix-blend-mode: screen; }
      .home .floating-leaf.left-wave2 {
        -webkit-transform: translate(-50%, -13%);
                transform: translate(-50%, -13%); }
    .home .floating-leaf.right {
      right: 0; }
      .home .floating-leaf.right-top {
        top: 0;
        -webkit-transform: translateX(-5px);
                transform: translateX(-5px); }
      .home .floating-leaf.right-vine {
        top: 0;
        -webkit-transform: translate(20%, -3%);
                transform: translate(20%, -3%); }
      .home .floating-leaf.right-wax {
        -webkit-transform: translate(64%, 160%);
                transform: translate(64%, 160%); }
      .home .floating-leaf.right-leaf {
        -webkit-transform: translate(20.2%, 4%);
                transform: translate(20.2%, 4%); }
      .home .floating-leaf.right-slider-leaf {
        bottom: 50%;
        -webkit-transform: translate(67%, 33%);
                transform: translate(67%, 33%); }
      .home .floating-leaf.right-slider-lower {
        top: 100%;
        -webkit-transform: translate(-10%, -55%);
                transform: translate(-10%, -55%); }
      .home .floating-leaf.right-wave {
        -webkit-transform: translate(40%, 0);
                transform: translate(40%, 0);
        mix-blend-mode: screen; }
      .home .floating-leaf.right-wave2 {
        -webkit-transform: translate(53%, -13%);
                transform: translate(53%, -13%); }
    .home .floating-leaf.faded {
      opacity: 0.3; }
      .home .floating-leaf.faded-light {
        opacity: 0.7; }
      .home .floating-leaf.faded-9 {
        opacity: 0.9; }
  .home .landscape {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -32%);
            transform: translate(-50%, -32%); }
  .home .steps {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    margin-top: 30px;
    padding: 0 50px; }
    .home .steps > div {
      padding: 10px; }
    .home .steps > div:nth-child(2) {
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc; }
  .home .featured-collection .banner-js .txt-oversized {
    font-size: 70px !important;
    line-height: 86px; }
  .home .featured-collection .banner-js .txt-large {
    line-height: 30px; }
  .home .whats-inside .container .image {
    width: 150px; }
  .home .whats-inside .container > div > :nth-child(even) {
    -webkit-transform: translateY(50%);
            transform: translateY(50%); }
  .home .whats-inside .container > div p {
    line-height: 21px; }
  .home .our-mission {
    position: relative; }
    .home .our-mission .container .description {
      max-width: 650px;
      margin: auto;
      line-height: 30px; }
      .home .our-mission .container .description.items {
        max-width: none;
        margin-top: 20px; }
        .home .our-mission .container .description.items .item {
          min-height: 450px;
          border-radius: 20px; }
          .home .our-mission .container .description.items .item > div > p {
            line-height: 24px !important; }
          .home .our-mission .container .description.items .item:after {
            content: "";
            background: transparent linear-gradient(180deg, #00000000 0%, rgba(0, 0, 0, 0.5) 100%) 0% 0% no-repeat padding-box;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            border-radius: 20px; }
          .home .our-mission .container .description.items .item > * {
            max-width: 400px;
            margin-left: 20px;
            margin-right: 20px;
            line-height: 1.5rem;
            position: relative;
            z-index: 1; }
        .home .our-mission .container .description.items .slick-dots {
          bottom: 15px; }
          .home .our-mission .container .description.items .slick-dots li button {
            background-color: #164e36;
            border-color: #164e36; }
          .home .our-mission .container .description.items .slick-dots li.slick-active button, .home .our-mission .container .description.items .slick-dots li:hover button {
            background-color: #58a300;
            border-color: #58a300;
            -webkit-transform: scale(1);
                    transform: scale(1);
            opacity: 0.9; }
    .home .our-mission .container hr {
      margin-top: 1rem;
      margin-bottom: 0;
      border: 0;
      height: 50px;
      border-left: 2px dashed #58a300;
      width: 0; }
  .home .txt-shadow {
    text-shadow: 0px 3px 6px #00000029; }
  .home .txt-14-px {
    font-size: 14px !important; }
  .home .section-5 > div {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto; }
    .home .section-5 > div > div {
      background-color: #164e36;
      padding: 50px;
      border-radius: 150px;
      display: grid;
      width: 100%;
      min-height: 270px;
      grid-template-columns: 40% 1fr;
      grid-gap: 50px; }
      .home .section-5 > div > div .image-container img {
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 100%;
        max-height: 350px; }
      .home .section-5 > div > div .content-container h2 {
        line-height: 48px;
        color: #fff; }
      .home .section-5 > div > div .content-container > div {
        background-color: #fcf7ea;
        border-radius: 25px;
        max-height: 40px;
        padding: 5px 20px;
        max-width: 100%; }
        .home .section-5 > div > div .content-container > div a img {
          max-height: 100%; }
  .home .our-journal .items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    grid-template-areas: "main side1" "main side2"; }
    .home .our-journal .items .item .txt-large {
      line-height: 24px; }
    .home .our-journal .items .item .txt-medium {
      font-size: 14px !important;
      line-height: 21px; }
    .home .our-journal .items .item:first-child {
      grid-area: main; }
      .home .our-journal .items .item:first-child > img {
        height: 240px; }
      .home .our-journal .items .item:first-child > div {
        margin-top: 10px; }
    .home .our-journal .items .item:nth-child(2) {
      grid-area: side1; }
    .home .our-journal .items .item:nth-child(2) {
      grid-area: side2; }
    .home .our-journal .items .item:not(:first-child) {
      display: grid;
      grid-template-columns: 200px 1fr;
      grid-gap: 10px; }
      .home .our-journal .items .item:not(:first-child) > img {
        height: 150px; }
    .home .our-journal .items .item > img {
      width: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 8px; }
  .home .section-7 {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
    .home .section-7::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: transparent linear-gradient(180deg, #00000000 0%, rgba(0, 0, 0, 0.8) 100%) 0% 0% no-repeat padding-box; }
    .home .section-7 .container {
      color: #fff;
      min-height: 430px;
      position: relative;
      z-index: 1; }
      .home .section-7 .container div {
        line-height: 72px; }
        .home .section-7 .container div p {
          margin-bottom: 0; }
      @media (max-width: 360px) {
        .home .section-7 .container div {
          font-size: 45px !important; } }
  @media (max-width: 1999px) {
    .home .section-5 > div > div {
      grid-gap: 35px; }
    .home .section-5 > div > div .content-container .available {
      max-height: 100%;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; } }
  @media (max-width: 991px) {
    .home .page-container {
      margin-top: 10px;
      padding-top: 0; }
    .home .featured-collection .banner-js .main-banner {
      height: auto; }
    .home .featured-collection .banner-js .banner-footer {
      grid-template-columns: 1fr; }
      .home .featured-collection .banner-js .banner-footer .next-preview {
        -webkit-justify-content: center;
                justify-content: center; }
    .home .featured-collection .banner-js .link-to-collection {
      text-align: center !important;
      margin-bottom: 20px; }
    .home .grid-2 {
      grid-template-columns: 1fr; }
    .home .whats-inside .container > div > :nth-child(even) {
      -webkit-transform: translateY(0);
              transform: translateY(0); }
    .home .grid-gap-50-20 {
      grid-gap: 20px; }
    .home .section-5 > div > div .image-container img {
      max-height: 300px; }
    .home .our-journal .items .item:not(:first-child) {
      grid-template-columns: 160px 1fr; } }
  @media (max-width: 767px) {
    .home .steps {
      grid-template-columns: 1fr; }
      .home .steps > div {
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 10px; }
    .home .our-journal .items {
      grid-template-areas: "main main" "side1 side2"; } }
  @media (max-width: 700px) {
    .home .our-journal .items {
      grid-template-areas: "main main" "side1 side1" "side2 side2"; }
      .home .our-journal .items .item:first-child {
        grid-area: main; }
        .home .our-journal .items .item:first-child > img {
          height: 240px; }
        .home .our-journal .items .item:first-child > div {
          margin-top: 10px; }
      .home .our-journal .items .item:nth-child(2) {
        grid-area: side1; }
      .home .our-journal .items .item:nth-child(2) {
        grid-area: side1; }
      .home .our-journal .items .item:nth-child(3) {
        grid-area: side2; }
      .home .our-journal .items .item:nth-child(3) {
        grid-area: side2; }
    .home .section-5 > div > div {
      grid-template-columns: 1fr; }
    .home .section-5 .image-container {
      height: 100px; }
      .home .section-5 .image-container img {
        -webkit-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
        left: 50%;
        max-height: 230px; }
    .home .section-5 .content-container {
      text-align: center;
      -webkit-align-items: center;
              align-items: center; }
      .home .section-5 .content-container .flex-wrap {
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap; }
      .home .section-5 .content-container .justify-content-center {
        -webkit-justify-content: center !important;
                justify-content: center !important; } }
  @media (max-width: 550px) {
    .home .featured-products {
      grid-template-columns: 1fr 1fr; }
      .home .featured-products .featured-product-card {
        height: 50vw; }
        .home .featured-products .featured-product-card .flex-end .btn {
          font-size: 10px; }
    .home .section-5 > div > div .image-container img {
      max-height: 230px; }
    .home .our-journal .items .item:not(:first-child) {
      grid-template-columns: 40% 1fr; } }
  @media (max-width: 530px) {
    .home .floating-leaf.faded-light {
      opacity: 0.5; }
    .home .floating-leaf.left-leaf {
      -webkit-transform: translate(-21%, 19%);
              transform: translate(-21%, 19%); }
    .home .floating-leaf.right-leaf {
      -webkit-transform: translate(20.2%, 19%);
              transform: translate(20.2%, 19%); }
    .home .floating-leaf.right-wax {
      -webkit-transform: translate(64%, 218%);
              transform: translate(64%, 218%); }
    .home .floating-leaf.left-wax {
      -webkit-transform: translate(-68%, 191%);
              transform: translate(-68%, 191%); }
    .home .floating-leaf.left-vine {
      -webkit-transform: translate(-20%, 21%);
              transform: translate(-20%, 21%); }
    .home .floating-leaf.right-vine {
      -webkit-transform: translate(20%, 21%);
              transform: translate(20%, 21%); } }
  @media (max-width: 500px) {
    .home .grid-2-auto-1fr {
      grid-template-columns: 1fr;
      justify-items: center; }
    .home .whats-inside .container > div .grid img {
      max-width: 150px; }
    .home .whats-inside .container > div .grid .txt-medium {
      text-align: center; } }
  @media (max-width: 400px) {
    .home .our-journal .items .item:not(:first-child) > img {
      height: 120px; } }
  @media (max-width: 340px) {
    .home .section-5 > div > div {
      padding: 40px; } }

.about-page .banner {
  min-height: 500px;
  color: #fff;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  .about-page .banner .txt-upsized {
    line-height: 72px; }
  .about-page .banner p {
    margin-bottom: 0; }

.about-page .txt-shadow {
  text-shadow: 0px 3px 6px #00000029; }

.about-page .txt-14-px {
  font-size: 14px !important; }

.about-page .end-section::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.1;
  background: #164E36 0% 0% no-repeat padding-box; }

@media (max-width: 415px) {
  .about-page .end-section .container .txt-upsized {
    font-size: 45px !important; } }

.about-page .about-us .content {
  max-width: 750px; }
  .about-page .about-us .content h3 {
    font-size: 24px !important;
    line-height: 32px; }
  .about-page .about-us .content .description {
    line-height: 30px; }

.about-page .testimonials, .about-page .white-bottom {
  background: white !important; }
  .about-page .testimonials .landscape, .about-page .white-bottom .landscape {
    -webkit-transform: scaleY(-1);
            transform: scaleY(-1); }

.about-page .testimonials-section {
  background: white; }
  .about-page .testimonials-section .latest-news {
    position: relative; }
  .about-page .testimonials-section .testimonials-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 30px; }
    .about-page .testimonials-section .testimonials-grid .item {
      position: relative;
      padding: 110px 0 0;
      border-radius: 40px;
      transition: all 0.3s ease;
      margin-top: 100px; }
      .about-page .testimonials-section .testimonials-grid .item .txt-small {
        font-size: 14px !important;
        line-height: 21px; }
      .about-page .testimonials-section .testimonials-grid .item h5 {
        font-size: 17px !important; }
      .about-page .testimonials-section .testimonials-grid .item .txt-10-px {
        font-size: 10px !important; }
      .about-page .testimonials-section .testimonials-grid .item .txt-18-px {
        font-size: 18px !important; }
      .about-page .testimonials-section .testimonials-grid .item img {
        max-width: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        max-height: 200px;
        max-width: 100%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
  .about-page .testimonials-section .btn-load {
    display: -webkit-flex;
    display: flex;
    margin: auto;
    font-size: 15px;
    border: none;
    border-radius: 25px;
    padding: 10px 45px;
    background: #58a300;
    max-width: 200px; }

@media (max-width: 991px) {
  .about-page .testimonials-section .testimonials-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr)); } }

@media (max-width: 575px) {
  .about-page .testimonials-section .testimonials-grid {
    grid-template-columns: 1fr; } }

.login-container {
  background-color: #fcf7ea;
  position: relative; }
  .login-container .login {
    max-width: 430px;
    width: 100%;
    margin: auto; }
    .login-container .login .icon-badge {
      text-align: center; }
      .login-container .login .icon-badge i {
        width: 80px;
        height: 80px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        border: 5px solid #fe4f00;
        background: #fe4f00;
        color: #fff;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        border-radius: 100%;
        font-size: 40px;
        margin: auto;
        box-shadow: 0 5px 10px -3px rgba(0, 0, 0, 0.3); }
    .login-container .login .login-header {
      font-weight: bold; }
    .login-container .login .login-form {
      padding: 20px; }
      .login-container .login .login-form .form-group {
        margin-bottom: 10px; }
    .login-container .login input[type="password"] {
      letter-spacing: 3px; }
    .login-container .login input[type="password"]::-webkit-input-placeholder {
      letter-spacing: normal; }
    .login-container .login input[type="password"]::-ms-input-placeholder {
      letter-spacing: normal; }
    .login-container .login input[type="password"]::placeholder {
      letter-spacing: normal; }
    .login-container .login .submit-btn {
      margin-top: 20px; }
    .login-container .login .registration-link {
      margin-top: 20px; }
      .login-container .login .registration-link .arti-link:hover {
        color: #3c7000; }
    .login-container .login .separator {
      position: relative;
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: center; }
      .login-container .login .separator::before {
        content: "";
        border-bottom: 1px solid #cbcbcb;
        position: absolute;
        width: 100%;
        top: 55%;
        left: 0; }
      .login-container .login .separator span {
        position: relative;
        background-color: #fcf7ea;
        padding-left: 30px;
        padding-right: 30px;
        color: #58a300;
        font-size: 12px; }
    .login-container .login .fa-facebook-f,
    .login-container .login .fa-google {
      width: 17px;
      height: 17px;
      border-radius: 100px;
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      margin-right: 5px;
      padding: 10px; }
    .login-container .login .fa-facebook-f {
      background-color: #4267b2;
      color: #fff;
      -webkit-transform: scale(1.2);
              transform: scale(1.2); }
    .login-container .login .fa-google {
      -webkit-transform: scale(1.5);
              transform: scale(1.5); }
    @media (max-width: 767px) {
      .login-container .login .icon-badge i {
        width: 60px;
        height: 60px;
        font-size: 30px; } }
    @media (max-width: 450px) {
      .login-container .login .login-options-container {
        display: block; }
        .login-container .login .login-options-container .forgot-password {
          text-align: center !important;
          border: 1px solid #5e5e5e;
          padding: 5px;
          margin-top: 20px; } }

.product-view .txt-green {
  color: #1d600c; }

.product-view .line-height-21 {
  line-height: 21px; }

.product-view .line-height-30 {
  line-height: 30px; }

.product-view .modal-caboodle {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out; }
  .product-view .modal-caboodle .floating-leaf {
    position: absolute;
    pointer-events: none;
    z-index: 5; }
    .product-view .modal-caboodle .floating-leaf.left {
      left: 0; }
      .product-view .modal-caboodle .floating-leaf.left-upper {
        -webkit-transform: translate(-33%, -70%);
                transform: translate(-33%, -70%);
        width: 769px; }
      .product-view .modal-caboodle .floating-leaf.left.faded-3 {
        opacity: 0.3; }
    .product-view .modal-caboodle .floating-leaf.right {
      right: 0; }
      .product-view .modal-caboodle .floating-leaf.right-upper {
        -webkit-transform: translate(33%, -70%);
                transform: translate(33%, -70%);
        width: 769px; }
      .product-view .modal-caboodle .floating-leaf.right.faded-3 {
        opacity: 0.3; }
    .product-view .modal-caboodle .floating-leaf.faded {
      opacity: 0.3; }
      .product-view .modal-caboodle .floating-leaf.faded-light {
        opacity: 0.7; }
      .product-view .modal-caboodle .floating-leaf.faded-9 {
        opacity: 0.9; }
  .product-view .modal-caboodle.open {
    pointer-events: auto;
    opacity: 1; }
  .product-view .modal-caboodle .modal-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    height: 100%;
    padding: 40px; }
    .product-view .modal-caboodle .modal-container .modal-ingredient,
    .product-view .modal-caboodle .modal-container .modal-review {
      position: relative;
      background-color: #fcf7ea;
      text-align: center;
      width: 100%; }
      .product-view .modal-caboodle .modal-container .modal-ingredient .modal-exit,
      .product-view .modal-caboodle .modal-container .modal-review .modal-exit {
        position: absolute;
        right: 0;
        top: 0;
        -webkit-transform: translateX(150%);
                transform: translateX(150%);
        border: none;
        background-color: #fff;
        color: #4e4e4e;
        border-radius: 20px; }
      .product-view .modal-caboodle .modal-container .modal-ingredient .star-container button,
      .product-view .modal-caboodle .modal-container .modal-review .star-container button {
        border: none;
        background: none;
        outline: none;
        color: #58a300; }
      .product-view .modal-caboodle .modal-container .modal-ingredient .review-information,
      .product-view .modal-caboodle .modal-container .modal-review .review-information {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        z-index: 20;
        /* width */
        /* Track */
        /* Handle */
        /* Handle on hover */ }
        .product-view .modal-caboodle .modal-container .modal-ingredient .review-information::-webkit-scrollbar,
        .product-view .modal-caboodle .modal-container .modal-review .review-information::-webkit-scrollbar {
          width: 5px; }
        .product-view .modal-caboodle .modal-container .modal-ingredient .review-information::-webkit-scrollbar-track,
        .product-view .modal-caboodle .modal-container .modal-review .review-information::-webkit-scrollbar-track {
          background: #f1f1f1; }
        .product-view .modal-caboodle .modal-container .modal-ingredient .review-information::-webkit-scrollbar-thumb,
        .product-view .modal-caboodle .modal-container .modal-review .review-information::-webkit-scrollbar-thumb {
          background: #979797;
          opacity: 0.5; }
        .product-view .modal-caboodle .modal-container .modal-ingredient .review-information::-webkit-scrollbar-thumb:hover,
        .product-view .modal-caboodle .modal-container .modal-review .review-information::-webkit-scrollbar-thumb:hover {
          background: #7a7a7a;
          cursor: pointer; }
    .product-view .modal-caboodle .modal-container .modal-review {
      position: relative; }
      .product-view .modal-caboodle .modal-container .modal-review .images-container {
        width: 100%; }
        .product-view .modal-caboodle .modal-container .modal-review .images-container .image {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          overflow: hidden; }
    .product-view .modal-caboodle .modal-container .modal-ingredient {
      padding: 30px 20px;
      max-width: 450px;
      border-radius: 10px; }
      .product-view .modal-caboodle .modal-container .modal-ingredient .product-information {
        height: 100%;
        overflow-y: auto;
        /* width */
        /* Track */
        /* Handle */
        /* Handle on hover */ }
        .product-view .modal-caboodle .modal-container .modal-ingredient .product-information::-webkit-scrollbar {
          width: 5px; }
        .product-view .modal-caboodle .modal-container .modal-ingredient .product-information::-webkit-scrollbar-track {
          background: #f1f1f1; }
        .product-view .modal-caboodle .modal-container .modal-ingredient .product-information::-webkit-scrollbar-thumb {
          background: #979797;
          opacity: 0.5; }
        .product-view .modal-caboodle .modal-container .modal-ingredient .product-information::-webkit-scrollbar-thumb:hover {
          background: #7a7a7a;
          cursor: pointer; }
        .product-view .modal-caboodle .modal-container .modal-ingredient .product-information > img {
          width: inherit;
          margin-top: 20px;
          margin-bottom: 20px;
          max-width: 100%;
          width: 70%; }
        .product-view .modal-caboodle .modal-container .modal-ingredient .product-information .title {
          margin-bottom: 20px;
          color: #164e36; }
    .product-view .modal-caboodle .modal-container .modal-review {
      padding: 40px 50px;
      max-width: 650px; }
      @media (max-width: 420px) {
        .product-view .modal-caboodle .modal-container .modal-review {
          padding: 40px 30px; } }

.product-view .customer-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 30px;
  width: 30px;
  background-color: #ff8464;
  color: #fff;
  border-radius: 50px; }

.product-view .grid .image-container {
  background-color: #fcf7ea;
  height: 100%;
  position: relative;
  overflow: hidden; }
  .product-view .grid .image-container > div {
    height: calc(100vh - 78px);
    position: relative; }
    .product-view .grid .image-container > div .main-asset {
      width: 500px;
      height: 500px;
      margin: 0 auto;
      max-height: 70vh;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
      .product-view .grid .image-container > div .main-asset img {
        max-width: 100%;
        max-height: 100%;
        object-position: center;
        object-fit: contain; }
    .product-view .grid .image-container > div .nav-assets {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      grid-gap: 20px;
      gap: 20px;
      margin: 0 auto;
      width: 550px; }
      .product-view .grid .image-container > div .nav-assets button {
        background: none;
        background-color: rgba(0, 0, 0, 0.3);
        border: none;
        opacity: 0.2;
        transition: opacity 0.15s ease-in-out;
        color: #fff; }
        .product-view .grid .image-container > div .nav-assets button:hover {
          opacity: 0.3; }
      .product-view .grid .image-container > div .nav-assets > div {
        height: 100px;
        max-width: 120px;
        width: 100%;
        border: 1px dashed #cbcbcb;
        border-radius: 10px;
        padding: 15px; }
        .product-view .grid .image-container > div .nav-assets > div img {
          height: 100%;
          width: 100%;
          object-position: center;
          object-fit: contain; }

.product-view .grid .content-container > div {
  min-height: calc(50vh - 8px);
  padding-left: 30px; }
  .product-view .grid .content-container > div.details-container .details {
    padding-top: 50px;
    padding-bottom: 50px;
    height: 100%;
    max-width: 550px; }
    .product-view .grid .content-container > div.details-container .details .description {
      line-height: 30px;
      color: #6e6e6e; }
    .product-view .grid .content-container > div.details-container .details .product-specification {
      font-size: 15px; }
      .product-view .grid .content-container > div.details-container .details .product-specification i,
      .product-view .grid .content-container > div.details-container .details .product-specification em {
        margin-right: 5px;
        border-radius: 50px;
        background-color: #fcf7ea;
        border: 1px solid #c9c9c9;
        color: #164e36;
        height: 25px;
        width: 25px;
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        font-size: 12px; }
    .product-view .grid .content-container > div.details-container .details .variants-container button {
      border: 2px solid #164e36;
      background: none;
      padding: 5px;
      color: #164e36;
      transition: all 0.15s ease;
      min-width: 80px; }
      .product-view .grid .content-container > div.details-container .details .variants-container button.selected {
        background: #164e36;
        color: #fff; }
      .product-view .grid .content-container > div.details-container .details .variants-container button:not(:last-child) {
        margin-right: 10px; }
    .product-view .grid .content-container > div.details-container .details .price-container .price {
      font-size: 32px !important;
      min-width: 100px; }
    .product-view .grid .content-container > div.details-container .details .price-container .quantity-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      width: 130px;
      height: 45px;
      border: 1px solid #5e5e5e;
      border-radius: 30px;
      color: #000000; }
      .product-view .grid .content-container > div.details-container .details .price-container .quantity-container > * {
        border: none;
        outline: none;
        height: 100%;
        width: 100%;
        text-align: center;
        background: none; }
    .product-view .grid .content-container > div.details-container .details .price-container .checkout-btn {
      min-width: 220px; }
  .product-view .grid .content-container > div.reviews-ingredients-container {
    background: transparent linear-gradient(180deg, #efefef 0%, #f4f4f4 100%) 0% 0% no-repeat padding-box;
    position: relative;
    padding-bottom: 50px; }
    .product-view .grid .content-container > div.reviews-ingredients-container .r-i-navigation {
      position: absolute;
      top: 0;
      left: 30px;
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      max-width: 550px;
      width: 100%;
      border-bottom: 1px solid #5e5e5e; }
      .product-view .grid .content-container > div.reviews-ingredients-container .r-i-navigation button {
        background: none;
        border: none;
        padding-bottom: 10px; }
        .product-view .grid .content-container > div.reviews-ingredients-container .r-i-navigation button.active {
          color: #58a300;
          border-bottom: 1px solid #58a300; }
    .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients {
      max-width: 550px;
      position: relative;
      padding-top: 20px; }
      .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .ingredients-container,
      .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .reviews-container {
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.15s ease-in-out;
        width: 100%;
        padding-right: 5px;
        display: none;
        /* width */
        /* Track */
        /* Handle */
        /* Handle on hover */ }
        .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .ingredients-container::-webkit-scrollbar,
        .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .reviews-container::-webkit-scrollbar {
          width: 5px; }
        .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .ingredients-container::-webkit-scrollbar-track,
        .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .reviews-container::-webkit-scrollbar-track {
          background: #f1f1f1; }
        .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .ingredients-container::-webkit-scrollbar-thumb,
        .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .reviews-container::-webkit-scrollbar-thumb {
          background: #979797;
          opacity: 0.5; }
        .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .ingredients-container::-webkit-scrollbar-thumb:hover,
        .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .reviews-container::-webkit-scrollbar-thumb:hover {
          background: #7a7a7a;
          cursor: pointer; }
        .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .ingredients-container.active,
        .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .reviews-container.active {
          opacity: 1;
          pointer-events: auto;
          height: auto;
          min-height: calc(50vh - 95px);
          display: block; }
      .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .reviews-container .write-review {
        background-color: #fff;
        border-radius: 15px;
        padding: 15px; }
        .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .reviews-container .write-review .content {
          max-width: 250px; }
        .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .reviews-container .write-review .btn-clear {
          border-radius: 10px;
          border-width: 2px; }
      .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .reviews-container .review-item {
        border-bottom: 1px solid #cbcbcb; }
      .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .ingredients-container table {
        width: 100%; }
        .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .ingredients-container table tr {
          border-bottom: 1px solid #5e5e5e; }
          .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .ingredients-container table tr td {
            padding: 8px 20px 8px 0; }
            .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .ingredients-container table tr td:last-child {
              padding-right: 0; }
            .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .ingredients-container table tr td .fa-circle {
              font-size: 8px;
              color: #58a300;
              -webkit-transform: translateY(-20%);
                      transform: translateY(-20%); }
            .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .ingredients-container table tr td h4 {
              margin: 0; }
            .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .ingredients-container table tr td .ingredient-description {
              max-width: 300px;
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #4e4e4e; }
              .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .ingredients-container table tr td .ingredient-description p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 0; }
            .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients .ingredients-container table tr td button {
              border: none;
              background: none;
              color: #58a300;
              height: 100%;
              width: 30px; }

@media (max-width: 1100px) {
  .product-view .grid .image-container > div .main-asset {
    width: 350px; }
  .product-view .grid .image-container > div .nav-assets {
    grid-gap: 10px;
    gap: 10px;
    width: 400px;
    max-width: 100%;
    padding: 10; }
    .product-view .grid .image-container > div .nav-assets > div {
      height: 80px;
      max-width: 80px; } }

@media (max-width: 820px) {
  .product-view .grid {
    grid-template-columns: 1fr; }
    .product-view .grid .image-container {
      height: auto; }
      .product-view .grid .image-container > div {
        height: 380px; }
        .product-view .grid .image-container > div .main-asset {
          height: 250px; }
    .product-view .grid .content-container > div {
      padding-right: 30px; }
      .product-view .grid .content-container > div .details {
        margin: 0 auto; }
      .product-view .grid .content-container > div.reviews-ingredients-container .reviews-ingredients,
      .product-view .grid .content-container > div.reviews-ingredients-container .r-i-navigation {
        max-width: 100%; }
      .product-view .grid .content-container > div.reviews-ingredients-container .r-i-navigation {
        width: auto;
        right: 35px; } }

.products-page .category-banner-header {
  height: 500px;
  background-size: cover;
  background-position: center center;
  overflow: hidden; }
  .products-page .category-banner-header .text-details {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5); }
    .products-page .category-banner-header .text-details > div .header-icon {
      height: 95px;
      width: 95px;
      object-fit: contain;
      object-position: center; }
    .products-page .category-banner-header .text-details > div .category-tags {
      border-radius: 100px;
      background: rgba(255, 255, 255, 0.2); }
      .products-page .category-banner-header .text-details > div .category-tags p {
        margin: 0; }
  .products-page .category-banner-header .transition-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    background-color: #fff;
    -webkit-animation-name: slideLeft;
            animation-name: slideLeft;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    overflow: hidden; }
    .products-page .category-banner-header .transition-overlay > div {
      background-size: cover;
      background-position: center center;
      position: relative; }

.products-page .main-products-content {
  margin-left: 50px; }
  .products-page .main-products-content .gray-control {
    border-radius: 50px;
    position: relative; }
  .products-page .main-products-content .icon-overlay {
    position: absolute;
    border-radius: 50px;
    height: 50%;
    background: #fff;
    pointer-events: none;
    right: 5px;
    top: 25%;
    padding: 0 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }

.products-page .category-section {
  max-width: 400px;
  width: 100%; }

.products-page .product-filter-category-list {
  position: relative;
  margin-bottom: 30px;
  width: 100%; }
  .products-page .product-filter-category-list .mobile-category-toggle {
    position: fixed;
    bottom: 20px;
    right: 0px;
    z-index: 100;
    display: none;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: 0.3s; }
  .products-page .product-filter-category-list .category-list {
    padding: 0 50px; }
  .products-page .product-filter-category-list .category-container {
    padding: 15px 20px;
    opacity: .6;
    transition: 0.6s;
    cursor: pointer; }
    .products-page .product-filter-category-list .category-container .category-title {
      text-overflow: ellipsis;
      overflow: hidden; }
    .products-page .product-filter-category-list .category-container .sub-category-options {
      padding: 0 20px;
      transition: 0.3s;
      max-height: 0px;
      overflow: hidden; }
      .products-page .product-filter-category-list .category-container .sub-category-options .sub-option {
        padding-top: 10px; }
        .products-page .product-filter-category-list .category-container .sub-category-options .sub-option span:hover {
          font-weight: bold; }

@media (max-width: 991px) {
  .products-page .category-banner-header {
    height: 400px; }
    .products-page .category-banner-header .text-details > div .header-icon {
      height: 50px;
      width: 50px;
      object-fit: contain;
      object-position: center; }
    .products-page .category-banner-header .text-details .txt-giantsize {
      font-size: 50px; }
  .products-page .category-section {
    max-width: 100%; }
  .products-page .page-container {
    padding-top: 0px; }
  .products-page .main-products-content {
    margin-left: 0; }
    .products-page .main-products-content .category-header {
      height: 300px;
      background-size: cover;
      background-position: center center; }
  .products-page .product-filter-category-list {
    max-width: 100%; }
    .products-page .product-filter-category-list .mobile-category-toggle {
      display: block;
      -webkit-transform: translateX(-20px);
              transform: translateX(-20px); }
    .products-page .product-filter-category-list .category-nav-list {
      position: fixed;
      width: 300px;
      height: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 99999;
      background: #eee;
      padding: 30px;
      overflow: auto;
      transition: 0.3s;
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%); }
      .products-page .product-filter-category-list .category-nav-list.in {
        -webkit-transform: translateX(0%);
                transform: translateX(0%); } }

@media (max-width: 767px) {
  .products-page .category-banner-header {
    height: 300px;
    text-align: center; }
    .products-page .category-banner-header .text-details > div .header-icon {
      height: 30px;
      width: 30px;
      object-fit: contain;
      object-position: center;
      margin: auto; }
    .products-page .category-banner-header .text-details .txt-giantsize {
      font-size: 30px; }
  .products-page .main-products-content .category-header {
    height: 200px; } }

.ingredients-selection {
  padding: 100px 0 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .ingredients-selection .slider-container {
    width: 100%;
    overflow: hidden;
    padding: 20px 0; }
    .ingredients-selection .slider-container .slick-list {
      overflow: visible; }
    .ingredients-selection .slider-container .ingredient-option {
      width: 120px;
      margin: auto;
      border-radius: 100%;
      overflow: hidden;
      transition: 0.3s;
      cursor: pointer;
      -webkit-animation-name: growIn;
              animation-name: growIn;
      -webkit-animation-duration: 0.3s;
              animation-duration: 0.3s;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
      -webkit-transform: scale(0);
              transform: scale(0); }
      .ingredients-selection .slider-container .ingredient-option img {
        width: 100%;
        height: 120px;
        object-fit: cover;
        object-position: center; }
      .ingredients-selection .slider-container .ingredient-option:hover, .ingredients-selection .slider-container .ingredient-option.active {
        box-shadow: 0 0 0 10px #164e36; }
  .ingredients-selection .slider-control {
    position: relative;
    z-index: 2;
    opacity: 0.3;
    font-size: 50px;
    margin: 0 50px;
    text-align: center;
    transition: 0.3s;
    cursor: pointer; }
    .ingredients-selection .slider-control:hover {
      opacity: 0.7; }

.ingredient-details {
  margin-top: 50px; }
  .ingredient-details .text-details .ingredient-name {
    color: #fff;
    padding: 50px;
    position: relative;
    max-width: 500px;
    width: 100%; }
    .ingredient-details .text-details .ingredient-name h1 {
      font-size: 48px; }
    .ingredient-details .text-details .ingredient-name .overlay-display {
      background-color: #164e36;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 130%;
      padding: 50px; }
      .ingredient-details .text-details .ingredient-name .overlay-display .shop-link {
        font-size: 12px; }
  .ingredient-details .text-details .ingredient-description {
    padding: 50px;
    width: 100%; }
  .ingredient-details .ingredient-bg {
    height: 300px;
    background-color: #ccc;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; }

.stores #map {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }

.stores .store-control {
  color: #a3a4a4;
  background: #eee;
  border: none;
  box-shadow: none;
  font-size: 20px;
  padding: 20px;
  font-weight: bold;
  transition: 0.3s; }
  .stores .store-control.active {
    color: #fff;
    background: #164e36; }
  .stores .store-control:not(.active):hover {
    opacity: 0.6; }

.stores .stores-list {
  margin-top: 30px;
  grid-gap: 20px; }
  .stores .stores-list .store-cell {
    padding: 10px 5px;
    font-size: 15px; }
    .stores .stores-list .store-cell .contact-details {
      opacity: 0.4;
      margin-top: 5px; }

.blogs {
  padding-bottom: 50px; }
  .blogs .page-banner-image .banner-text {
    max-width: 800px;
    width: 100%; }
    .blogs .page-banner-image .banner-text .background-overlay {
      background: rgba(0, 0, 0, 0.5);
      padding: 20px; }
  .blogs .blog-list {
    grid-gap: 30px; }
  .blogs .share-list i {
    width: 35px;
    height: 35px;
    background: #c9c9c9;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 100%;
    transition: 0.3s; }
    .blogs .share-list i:hover {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      background: #000000;
      color: #fff; }
  .blogs .blog-content {
    min-width: 800px; }
    .blogs .blog-content .author-details .author-image img {
      width: 150px;
      height: 150px;
      border-radius: 100%;
      object-fit: cover;
      object-position: center; }
  .blogs .related-products {
    max-width: 300px;
    width: 100%; }
  .blogs .related-articles {
    border-top: 2px solid #c9c9c9; }
  @media (max-width: 1200px) {
    .blogs .blog-content {
      min-width: 700px; } }
  @media (max-width: 991px) {
    .blogs .banner-text {
      padding: 50px; }
    .blogs .blog-view-outer-container {
      display: block; }
      .blogs .blog-view-outer-container .blog-content {
        min-width: 0;
        min-width: initial;
        margin: auto; }
    .blogs .related-products {
      max-width: 100%; }
      .blogs .related-products .related-products-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px; } }
  @media (max-width: 767px) {
    .blogs .banner-text {
      padding: 20px; }
    .blogs .grid-3 {
      grid-template-columns: repeat(2, minmax(0, 1fr)); }
    .blogs .blog-content .author-details {
      text-align: center;
      display: block; }
      .blogs .blog-content .author-details .margin-left-20 {
        margin-left: 0px;
        margin-top: 10px; } }
  @media (max-width: 550px) {
    .blogs .grid-3 {
      grid-template-columns: 1fr; }
      .blogs .grid-3 .article-card {
        margin-top: 30px; }
        .blogs .grid-3 .article-card .btn {
          display: block;
          margin-left: auto;
          margin-right: auto; }
    .blogs .related-products .related-products-container {
      grid-template-columns: 1fr 1fr; } }
  @media (max-width: 360px) {
    .blogs .banner-text .btn {
      display: block;
      margin-left: auto;
      margin-right: auto; }
    .blogs .related-products .related-products-container {
      grid-template-columns: 1fr; } }

.contact-us {
  background-color: #fcf7ea;
  position: relative;
  overflow: hidden; }
  .contact-us-container {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-left: 0;
    padding-right: 0;
    margin-top: 50px; }
  .contact-us .floating-leaf {
    position: absolute;
    pointer-events: none; }
    .contact-us .floating-leaf.left {
      left: 0; }
      .contact-us .floating-leaf.left-bottom {
        -webkit-transform: translate(-21%, 9%);
                transform: translate(-21%, 9%); }
      .contact-us .floating-leaf.left.faded-75 {
        opacity: 0.75; }
    .contact-us .floating-leaf.right {
      right: 0; }
      .contact-us .floating-leaf.right-bottom {
        -webkit-transform: translate(21%, 9%);
                transform: translate(21%, 9%); }
      .contact-us .floating-leaf.right.faded-75 {
        opacity: 0.75; }
  .contact-us .with-top-margin {
    margin-top: 20px; }
  .contact-us .contact-information {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    min-height: 330px; }
    .contact-us .contact-information .info {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 30px 1fr; }
      .contact-us .contact-information .info .contact-info {
        display: contents; }
      .contact-us .contact-information .info img {
        width: 28px; }
  .contact-us .grid {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: 55% 1fr;
    grid-gap: 20px; }
  .contact-us .contact-info i {
    width: 20px;
    margin-right: 5px; }
  .contact-us .contact-form {
    max-width: 100%;
    width: 100%;
    border-right: 1px dashed #6f6f6f; }
    .contact-us .contact-form .text-right {
      text-align: right !important; }
    .contact-us .contact-form .submit-btn {
      font-weight: 500;
      border: none;
      border-radius: 25px;
      padding: 10px 45px;
      background: #58a300;
      color: #f8f8f8;
      max-width: 300px; }
    .contact-us .contact-form .error {
      position: absolute;
      padding-left: 12px; }
  @media (max-width: 991px) {
    .contact-us-container {
      display: block;
      padding: 20px 50px; }
      .contact-us-container > div {
        padding: 0; }
        .contact-us-container > div.form-header {
          text-align: center; }
          .contact-us-container > div.form-header .contact-info {
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: center;
                    justify-content: center;
            margin-top: 20px; }
        .contact-us-container > div.contact-form {
          margin: 50px auto 0; } }
  @media (max-width: 767px) {
    .contact-us-container {
      display: block;
      padding: 20px; }
      .contact-us-container > div {
        padding: 0; }
        .contact-us-container > div.form-header .contact-info {
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: center;
                  justify-content: center;
          margin-top: 20px;
          text-align: center;
          font-size: 12px; }
          .contact-us-container > div.form-header .contact-info i {
            display: block;
            margin: 15px auto 5px;
            font-size: 20px; }
        .contact-us-container > div.contact-form {
          margin: 50px auto 0; }
    .contact-us .floating-leaf.left-bottom {
      -webkit-transform: translate(-22%, 23%);
              transform: translate(-22%, 23%); }
    .contact-us .floating-leaf.right-bottom {
      -webkit-transform: translate(22%, 23%);
              transform: translate(22%, 23%); }
    .contact-us .grid {
      grid-template-columns: 1fr; }
    .contact-us .contact-form {
      padding-right: 0 !important;
      border: none; }
    .contact-us .contact-information {
      min-height: unset; } }

.account-page {
  position: relative;
  background-color: #fcf7ea;
  min-height: calc(100vh - 390px + 78px - 100px); }
  .account-page h1 {
    font-size: 48px;
    font-family: "cerapro-bold"; }
  .account-page .txt-12-px {
    font-size: 12px !important; }
  .account-page .txt-17-px {
    font-size: 17px !important; }
  .account-page .txt-15-px {
    font-size: 15px !important; }
  .account-page .flex-wrap {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
  .account-page .align-items-center {
    -webkit-align-items: center;
            align-items: center; }
  .account-page .container {
    max-width: 900px; }
    .account-page .container .nav-container > ul > li h4 {
      font-family: "cerapro-bold";
      font-size: 17px; }
    .account-page .container .nav-container > ul > li > ul {
      margin-left: 20px;
      margin-bottom: 10px; }
    .account-page .container .nav-container > ul > li:last-of-type {
      border-top: 1px solid #cbcbcb;
      padding-top: 5px; }
    .account-page .container .nav-container a {
      font-size: 15px;
      margin-top: 10px;
      margin-bottom: 10px;
      display: block; }
      .account-page .container .nav-container a.active {
        color: #58a300;
        pointer-events: none; }
    .account-page .container .content-container {
      background-color: #fff;
      padding: 0; }
      .account-page .container .content-container .personal-data-page .change-toggle {
        position: relative; }
        .account-page .container .content-container .personal-data-page .change-toggle button {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          padding: 0 20px;
          font-size: 14px;
          color: #58a300;
          background: none;
          outline: none;
          border: none; }
      .account-page .container .content-container .personal-data-page .btn {
        min-width: 99px; }
      .account-page .container .content-container .order-history .status-nav {
        padding: 8px 0;
        display: block; }
        .account-page .container .content-container .order-history .status-nav.active {
          border-bottom: 4px solid #58a300; }
      @media (max-width: 767px) {
        .account-page .container .content-container .order-history {
          padding: 2rem !important; } }
      .account-page .container .content-container .order-history .order-item {
        border-top: 1px solid #cbcbcb; }
        .account-page .container .content-container .order-history .order-item .item-product img {
          width: 50px;
          height: 50px;
          margin-right: 5px; }
        .account-page .container .content-container .order-history .order-item .item-product .flex {
          -webkit-flex-wrap: wrap;
                  flex-wrap: wrap; }
        .account-page .container .content-container .order-history .order-item .flex {
          -webkit-align-items: center;
                  align-items: center; }
        .account-page .container .content-container .order-history .order-item .float-right {
          text-align: end; }
        .account-page .container .content-container .order-history .order-item .btn {
          margin-bottom: 10px;
          min-width: 99px; }
      .account-page .container .content-container .order-history-view .back-btn {
        border: none;
        outline: none;
        background: none; }
      .account-page .container .content-container .order-history-view .order-status {
        position: relative; }
        .account-page .container .content-container .order-history-view .order-status .connector-line {
          position: absolute;
          top: 35px;
          width: 50%;
          border-bottom: 1px solid #58a300;
          z-index: 1; }
          .account-page .container .content-container .order-history-view .order-status .connector-line.second {
            right: 0; }
          .account-page .container .content-container .order-history-view .order-status .connector-line.gray-line {
            border-bottom: 1px solid #cbcbcb; }
        @media (max-width: 430px) {
          .account-page .container .content-container .order-history-view .order-status .deliver {
            width: 100%; } }
        @media (max-width: 329px) {
          .account-page .container .content-container .order-history-view .order-status .mobile-img {
            width: 100%; } }
        .account-page .container .content-container .order-history-view .order-status > div {
          position: relative;
          background-color: #fff;
          width: 102px;
          z-index: 2; }
          .account-page .container .content-container .order-history-view .order-status > div .img-container {
            background-color: #fcf7ea;
            width: 70px;
            height: 70px;
            padding: 15px;
            -webkit-align-items: center;
                    align-items: center;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: center;
                    justify-content: center;
            border-radius: 50px; }
            .account-page .container .content-container .order-history-view .order-status > div .img-container img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center; }
          .account-page .container .content-container .order-history-view .order-status > div.active .img-container {
            background-color: #58a300; }
            .account-page .container .content-container .order-history-view .order-status > div.active .img-container img {
              -webkit-filter: brightness(0) invert(1);
                      filter: brightness(0) invert(1); }
          .account-page .container .content-container .order-history-view .order-status > div.pending .img-container {
            background-color: #fff; }
            .account-page .container .content-container .order-history-view .order-status > div.pending .img-container img {
              -webkit-filter: grayscale(1);
                      filter: grayscale(1); }
      .account-page .container .content-container .order-history-view .view-items {
        border-top: 1px solid #c9c9c9; }
      .account-page .container .content-container .order-history-view .price-container {
        border-top: 1px solid #c9c9c9; }
        .account-page .container .content-container .order-history-view .price-container > :last-child {
          min-width: 120px; }
      .account-page .container .content-container .order-history-view .customer-info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        border: 1px solid #cbcbcb; }
        .account-page .container .content-container .order-history-view .customer-info > div:not(:last-child) {
          margin-bottom: 20px; }
        .account-page .container .content-container .order-history-view .customer-info h5,
        .account-page .container .content-container .order-history-view .customer-info p {
          font-family: "cerapro-bold";
          color: #000000;
          margin-bottom: 0.25rem;
          font-size: 12px;
          line-height: 20px; }
        .account-page .container .content-container .order-history-view .customer-info p {
          font-family: "cerapro-regular";
          color: #6f6f6f; }
      .account-page .container .content-container .have-question {
        background-color: #cbcbcb; }
  .account-page .modal-caboodle {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out; }
    .account-page .modal-caboodle.open {
      pointer-events: auto;
      opacity: 1; }
    .account-page .modal-caboodle .floating-leaf {
      position: absolute;
      pointer-events: none;
      z-index: 5; }
      .account-page .modal-caboodle .floating-leaf.left {
        left: 0; }
        .account-page .modal-caboodle .floating-leaf.left-upper {
          -webkit-transform: translate(-33%, -70%);
                  transform: translate(-33%, -70%);
          width: 769px; }
        .account-page .modal-caboodle .floating-leaf.left.faded-3 {
          opacity: 0.3; }
      .account-page .modal-caboodle .floating-leaf.right {
        right: 0; }
        .account-page .modal-caboodle .floating-leaf.right-upper {
          -webkit-transform: translate(33%, -70%);
                  transform: translate(33%, -70%);
          width: 769px; }
        .account-page .modal-caboodle .floating-leaf.right.faded-3 {
          opacity: 0.3; }
      .account-page .modal-caboodle .floating-leaf.faded {
        opacity: 0.3; }
        .account-page .modal-caboodle .floating-leaf.faded-light {
          opacity: 0.7; }
        .account-page .modal-caboodle .floating-leaf.faded-9 {
          opacity: 0.9; }
    .account-page .modal-caboodle .modal-container {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      width: 100%;
      height: 100%;
      padding: 40px; }
      .account-page .modal-caboodle .modal-container .modal-ingredient,
      .account-page .modal-caboodle .modal-container .modal-review {
        position: relative;
        background-color: #fcf7ea;
        text-align: center;
        width: 100%; }
        .account-page .modal-caboodle .modal-container .modal-ingredient .modal-exit,
        .account-page .modal-caboodle .modal-container .modal-review .modal-exit {
          position: absolute;
          right: 0;
          top: 0;
          -webkit-transform: translateX(150%);
                  transform: translateX(150%);
          border: none;
          background-color: #fff;
          color: #4e4e4e;
          border-radius: 20px; }
        .account-page .modal-caboodle .modal-container .modal-ingredient .star-container button,
        .account-page .modal-caboodle .modal-container .modal-review .star-container button {
          border: none;
          background: none;
          outline: none;
          color: #58a300; }
        .account-page .modal-caboodle .modal-container .modal-ingredient .review-information,
        .account-page .modal-caboodle .modal-container .modal-review .review-information {
          height: 100%;
          overflow-y: auto;
          overflow-x: hidden;
          position: relative;
          z-index: 20;
          /* width */
          /* Track */
          /* Handle */
          /* Handle on hover */ }
          .account-page .modal-caboodle .modal-container .modal-ingredient .review-information::-webkit-scrollbar,
          .account-page .modal-caboodle .modal-container .modal-review .review-information::-webkit-scrollbar {
            width: 5px; }
          .account-page .modal-caboodle .modal-container .modal-ingredient .review-information::-webkit-scrollbar-track,
          .account-page .modal-caboodle .modal-container .modal-review .review-information::-webkit-scrollbar-track {
            background: #f1f1f1; }
          .account-page .modal-caboodle .modal-container .modal-ingredient .review-information::-webkit-scrollbar-thumb,
          .account-page .modal-caboodle .modal-container .modal-review .review-information::-webkit-scrollbar-thumb {
            background: #979797;
            opacity: 0.5; }
          .account-page .modal-caboodle .modal-container .modal-ingredient .review-information::-webkit-scrollbar-thumb:hover,
          .account-page .modal-caboodle .modal-container .modal-review .review-information::-webkit-scrollbar-thumb:hover {
            background: #7a7a7a;
            cursor: pointer; }
      .account-page .modal-caboodle .modal-container .modal-ingredient {
        padding: 30px 20px 100px;
        max-width: 450px;
        border-radius: 10px; }
        .account-page .modal-caboodle .modal-container .modal-ingredient > img {
          width: inherit;
          margin-top: 20px;
          margin-bottom: 20px; }
        .account-page .modal-caboodle .modal-container .modal-ingredient .title {
          margin-bottom: 20px;
          color: #164e36; }
      .account-page .modal-caboodle .modal-container .modal-review {
        padding: 40px 50px;
        max-width: 650px;
        position: relative; }
        @media (max-width: 420px) {
          .account-page .modal-caboodle .modal-container .modal-review {
            padding: 40px 30px; } }
        .account-page .modal-caboodle .modal-container .modal-review .images-container {
          width: 100%; }
          .account-page .modal-caboodle .modal-container .modal-review .images-container .image {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            overflow: hidden; }
  @media (max-width: 767px) {
    .account-page .row {
      margin-left: 25px; } }
  @media (max-width: 535px) {
    .account-page .row {
      margin: 0 !important; } }
  @media (max-width: 435px) {
    .account-page .container .content-container .order-history-view .customer-info {
      grid-template-columns: 1fr; } }
  @media (max-width: 420px) {
    .account-page .justify-content-center {
      -webkit-justify-content: center !important;
              justify-content: center !important; }
      .account-page .justify-content-center > div:nth-child(2) {
        margin-top: 20px; } }

.pagination li a {
  padding: 5px 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 25px; }
  .pagination li a.active {
    color: #58a300; }

.checkout-page {
  min-height: calc(100vh - 390px + 78px - 100px); }
  .checkout-page .flex-wrap {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
  .checkout-page .txt-12-px {
    font-size: 12px !important; }
  .checkout-page .txt-14-px {
    font-size: 14px !important; }
  .checkout-page .container {
    max-width: 970px; }
    .checkout-page .container .breadcrumbs {
      color: #164e36; }
      .checkout-page .container .breadcrumbs span,
      .checkout-page .container .breadcrumbs a {
        font-family: "cerapro-medium"; }
        .checkout-page .container .breadcrumbs span.active,
        .checkout-page .container .breadcrumbs a.active {
          color: #58a300; }
    .checkout-page .container .order-summary {
      background-color: #fcf7ea;
      padding: 15px 10px;
      border-radius: 4px; }
      .checkout-page .container .order-summary .items .item {
        display: grid;
        grid-template-columns: 60px 1fr;
        grid-gap: 10px;
        gap: 10px; }
        .checkout-page .container .order-summary .items .item img {
          width: 100%;
          height: auto;
          object-position: center;
          object-fit: contain; }
        .checkout-page .container .order-summary .items .item .quantity-control {
          display: -webkit-flex;
          display: flex; }
          .checkout-page .container .order-summary .items .item .quantity-control .btn {
            height: 32px;
            width: 37px;
            border-radius: 7px;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
                    align-items: center;
            -webkit-justify-content: center;
                    justify-content: center;
            padding: 0; }
          .checkout-page .container .order-summary .items .item .quantity-control input {
            border: none;
            background: none;
            width: 70px;
            text-align: center; }
            .checkout-page .container .order-summary .items .item .quantity-control input:focus {
              outline: none; }
      .checkout-page .container .order-summary .voucher-container {
        display: -webkit-flex;
        display: flex;
        background-color: #fff;
        border: 1px solid #c9c9c9;
        border-radius: 9px;
        padding: 1px; }
        .checkout-page .container .order-summary .voucher-container input {
          padding: 5px;
          background: none;
          border: none;
          width: 100%; }
        .checkout-page .container .order-summary .voucher-container .btn {
          border-radius: 9px;
          padding: 0;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
                  align-items: center;
          -webkit-justify-content: center;
                  justify-content: center;
          width: 68px;
          height: 36px;
          padding: 10px 32px; }
    .checkout-page .container .payment .billing-container {
      outline: 1px solid #cbcbcb;
      outline-offset: -1px; }
      .checkout-page .container .payment .billing-container .billing-form {
        max-height: 0;
        transition: max-height 0.8s cubic-bezier(0, 1, 0, 1);
        overflow: hidden; }
        .checkout-page .container .payment .billing-container .billing-form.active {
          max-height: 820px;
          transition: max-height 0.5s ease-in-out; }
  @media (max-width: 767px) {
    .checkout-page .payment-row {
      -webkit-flex-direction: column-reverse;
              flex-direction: column-reverse; } }

.shipping-summary-item {
  display: -webkit-flex;
  display: flex;
  border: 1px solid #cbcbcb;
  padding: 8px 20px; }
  .shipping-summary-item .title {
    width: 100%;
    max-width: 100px;
    color: #6f6f6f; }
  .shipping-summary-item .content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%; }
  .shipping-summary-item .action {
    overflow: hidden;
    color: #58a300;
    width: 100%;
    max-width: 85px;
    background: none;
    border: none;
    padding: 0;
    text-align: right; }

.select-method {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px 20px;
  border: 1px solid #cbcbcb; }
  .select-method .icon-holder {
    box-shadow: inset 0 0 2px #58a300;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
    .select-method .icon-holder i {
      -webkit-transform: scale(0);
              transform: scale(0);
      transition: 0.3s;
      color: #58a300;
      font-size: 20px; }
  @media (max-width: 384px) {
    .select-method .title {
      max-width: 100px; } }
  @media (max-width: 322px) {
    .select-method .title {
      max-width: 95px; } }
  .select-method .img-right {
    height: 24px; }
    .select-method .img-right img {
      height: 100%;
      object-fit: contain;
      object-position: center; }
  .select-method.active .icon-holder i {
    -webkit-transform: scale(1);
            transform: scale(1); }
  @media (max-width: 382px) {
    .select-method .title {
      max-width: 100px; }
    .select-method .img-right {
      height: 20px; } }

.order-status-page .txt-green {
  color: #1d600c; }

.order-status-page .container .bg-yellow {
  background-color: #fcf7ea; }

.order-status-page .container .txt-14-px {
  font-size: 14px !important; }

.order-status-page .container .customer-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid #cbcbcb; }
  .order-status-page .container .customer-info > div:not(:last-child) {
    margin-bottom: 20px; }
  .order-status-page .container .customer-info div {
    line-height: 20px; }
  .order-status-page .container .customer-info h5,
  .order-status-page .container .customer-info p {
    font-family: "cerapro-bold";
    color: #000000;
    margin-bottom: 0.25rem;
    font-size: 15px;
    line-height: 20px; }
  .order-status-page .container .customer-info p {
    font-family: "cerapro-regular";
    color: #6f6f6f; }

.order-status-page .container .order-summary {
  background-color: #f4f4f4;
  padding: 15px 10px;
  border-radius: 4px; }
  .order-status-page .container .order-summary .items .item {
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-gap: 10px;
    gap: 10px; }
    .order-status-page .container .order-summary .items .item img {
      width: 100%;
      object-position: center;
      object-fit: contain; }

@media (max-width: 430px) {
  .order-status-page .container .customer-info {
    grid-template-columns: 1fr; } }

@media (max-width: 991px) {
  .t-and-c .page-container > .flex > div {
    margin: 0;
    padding: 0; }
    .t-and-c .page-container > .flex > div.tc-page-links {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 40px; }
      .t-and-c .page-container > .flex > div.tc-page-links > div {
        margin: 0;
        text-align: center; }
        .t-and-c .page-container > .flex > div.tc-page-links > div:last-child {
          border-left: 2px solid #ccc; } }

@media (max-width: 767px) {
  .t-and-c .page-container > .flex > div.tc-page-links {
    grid-template-columns: 1fr;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto; }
    .t-and-c .page-container > .flex > div.tc-page-links > div {
      padding: 10px;
      border: 1px solid #5e5e5e;
      margin-bottom: 20px; } }

.replacement-options .replacement-option {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  transition: 0.3s; }
  .replacement-options .replacement-option .icon-holder {
    box-shadow: inset 0 0 2px #111;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
    .replacement-options .replacement-option .icon-holder i {
      -webkit-transform: scale(0);
              transform: scale(0);
      transition: 0.3s; }
  .replacement-options .replacement-option:hover {
    opacity: 0.5; }
  .replacement-options .replacement-option.active .icon-holder i {
    -webkit-transform: scale(1);
            transform: scale(1); }

.my-cart-page-container .custom-table {
  display: grid;
  grid-template-columns: auto 1fr 2fr auto 1.5fr auto;
  border: solid 1px #c9c9c9;
  border-bottom: 0; }
  .my-cart-page-container .custom-table > div {
    padding: 15px;
    border-bottom: solid 1px #c9c9c9; }
  .my-cart-page-container .custom-table .table-header {
    background-color: #c9c9c9;
    font-family: "quicksand-bold"; }
  .my-cart-page-container .custom-table .image .product-image {
    max-width: 50px; }
  .my-cart-page-container .custom-table .edit-qty-container {
    display: grid;
    grid-template-columns: 40px 70px 40px; }
    .my-cart-page-container .custom-table .edit-qty-container .btn {
      padding: 5px 0;
      border-radius: 0; }
      .my-cart-page-container .custom-table .edit-qty-container .btn i {
        transition: 0.3s; }
      .my-cart-page-container .custom-table .edit-qty-container .btn:hover i {
        -webkit-transform: scale(1.4);
                transform: scale(1.4);
        color: inherit; }
    .my-cart-page-container .custom-table .edit-qty-container input {
      text-align: center; }

.my-cart-page-container .prescription-form .prescriptions {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .my-cart-page-container .prescription-form .prescriptions > div img {
    width: 100px;
    height: 100px;
    object-fit: cover; }
  .my-cart-page-container .prescription-form .prescriptions > div .btn {
    background: #e20612;
    width: 30px;
    height: 30px;
    color: #fff;
    position: absolute;
    right: 5px;
    top: 5px; }

.my-cart-page-container .prescription-form .empty-prescription {
  width: 100%;
  height: 150px;
  border: 2px dashed #aaa;
  border-radius: 10px;
  position: relative; }
  .my-cart-page-container .prescription-form .empty-prescription input {
    opacity: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute; }

.my-cart-page-container .mobile-controls {
  display: none; }

@media (max-width: 767px) {
  .my-cart-page-container .custom-table {
    grid-template-columns: 1fr 2fr auto 1fr auto; }
    .my-cart-page-container .custom-table .table-header,
    .my-cart-page-container .custom-table .sku,
    .my-cart-page-container .custom-table .product-description {
      display: none; } }

@media (max-width: 600px) {
  .my-cart-page-container .custom-table {
    grid-template-columns: auto 2fr; }
    .my-cart-page-container .custom-table .item-price {
      display: none; }
    .my-cart-page-container .custom-table .edit-qty-container {
      grid-template-columns: 30px 40px 30px; }
      .my-cart-page-container .custom-table .edit-qty-container .btn {
        padding: 5px 0; }
  .my-cart-page-container .desktop-controls {
    display: none; }
  .my-cart-page-container .mobile-controls {
    display: block; }
    .my-cart-page-container .mobile-controls.flex {
      display: -webkit-flex;
      display: flex; }
  .my-cart-page-container .button-container {
    display: block; }
    .my-cart-page-container .button-container .btn {
      display: block;
      margin: 10px auto;
      width: 100%; } }

@media (max-width: 380px) {
  .my-cart-page-container .mobile-controls.flex {
    display: block; }
    .my-cart-page-container .mobile-controls.flex .total {
      text-align: left !important;
      margin-top: 20px; } }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
input[type=number] {
  -moz-appearance: textfield; }

.product-review-container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 15px 60px; }
  .product-review-container .btn {
    max-width: 200px;
    margin: auto; }
  .product-review-container .user-image-container .user-image {
    width: 75px;
    height: 75px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 100%; }
  .product-review-container .product-review-content .product-review-form .rating-buttons {
    padding-bottom: 20px; }
  .product-review-container .product-review-content .product-review-form .form-group {
    margin-bottom: 30px; }
  .product-review-container h1 {
    font-size: 1.5rem;
    padding-top: 20px;
    margin: 0;
    font-family: "quicksand-regular";
    grid-column: 1/3;
    color: #164e36; }
  .product-review-container .product-review-list {
    grid-column: 1/3; }
    .product-review-container .product-review-list li {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 15px 60px;
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: solid 1px #c9c9c9; }
      .product-review-container .product-review-list li:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0; }
      .product-review-container .product-review-list li .review-header {
        display: grid;
        grid-template-columns: 1fr 1fr; }
        .product-review-container .product-review-list li .review-header .name {
          font-family: "quicksand-light-italic"; }
        .product-review-container .product-review-list li .review-header .date {
          font-size: 0.625rem;
          color: #c9c9c9;
          text-align: right; }
      .product-review-container .product-review-list li .review-description {
        font-size: 0.8125rem;
        padding-top: 15px; }
  @media (max-width: 600px) {
    .product-review-container {
      grid-template-columns: 1fr; }
      .product-review-container h1, .product-review-container .product-review-list {
        grid-column: unset; }
      .product-review-container .product-review-list li {
        grid-template-columns: 1fr; } }

.shipping-form .row > div {
  margin-top: 5px;
  margin-bottom: 5px; }

.shipping-form .row input,
.shipping-form .row select {
  width: 100%;
  border: 1px solid #cbcbcb;
  color: #6f6f6f;
  height: 40px;
  border-radius: 3px;
  padding: 6px 12px; }

.shipping-zone-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border: solid 1px #f0f0f0;
  border-bottom: 0; }
  .shipping-zone-list > div {
    padding: 10px;
    border-bottom: solid 1px #f0f0f0; }
  .shipping-zone-list .list-label {
    background-color: #f0f0f0;
    font-family: "quicksand-bold"; }
  .shipping-zone-list .zone-name {
    grid-column: 1/4;
    font-family: "quicksand-regular"; }
  .shipping-zone-list .zone-type {
    padding-left: 15px; }

.testimonial-section {
  color: black; }
  .testimonial-section .slick-arrow {
    font-size: 20px;
    color: #164e36;
    font-size: 32px;
    width: auto;
    height: auto; }
    .testimonial-section .slick-arrow::before {
      display: none; }
  .testimonial-section .items-container {
    padding: 20px; }
    .testimonial-section .items-container .slick-active {
      padding-left: 5px;
      padding-right: 5px; }
    .testimonial-section .items-container .item {
      background-color: #fff;
      position: relative;
      padding: 110px 20px 30px;
      border-radius: 40px;
      opacity: 0.4;
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
      transition: all 0.3s ease;
      margin-top: 100px; }
      .testimonial-section .items-container .item .txt-small {
        font-size: 14px !important;
        line-height: 21px; }
      .testimonial-section .items-container .item h5 {
        font-size: 12px !important; }
      .testimonial-section .items-container .item .txt-18-px {
        font-size: 18px !important; }
      .testimonial-section .items-container .item img {
        max-width: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        max-height: 200px;
        max-width: 100%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
      .testimonial-section .items-container .item.active {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1); }

.floating-slider {
  position: relative;
  overflow: hidden;
  color: #fff; }
  .floating-slider .white-bottom {
    content: "";
    position: absolute;
    height: 185px;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fcf7ea; }
    .floating-slider .white-bottom img {
      -webkit-transform: translate(-50%, -45%);
              transform: translate(-50%, -45%); }
  .floating-slider .items {
    max-width: none;
    margin-top: 20px; }
    .floating-slider .items .slick-slider {
      border-radius: 20px;
      overflow: hidden; }
    .floating-slider .items .item {
      min-height: 450px;
      border-radius: 20px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat; }
      .floating-slider .items .item > div > p {
        line-height: 24px !important; }
      .floating-slider .items .item:after {
        content: "";
        background: transparent linear-gradient(180deg, #00000000 0%, rgba(0, 0, 0, 0.1) 100%) 0% 0% no-repeat padding-box;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        border-radius: 20px; }
      .floating-slider .items .item > * {
        max-width: 400px;
        margin-left: 20px;
        margin-right: 20px;
        line-height: 1.5rem;
        position: relative;
        z-index: 1; }
    .floating-slider .items .slick-dots {
      bottom: 15px; }
      .floating-slider .items .slick-dots li button {
        background-color: #164e36;
        border-color: #164e36; }
      .floating-slider .items .slick-dots li.slick-active button, .floating-slider .items .slick-dots li:hover button {
        background-color: #58a300;
        border-color: #58a300;
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 0.9; }
  .floating-slider .floating-leaf {
    position: absolute;
    pointer-events: none; }
    .floating-slider .floating-leaf.left {
      left: 0; }
      .floating-slider .floating-leaf.left-top {
        top: 0;
        -webkit-transform: translateX(5px);
                transform: translateX(5px); }
      .floating-slider .floating-leaf.left-vine {
        top: 0;
        -webkit-transform: translate(-20%, -3%);
                transform: translate(-20%, -3%); }
      .floating-slider .floating-leaf.left-wax {
        -webkit-transform: translate(-68%, 138%);
                transform: translate(-68%, 138%); }
      .floating-slider .floating-leaf.left-leaf {
        -webkit-transform: translate(-21%, 4%);
                transform: translate(-21%, 4%); }
      .floating-slider .floating-leaf.left-slider-leaf {
        bottom: 50%;
        -webkit-transform: translate(-67%, 33%);
                transform: translate(-67%, 33%); }
      .floating-slider .floating-leaf.left-slider-lower {
        top: 100%;
        -webkit-transform: translate(10%, -55%);
                transform: translate(10%, -55%); }
      .floating-slider .floating-leaf.left-wave {
        -webkit-transform: translate(-40%, 0);
                transform: translate(-40%, 0);
        mix-blend-mode: screen; }
      .floating-slider .floating-leaf.left-wave2 {
        -webkit-transform: translate(-50%, -13%);
                transform: translate(-50%, -13%); }
    .floating-slider .floating-leaf.right {
      right: 0; }
      .floating-slider .floating-leaf.right-top {
        top: 0;
        -webkit-transform: translateX(-5px);
                transform: translateX(-5px); }
      .floating-slider .floating-leaf.right-vine {
        top: 0;
        -webkit-transform: translate(20%, -3%);
                transform: translate(20%, -3%); }
      .floating-slider .floating-leaf.right-wax {
        -webkit-transform: translate(64%, 160%);
                transform: translate(64%, 160%); }
      .floating-slider .floating-leaf.right-leaf {
        -webkit-transform: translate(20.2%, 4%);
                transform: translate(20.2%, 4%); }
      .floating-slider .floating-leaf.right-slider-leaf {
        bottom: 50%;
        -webkit-transform: translate(67%, 33%);
                transform: translate(67%, 33%); }
      .floating-slider .floating-leaf.right-slider-lower {
        top: 100%;
        -webkit-transform: translate(-10%, -55%);
                transform: translate(-10%, -55%); }
      .floating-slider .floating-leaf.right-wave {
        -webkit-transform: translate(40%, 0);
                transform: translate(40%, 0);
        mix-blend-mode: screen; }
      .floating-slider .floating-leaf.right-wave2 {
        -webkit-transform: translate(53%, -13%);
                transform: translate(53%, -13%); }
    .floating-slider .floating-leaf.faded {
      opacity: 0.3; }
      .floating-slider .floating-leaf.faded-light {
        opacity: 0.7; }
      .floating-slider .floating-leaf.faded-9 {
        opacity: 0.9; }

.journals {
  background-color: #fcf7ea;
  position: relative;
  overflow: hidden; }
  .journals .txt-shadow {
    text-shadow: 0px 3px 6px #00000029; }
  .journals .banner {
    position: relative;
    min-height: 400px;
    color: #fff;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    .journals .banner p {
      margin-bottom: 0; }
    .journals .banner .content {
      line-height: 72px;
      min-height: 250px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: column;
              flex-flow: column;
      -webkit-justify-content: flex-end;
              justify-content: flex-end; }
      @media (max-width: 360px) {
        .journals .banner .content div {
          font-size: 45px !important; } }
    .journals .banner .container {
      position: relative; }
  .journals .end-section::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent linear-gradient(180deg, #00000000 0%, rgba(0, 0, 0, 0.8) 100%) 0% 0% no-repeat padding-box; }
  .journals .start-section::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.1;
    background: #164e36; }
  @media (max-width: 360px) {
    .journals .start-section .container {
      font-size: 45px !important; } }
  .journals .txt-shadow {
    text-shadow: 0px 3px 6px #00000029; }
  .journals .green {
    font-size: 17px !important;
    color: #1c5b44; }
  .journals .journal-content h2 {
    line-height: 70px; }
  .journals .journal-content h3 {
    font-size: 17px !important; }
  .journals .journal-content img {
    width: 100%; }
  .journals .journal-content .content {
    line-height: 30px;
    max-width: 85%; }
  .journals .with-border-bottom {
    border-bottom: 1px solid #6f6f6f; }
  .journals .go-back {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .journals .go-back img {
      margin-right: 10px; }
    .journals .go-back .back {
      margin-bottom: 0; }
  .journals .btn-no-style {
    border: none;
    background: none; }
  .journals-container {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-left: 0;
    padding-right: 0;
    margin-top: 50px; }
  .journals .floating-leaf {
    position: absolute;
    pointer-events: none; }
    .journals .floating-leaf.left {
      left: 0; }
      .journals .floating-leaf.left-top {
        top: 0;
        -webkit-transform: translateX(5px);
                transform: translateX(5px); }
      .journals .floating-leaf.left-vine {
        top: 0;
        -webkit-transform: translate(-20%, -3%);
                transform: translate(-20%, -3%); }
      .journals .floating-leaf.left-wax {
        -webkit-transform: translate(-68%, 138%);
                transform: translate(-68%, 138%); }
      .journals .floating-leaf.left-leaf {
        -webkit-transform: translate(-21%, 4%);
                transform: translate(-21%, 4%); }
      .journals .floating-leaf.left-slider-leaf {
        bottom: 50%;
        -webkit-transform: translate(-67%, 33%);
                transform: translate(-67%, 33%); }
      .journals .floating-leaf.left-slider-lower {
        top: 100%;
        -webkit-transform: translate(10%, -55%);
                transform: translate(10%, -55%); }
      .journals .floating-leaf.left-wave {
        -webkit-transform: translate(-40%, 0);
                transform: translate(-40%, 0);
        mix-blend-mode: screen; }
      .journals .floating-leaf.left-wave2 {
        -webkit-transform: translate(-50%, -13%);
                transform: translate(-50%, -13%); }
    .journals .floating-leaf.right {
      right: 0; }
      .journals .floating-leaf.right-top {
        top: 0;
        -webkit-transform: translateX(-5px);
                transform: translateX(-5px); }
      .journals .floating-leaf.right-vine {
        top: 0;
        -webkit-transform: translate(20%, -3%);
                transform: translate(20%, -3%); }
      .journals .floating-leaf.right-wax {
        -webkit-transform: translate(64%, 160%);
                transform: translate(64%, 160%); }
      .journals .floating-leaf.right-leaf {
        -webkit-transform: translate(20.2%, 4%);
                transform: translate(20.2%, 4%); }
      .journals .floating-leaf.right-slider-leaf {
        bottom: 50%;
        -webkit-transform: translate(67%, 33%);
                transform: translate(67%, 33%); }
      .journals .floating-leaf.right-slider-lower {
        top: 100%;
        -webkit-transform: translate(-10%, -55%);
                transform: translate(-10%, -55%); }
      .journals .floating-leaf.right-wave {
        -webkit-transform: translate(40%, 0);
                transform: translate(40%, 0);
        mix-blend-mode: screen; }
      .journals .floating-leaf.right-wave2 {
        -webkit-transform: translate(53%, -13%);
                transform: translate(53%, -13%); }
    .journals .floating-leaf.faded {
      opacity: 0.3; }
      .journals .floating-leaf.faded-light {
        opacity: 0.7; }
      .journals .floating-leaf.faded-9 {
        opacity: 0.9; }
  .journals .journals-section .latest-news {
    position: relative; }
    .journals .journals-section .latest-news .floating-leaf {
      position: absolute;
      pointer-events: none; }
      .journals .journals-section .latest-news .floating-leaf.left {
        left: 0; }
        .journals .journals-section .latest-news .floating-leaf.left-upper {
          -webkit-transform: translate(-40%, -40%);
                  transform: translate(-40%, -40%); }
        .journals .journals-section .latest-news .floating-leaf.left.faded-3 {
          opacity: 0.3; }
      .journals .journals-section .latest-news .floating-leaf.right {
        right: 0; }
        .journals .journals-section .latest-news .floating-leaf.right-upper {
          -webkit-transform: translate(40%, -40%);
                  transform: translate(40%, -40%); }
        .journals .journals-section .latest-news .floating-leaf.right.faded-3 {
          opacity: 0.3; }
  .journals .journals-section .journal-view .floating-leaf.left {
    left: 0; }
    .journals .journals-section .journal-view .floating-leaf.left-upper {
      -webkit-transform: translate(-70%, -40%);
              transform: translate(-70%, -40%); }
  .journals .journals-section .journal-view .floating-leaf.right {
    right: 0; }
    .journals .journals-section .journal-view .floating-leaf.right-upper {
      -webkit-transform: translate(70%, -40%);
              transform: translate(70%, -40%); }
  .journals .journals-section .journals-grid,
  .journals .journals-section .journals-grid-view {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 30px; }
    .journals .journals-section .journals-grid .title,
    .journals .journals-section .journals-grid-view .title {
      font-size: 17px !important; }
    .journals .journals-section .journals-grid .date,
    .journals .journals-section .journals-grid-view .date {
      font-size: 14px !important; }
    .journals .journals-section .journals-grid .all-caps,
    .journals .journals-section .journals-grid-view .all-caps {
      text-transform: uppercase; }
    .journals .journals-section .journals-grid .journal,
    .journals .journals-section .journals-grid-view .journal {
      position: relative;
      z-index: 1; }
      .journals .journals-section .journals-grid .journal .no-wrap,
      .journals .journals-section .journals-grid-view .journal .no-wrap {
        white-space: nowrap;
        overflow: hidden; }
      .journals .journals-section .journals-grid .journal .img-container,
      .journals .journals-section .journals-grid-view .journal .img-container {
        position: relative;
        width: 100%;
        height: 250px;
        margin-bottom: 20px;
        overflow: hidden; }
        .journals .journals-section .journals-grid .journal .img-container .on-hover,
        .journals .journals-section .journals-grid-view .journal .img-container .on-hover {
          position: absolute;
          width: 100%;
          height: 100%;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: center;
                  justify-content: center;
          -webkit-align-items: center;
                  align-items: center;
          z-index: 10;
          opacity: 0;
          transition: all 0.35s ease; }
        .journals .journals-section .journals-grid .journal .img-container .background-overlay,
        .journals .journals-section .journals-grid-view .journal .img-container .background-overlay {
          background-color: black;
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 5;
          opacity: 0;
          transition: all 0.35s ease; }
        .journals .journals-section .journals-grid .journal .img-container img,
        .journals .journals-section .journals-grid-view .journal .img-container img {
          position: absolute;
          width: 100%;
          height: 250px;
          object-fit: cover;
          object-position: center;
          transition: all 0.35s ease; }
      .journals .journals-section .journals-grid .journal:hover .img-container .on-hover,
      .journals .journals-section .journals-grid-view .journal:hover .img-container .on-hover {
        opacity: 1; }
      .journals .journals-section .journals-grid .journal:hover .img-container .background-overlay,
      .journals .journals-section .journals-grid-view .journal:hover .img-container .background-overlay {
        opacity: 0.2; }
      .journals .journals-section .journals-grid .journal:hover .img-container img,
      .journals .journals-section .journals-grid-view .journal:hover .img-container img {
        -webkit-transform: scale(1.1);
                transform: scale(1.1); }
  .journals .journals-section .btn-load {
    display: -webkit-flex;
    display: flex;
    margin: auto;
    font-size: 16px;
    border: none;
    border-radius: 25px;
    padding: 10px 45px;
    background: #58a300;
    max-width: 200px; }
  .journals .with-top-margin {
    margin-top: 20px; }
  .journals .section-5 > div {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto; }
    .journals .section-5 > div > div {
      background-color: #164e36;
      padding: 50px;
      border-radius: 150px;
      display: grid;
      width: 100%;
      min-height: 270px;
      grid-template-columns: 40% 1fr;
      grid-gap: 50px; }
      .journals .section-5 > div > div .image-container img {
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 100%; }
      .journals .section-5 > div > div .content-container h2 {
        line-height: 48px;
        color: #fff; }
      .journals .section-5 > div > div .content-container > div {
        background-color: #fcf7ea;
        border-radius: 25px;
        max-height: 40px;
        padding: 5px 20px;
        max-width: 90%; }
        .journals .section-5 > div > div .content-container > div a img {
          max-height: 100%; }
  .journals .section-5 .btn-shop {
    width: 120px;
    border: 1px solid #fcf7ea;
    border-radius: 25px;
    background: #fcf7ea;
    color: #164e36;
    padding: 10px 0;
    text-align: center; }
  @media (max-width: 991px) {
    .journals-container {
      display: block;
      padding: 20px 50px; }
      .journals-container > div {
        padding: 0; }
    .journals .journals-section .journals-grid {
      grid-template-columns: repeat(2, minmax(0, 1fr)); }
    .journals .journals-section .journals-grid-view {
      grid-template-columns: repeat(2, minmax(0, 1fr)); }
    .journals .journal-content .content {
      max-width: 100%; }
    .journals .section-5 > div > div .image-container img {
      max-height: 300px; }
    .journals .journals-section .journals-grid-view .journal:nth-child(3) {
      -webkit-transform: translateX(50%);
              transform: translateX(50%); } }
  @media (max-width: 767px) {
    .journals-container {
      display: block;
      padding: 20px; }
      .journals-container > div {
        padding: 0; }
    .journals .floating-leaf.left-bottom {
      -webkit-transform: translate(-22%, 23%);
              transform: translate(-22%, 23%); }
    .journals .floating-leaf.right-bottom {
      -webkit-transform: translate(22%, 23%);
              transform: translate(22%, 23%); } }
  @media (max-width: 700px) {
    .journals .section-5 > div > div {
      grid-template-columns: 1fr; }
    .journals .section-5 .image-container {
      height: 100px; }
      .journals .section-5 .image-container img {
        -webkit-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
        left: 50%;
        max-height: 230px; }
    .journals .section-5 .content-container {
      text-align: center;
      -webkit-align-items: center;
              align-items: center; } }
  @media (max-width: 575px) {
    .journals .journals-section .journals-grid {
      grid-template-columns: 1fr; }
    .journals .section-5 > div > div .image-container img {
      max-height: 230px; }
    .journals .journals-section .journals-grid-view {
      grid-template-columns: 1fr; }
    .journals .journals-section .journals-grid .journal .no-wrap {
      white-space: unset !important; }
    .journals .journals-section .journals-grid-view .journal:nth-child(3) {
      -webkit-transform: translateX(0);
              transform: translateX(0); } }

.journal-leaf.floating-leaf {
  position: absolute;
  pointer-events: none; }
  .journal-leaf.floating-leaf.left {
    left: 0; }
    .journal-leaf.floating-leaf.left-top {
      top: 0;
      -webkit-transform: translateX(5px);
              transform: translateX(5px); }
    .journal-leaf.floating-leaf.left-vine {
      top: 0;
      -webkit-transform: translate(-20%, -3%);
              transform: translate(-20%, -3%); }
    .journal-leaf.floating-leaf.left-wax {
      -webkit-transform: translate(-68%, 138%);
              transform: translate(-68%, 138%); }
    .journal-leaf.floating-leaf.left-leaf {
      -webkit-transform: translate(-21%, 4%);
              transform: translate(-21%, 4%); }
    .journal-leaf.floating-leaf.left-slider-leaf {
      bottom: 50%;
      -webkit-transform: translate(-67%, 33%);
              transform: translate(-67%, 33%); }
    .journal-leaf.floating-leaf.left-slider-lower {
      top: 100%;
      -webkit-transform: translate(10%, -55%);
              transform: translate(10%, -55%); }
    .journal-leaf.floating-leaf.left-wave {
      -webkit-transform: translate(-40%, 0);
              transform: translate(-40%, 0);
      mix-blend-mode: screen; }
    .journal-leaf.floating-leaf.left-wave2 {
      -webkit-transform: translate(-50%, -13%);
              transform: translate(-50%, -13%); }
  .journal-leaf.floating-leaf.right {
    right: 0; }
    .journal-leaf.floating-leaf.right-top {
      top: 0;
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px); }
    .journal-leaf.floating-leaf.right-vine {
      top: 0;
      -webkit-transform: translate(20%, -3%);
              transform: translate(20%, -3%); }
    .journal-leaf.floating-leaf.right-wax {
      -webkit-transform: translate(64%, 160%);
              transform: translate(64%, 160%); }
    .journal-leaf.floating-leaf.right-leaf {
      -webkit-transform: translate(20.2%, 4%);
              transform: translate(20.2%, 4%); }
    .journal-leaf.floating-leaf.right-slider-leaf {
      bottom: 50%;
      -webkit-transform: translate(67%, 33%);
              transform: translate(67%, 33%); }
    .journal-leaf.floating-leaf.right-slider-lower {
      top: 100%;
      -webkit-transform: translate(-10%, -55%);
              transform: translate(-10%, -55%); }
    .journal-leaf.floating-leaf.right-wave {
      -webkit-transform: translate(40%, 0);
              transform: translate(40%, 0);
      mix-blend-mode: screen; }
    .journal-leaf.floating-leaf.right-wave2 {
      -webkit-transform: translate(53%, -13%);
              transform: translate(53%, -13%); }
  .journal-leaf.floating-leaf.faded {
    opacity: 0.3; }
    .journal-leaf.floating-leaf.faded-light {
      opacity: 0.7; }
    .journal-leaf.floating-leaf.faded-3 {
      opacity: 0.3; }
    .journal-leaf.floating-leaf.faded-9 {
      opacity: 0.9; }

.faqs {
  background-color: #fcf7ea;
  position: relative;
  overflow: hidden; }
  .faqs .banner {
    position: relative;
    min-height: 400px;
    color: #fff;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    .faqs .banner p {
      margin-bottom: 0; }
    .faqs .banner .content {
      line-height: 72px;
      min-height: 250px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: column;
              flex-flow: column;
      -webkit-justify-content: flex-end;
              justify-content: flex-end; }
      @media (max-width: 360px) {
        .faqs .banner .content div {
          font-size: 45px !important; } }
    .faqs .banner .container {
      position: relative; }
  .faqs .txt-shadow {
    text-shadow: 0px 3px 6px #00000029; }
  .faqs .end-section::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent linear-gradient(180deg, #00000000 0%, rgba(0, 0, 0, 0.8) 100%) 0% 0% no-repeat padding-box; }
  .faqs .start-section::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.1;
    background: #164e36; }
  @media (max-width: 360px) {
    .faqs .start-section .container {
      font-size: 45px !important; } }
  .faqs .faq-content {
    max-width: 75%;
    margin: auto; }
    .faqs .faq-content .padding-bottom-20 {
      transition: all ease-in-out 0.35s;
      padding-bottom: 20px; }
    .faqs .faq-content .faq-group .green {
      transition: all ease-in-out 0.35s;
      color: #1d600c; }
    .faqs .faq-content .faq-group p {
      margin-bottom: 0; }
    .faqs .faq-content .faq-group .title-group {
      cursor: pointer;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center;
      font-size: 17px !important; }
      .faqs .faq-content .faq-group .title-group .hide {
        transition: all ease 0.35s;
        display: none;
        max-width: 0;
        max-height: 0;
        opacity: 0; }
      .faqs .faq-content .faq-group .title-group i {
        margin-left: 20px;
        color: #1d600c; }
    .faqs .faq-content .faq-group .desc {
      line-height: 30px;
      transition: all ease-in-out 0.35s;
      visibility: hidden;
      max-height: 0;
      opacity: 0; }
    .faqs .faq-content .faq-group .show {
      line-height: 30px;
      transition: all ease-in-out 0.35s;
      visibility: visible;
      max-height: 100%;
      opacity: 1; }
  .faqs .with-border-bottom {
    border-bottom: 1px solid #6f6f6f; }
  .faqs .btn-no-style {
    border: none;
    background: none; }
  .faqs-container {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-left: 0;
    padding-right: 0;
    margin-top: 50px; }
  .faqs .floating-leaf {
    position: absolute;
    pointer-events: none; }
    .faqs .floating-leaf.left {
      left: 0; }
      .faqs .floating-leaf.left-top {
        top: 0;
        -webkit-transform: translateX(5px);
                transform: translateX(5px); }
      .faqs .floating-leaf.left-vine {
        top: 0;
        -webkit-transform: translate(-20%, -3%);
                transform: translate(-20%, -3%); }
      .faqs .floating-leaf.left-wax {
        -webkit-transform: translate(-68%, 138%);
                transform: translate(-68%, 138%); }
      .faqs .floating-leaf.left-leaf {
        -webkit-transform: translate(-21%, 4%);
                transform: translate(-21%, 4%); }
      .faqs .floating-leaf.left-slider-leaf {
        bottom: 50%;
        -webkit-transform: translate(-67%, 33%);
                transform: translate(-67%, 33%); }
      .faqs .floating-leaf.left-slider-lower {
        top: 100%;
        -webkit-transform: translate(10%, -55%);
                transform: translate(10%, -55%); }
      .faqs .floating-leaf.left-wave {
        -webkit-transform: translate(-40%, 0);
                transform: translate(-40%, 0);
        mix-blend-mode: screen; }
      .faqs .floating-leaf.left-wave2 {
        -webkit-transform: translate(-50%, -13%);
                transform: translate(-50%, -13%); }
    .faqs .floating-leaf.right {
      right: 0; }
      .faqs .floating-leaf.right-top {
        top: 0;
        -webkit-transform: translateX(-5px);
                transform: translateX(-5px); }
      .faqs .floating-leaf.right-vine {
        top: 0;
        -webkit-transform: translate(20%, -3%);
                transform: translate(20%, -3%); }
      .faqs .floating-leaf.right-wax {
        -webkit-transform: translate(64%, 160%);
                transform: translate(64%, 160%); }
      .faqs .floating-leaf.right-leaf {
        -webkit-transform: translate(20.2%, 4%);
                transform: translate(20.2%, 4%); }
      .faqs .floating-leaf.right-slider-leaf {
        bottom: 50%;
        -webkit-transform: translate(67%, 33%);
                transform: translate(67%, 33%); }
      .faqs .floating-leaf.right-slider-lower {
        top: 100%;
        -webkit-transform: translate(-10%, -55%);
                transform: translate(-10%, -55%); }
      .faqs .floating-leaf.right-wave {
        -webkit-transform: translate(40%, 0);
                transform: translate(40%, 0);
        mix-blend-mode: screen; }
      .faqs .floating-leaf.right-wave2 {
        -webkit-transform: translate(53%, -13%);
                transform: translate(53%, -13%); }
    .faqs .floating-leaf.faded {
      opacity: 0.3; }
      .faqs .floating-leaf.faded-light {
        opacity: 0.7; }
      .faqs .floating-leaf.faded-9 {
        opacity: 0.9; }
  .faqs .with-top-margin {
    margin-top: 20px; }
  .faqs .section-5 > div {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto; }
    .faqs .section-5 > div > div {
      background-color: #164e36;
      padding: 50px;
      border-radius: 150px;
      display: grid;
      width: 100%;
      min-height: 270px;
      grid-template-columns: 40% 1fr;
      grid-gap: 50px; }
      .faqs .section-5 > div > div .image-container img {
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 100%;
        max-height: 330px; }
      .faqs .section-5 > div > div .content-container h2 {
        line-height: 48px;
        color: #fff; }
      .faqs .section-5 > div > div .content-container > div {
        background-color: #fcf7ea;
        border-radius: 25px;
        max-height: 40px;
        padding: 5px 20px;
        max-width: 90%; }
        .faqs .section-5 > div > div .content-container > div a img {
          max-height: 100%; }
  .faqs .section-5 .btn-shop {
    width: 120px;
    border: 1px solid #fcf7ea;
    border-radius: 25px;
    background: #fcf7ea;
    color: #164e36;
    padding: 10px 0; }
  @media (max-width: 991px) {
    .faqs-container {
      display: block;
      padding: 20px 50px; }
      .faqs-container > div {
        padding: 0; }
    .faqs .section-5 > div > div .image-container img {
      max-height: 300px; } }
  @media (max-width: 767px) {
    .faqs-container {
      display: block;
      padding: 20px; }
      .faqs-container > div {
        padding: 0; }
    .faqs .floating-leaf.left-bottom {
      -webkit-transform: translate(-22%, 23%);
              transform: translate(-22%, 23%); }
    .faqs .floating-leaf.right-bottom {
      -webkit-transform: translate(22%, 23%);
              transform: translate(22%, 23%); } }
  @media (max-width: 700px) {
    .faqs .section-5 > div > div {
      grid-template-columns: 1fr; }
    .faqs .section-5 .image-container {
      height: 100px; }
      .faqs .section-5 .image-container img {
        -webkit-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
        left: 50%;
        max-height: 200px !important; }
    .faqs .section-5 .content-container {
      text-align: center;
      -webkit-align-items: center;
              align-items: center; } }
  @media (max-width: 550px) {
    .faqs .section-5 > div > div .image-container img {
      max-height: 230px; } }
