.account-page {
    position: relative;
    background-color: $lYellow;
    min-height: calc(100vh - 390px + 78px - 100px);

    h1 {
        font-size: $h1;
        font-family: $cerapro-bold;
    }

    .txt-12-px {
        font-size: 12px !important;
    }
    .txt-17-px {
        font-size: 17px !important;
    }
    .txt-15-px {
        font-size: 15px !important;
    }

    .flex-wrap {
        flex-wrap: wrap;
    }

    .align-items-center {
        align-items: center;
    }

    .container {
        max-width: 900px;

        .nav-container {
            > ul {
                > li {
                    h4 {
                        font-family: $cerapro-bold;
                        font-size: 17px;
                    }

                    > ul {
                        margin-left: 20px;
                        margin-bottom: 10px;
                    }

                    &:last-of-type {
                        border-top: 1px solid $lighterGray;
                        padding-top: 5px;
                    }
                }
            }

            a {
                font-size: $medium;
                margin-top: 10px;
                margin-bottom: 10px;
                display: block;

                &.active {
                    color: $lGreen;
                    pointer-events: none;
                }
            }
        }

        .content-container {
            background-color: $white;
            padding: 0;

            .personal-data-page {
                .change-toggle {
                    position: relative;

                    button {
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 100%;
                        padding: 0 20px;
                        font-size: 14px;
                        color: $lGreen;
                        background: none;
                        outline: none;
                        border: none;
                    }
                }
                .btn {
                    min-width: 99px;
                }
            }

            .order-history {
                .status-nav {
                    padding: 8px 0;
                    display: block;

                    &.active {
                        border-bottom: 4px solid $lGreen;
                    }
                }
                @media (max-width: 767px) {
                    padding: 2rem !important;
                }

                .order-item {
                    border-top: 1px solid $lighterGray;

                    .item-product {
                        img {
                            width: 50px;
                            height: 50px;
                            margin-right: 5px;
                        }
                        .flex {
                            flex-wrap: wrap;
                        }
                    }
                    .flex {
                        align-items: center;
                    }
                    .float-right {
                        text-align: end;
                    }
                    .btn {
                        margin-bottom: 10px;
                        min-width: 99px;
                    }
                }
            }

            .order-history-view {
                .back-btn {
                    border: none;
                    outline: none;
                    background: none;
                }

                .order-status {
                    position: relative;

                    .connector-line {
                        position: absolute;
                        top: 35px;
                        width: 50%;
                        border-bottom: 1px solid $lGreen;
                        z-index: 1;

                        &.second {
                            right: 0;
                        }

                        &.gray-line {
                            border-bottom: 1px solid $lighterGray;
                        }
                    }

                    @media (max-width: 430px) {
                        .deliver {
                            width: 100%;
                        }
                    }
                    @media (max-width: 329px) {
                        .mobile-img {
                            width: 100%;
                        }
                    }

                    > div {
                        position: relative;
                        background-color: $white;
                        width: 102px;
                        z-index: 2;

                        .img-container {
                            background-color: #fcf7ea;
                            width: 70px;
                            height: 70px;
                            padding: 15px;
                            align-items: center;
                            display: flex;
                            justify-content: center;
                            border-radius: 50px;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                                object-position: center;
                            }
                        }

                        &.active {
                            .img-container {
                                background-color: $lGreen;

                                img {
                                    filter: brightness(0) invert(1);
                                }
                            }
                        }

                        &.pending {
                            .img-container {
                                background-color: $white;

                                img {
                                    filter: grayscale(1);
                                }
                            }
                        }
                    }
                }

                .view-items {
                    border-top: 1px solid $lightGray;
                }

                .price-container {
                    border-top: 1px solid $lightGray;

                    > :last-child {
                        min-width: 120px;
                    }
                }

                .customer-info {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 10px;
                    border: 1px solid #cbcbcb;

                    > div:not(:last-child) {
                        margin-bottom: 20px;
                    }

                    h5,
                    p {
                        font-family: $cerapro-bold;
                        color: $black;
                        margin-bottom: 0.25rem;
                        font-size: 12px;
                        line-height: 20px;
                    }

                    p {
                        font-family: $cerapro-regular;
                        color: #6f6f6f;
                    }
                }
            }

            .have-question {
                background-color: #cbcbcb;
            }
        }
    }

    .modal-caboodle {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;
        background-color: rgba(0, 0, 0, 0.3);
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease-in-out;

        &.open {
            pointer-events: auto;
            opacity: 1;
        }
        .floating-leaf {
            position: absolute;
            pointer-events: none;
            z-index: 5;

            &.left {
                left: 0;
                &-upper {
                    transform: translate(-33%, -70%);
                    width: 769px;
                }
                &.faded {
                    &-3 {
                        opacity: 0.3;
                    }
                }
            }

            &.right {
                right: 0;
                &-upper {
                    transform: translate(33%, -70%);
                    width: 769px;
                }
                &.faded {
                    &-3 {
                        opacity: 0.3;
                    }
                }
            }

            &.faded {
                opacity: 0.3;

                &-light {
                    opacity: 0.7;
                }

                &-9 {
                    opacity: 0.9;
                }
            }
        }

        .modal-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 40px;

            .modal-ingredient,
            .modal-review {
                position: relative;
                background-color: $lYellow;
                text-align: center;
                width: 100%;

                .modal-exit {
                    position: absolute;
                    right: 0;
                    top: 0;
                    transform: translateX(150%);
                    border: none;
                    background-color: $white;
                    color: $gray;
                    border-radius: 20px;
                }

                .star-container {
                    button {
                        border: none;
                        background: none;
                        outline: none;
                        color: $lGreen;
                    }
                }
                .review-information {
                    height: 100%;
                    overflow-y: auto;
                    overflow-x: hidden;
                    position: relative;
                    z-index: 20;
                    /* width */
                    &::-webkit-scrollbar {
                        width: 5px;
                    }

                    /* Track */
                    &::-webkit-scrollbar-track {
                        background: #f1f1f1;
                    }

                    /* Handle */
                    &::-webkit-scrollbar-thumb {
                        background: rgb(151, 151, 151);
                        opacity: 0.5;
                    }

                    /* Handle on hover */
                    &::-webkit-scrollbar-thumb:hover {
                        background: rgb(122, 122, 122);
                        cursor: pointer;
                    }
                }
            }

            .modal-ingredient {
                padding: 30px 20px 100px;
                max-width: 450px;
                border-radius: 10px;

                > img {
                    width: inherit;
                    margin-top: 20px;
                    margin-bottom: 20px;
                }

                .title {
                    margin-bottom: 20px;
                    color: $green;
                }
            }

            .modal-review {
                padding: 40px 50px;
                max-width: 650px;
                position: relative;
                @media (max-width: 420px) {
                    padding: 40px 30px;
                }

                .images-container {
                    width: 100%;
                    .image {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        overflow: hidden;
                    }
                }
            }
        }
    }
    @media (max-width: 767px) {
        .row {
            margin-left: 25px;
        }
    }
    @media (max-width: 535px) {
        .row {
            margin: 0 !important;
        }
    }
    @media (max-width: 435px) {
        .container .content-container .order-history-view .customer-info {
            grid-template-columns: 1fr;
        }
    }
    @media (max-width: 420px) {
        .justify-content-center {
            justify-content: center !important;
            > div:nth-child(2) {
                margin-top: 20px;
            }
        }
    }
}

.pagination {
    li {
        a {
            padding: 5px 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 25px;

            &.active {
                color: $lGreen;
            }
        }
    }
}
