.home {
    background-color: $lYellow;
    position: relative;

    .position-relative {
        position: relative;
        z-index: 10;
    }

    .floating-leaf {
        position: absolute;
        pointer-events: none;

        &.left {
            left: 0;

            &-top {
                top: 0;
                transform: translateX(5px);
            }

            &-vine {
                top: 0;
                transform: translate(-20%, -3%);
            }

            &-wax {
                transform: translate(-68%, 138%);
            }

            &-leaf {
                transform: translate(-21%, 4%);
            }

            &-slider-leaf {
                bottom: 50%;
                transform: translate(-67%, 33%);
            }

            &-slider-lower {
                top: 100%;
                transform: translate(10%, -55%);
            }

            &-wave {
                transform: translate(-40%, 0);
                mix-blend-mode: screen;
            }

            &-wave2 {
                transform: translate(-50%, -13%);
            }
        }

        &.right {
            right: 0;

            &-top {
                top: 0;
                transform: translateX(-5px);
            }

            &-vine {
                top: 0;
                transform: translate(20%, -3%);
            }

            &-wax {
                transform: translate(64%, 160%);
            }

            &-leaf {
                transform: translate(20.2%, 4%);
            }

            &-slider-leaf {
                bottom: 50%;
                transform: translate(67%, 33%);
            }

            &-slider-lower {
                top: 100%;
                transform: translate(-10%, -55%);
            }

            &-wave {
                transform: translate(40%, 0);
                mix-blend-mode: screen;
            }

            &-wave2 {
                transform: translate(53%, -13%);
            }
        }

        &.faded {
            opacity: 0.3;

            &-light {
                opacity: 0.7;
            }

            &-9 {
                opacity: 0.9;
            }
        }
    }

    .landscape {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -32%);
    }

    .steps {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        text-align: center;
        margin-top: 30px;
        padding: 0 50px;

        > div {
            padding: 10px;
        }

        > div:nth-child(2) {
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
        }
    }
    .featured-collection {
        .banner-js {
            .txt-oversized {
                font-size: 70px !important;
                line-height: 86px;
            }
            .txt-large {
                line-height: 30px;
            }
        }
    }

    .whats-inside {
        .container {
            .image {
                width: 150px;
            }
            > div {
                > :nth-child(even) {
                    transform: translateY(50%);
                }
                p {
                    line-height: 21px;
                }
            }
        }
    }

    .our-mission {
        position: relative;

        .container {
            .description {
                max-width: 650px;
                margin: auto;
                // line-height: 2rem;
                line-height: 30px;

                &.items {
                    max-width: none;
                    margin-top: 20px;

                    .item {
                        min-height: 450px;
                        border-radius: 20px;
                        > div > p {
                            line-height: 24px !important;
                        }

                        &:after {
                            content: "";
                            background: transparent
                                linear-gradient(
                                    180deg,
                                    #00000000 0%,
                                    rgba(0, 0, 0, 0.5) 100%
                                )
                                0% 0% no-repeat padding-box;
                            position: absolute;
                            top: 0;
                            right: 0;
                            left: 0;
                            bottom: 0;
                            border-radius: 20px;
                        }

                        > * {
                            max-width: 400px;
                            margin-left: 20px;
                            margin-right: 20px;
                            line-height: 1.5rem;
                            position: relative;
                            z-index: 1;
                        }
                    }

                    .slick-dots {
                        bottom: 15px;

                        li {
                            button {
                                background-color: $green;
                                border-color: $green;
                            }

                            &.slick-active,
                            &:hover {
                                button {
                                    background-color: $lGreen;
                                    border-color: $lGreen;
                                    transform: scale(1);
                                    opacity: 0.9;
                                }
                            }
                        }
                    }
                }
            }

            hr {
                margin-top: 1rem;
                margin-bottom: 0;
                border: 0;
                height: 50px;
                border-left: 2px dashed $lGreen;
                width: 0;
            }
        }
    }
    .txt-shadow {
        text-shadow: 0px 3px 6px #00000029;
    }
    .txt-14-px {
        font-size: 14px !important;
    }

    .section-5 {
        > div {
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;

            > div {
                background-color: $green;
                padding: 50px;
                border-radius: 150px;
                display: grid;
                width: 100%;
                min-height: 270px;
                grid-template-columns: 40% 1fr;
                grid-gap: 50px;

                .image-container {
                    img {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        max-width: 100%;
                        max-height: 350px;
                    }
                }

                .content-container {
                    h2 {
                        line-height: 48px;
                        color: $white;
                    }

                    > div {
                        background-color: $lYellow;
                        border-radius: 25px;
                        max-height: 40px;
                        padding: 5px 20px;
                        max-width: 100%;

                        a {
                            img {
                                max-height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .our-journal {
        .items {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;
            grid-template-areas:
                "main side1"
                "main side2";

            .item {
                .txt-large {
                    line-height: 24px;
                }
                .txt-medium {
                    font-size: 14px !important;
                    line-height: 21px;
                }
                &:first-child {
                    grid-area: main;

                    > img {
                        height: 240px;
                    }

                    > div {
                        margin-top: 10px;
                    }
                }

                &:nth-child(2) {
                    grid-area: side1;
                }

                &:nth-child(2) {
                    grid-area: side2;
                }

                &:not(:first-child) {
                    display: grid;
                    grid-template-columns: 200px 1fr;
                    grid-gap: 10px;

                    > img {
                        height: 150px;
                    }
                }

                > img {
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 8px;
                }
            }
        }
    }

    .section-7 {
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent
                linear-gradient(180deg, #00000000 0%, rgba(0, 0, 0, 0.8) 100%)
                0% 0% no-repeat padding-box;
        }

        .container {
            color: $white;
            min-height: 430px;
            position: relative;
            z-index: 1;

            div {
                line-height: 72px;
                p {
                    margin-bottom: 0;
                }
            }
            @media (max-width: 360px) {
                div {
                    font-size: 45px !important;
                }
            }
        }
    }

    @media (max-width: 1999px) {
        .section-5 > div > div {
            grid-gap: 35px;
        }
        .section-5 > div > div .content-container .available {
            max-height: 100%;
            flex-wrap: wrap;
        }
    }

    @media (max-width: 991px) {
        .page-container {
            margin-top: 10px;
            padding-top: 0;
        }

        .featured-collection {
            .banner-js {
                .main-banner {
                    height: auto;
                }

                .banner-footer {
                    grid-template-columns: 1fr;

                    .next-preview {
                        justify-content: center;
                    }
                }

                .link-to-collection {
                    text-align: center !important;
                    margin-bottom: 20px;
                }
            }
        }

        .grid-2 {
            grid-template-columns: 1fr;
        }
        .whats-inside .container > div > :nth-child(even) {
            transform: translateY(0);
        }
        .grid-gap-50-20 {
            grid-gap: 20px;
        }
        .section-5 > div > div .image-container img {
            max-height: 300px;
        }
        .our-journal .items .item:not(:first-child) {
            grid-template-columns: 160px 1fr;
        }
    }

    @media (max-width: 767px) {
        .steps {
            grid-template-columns: 1fr;

            > div {
                border: 1px solid #ccc;
                border-radius: 5px;
                margin-top: 10px;
            }
        }
        .our-journal .items {
            grid-template-areas:
                "main main"
                "side1 side2";
        }
    }

    @media (max-width: 700px) {
        .our-journal .items {
            grid-template-areas:
                "main main"
                "side1 side1"
                "side2 side2";
            .item {
                &:first-child {
                    grid-area: main;

                    > img {
                        height: 240px;
                    }

                    > div {
                        margin-top: 10px;
                    }
                }

                &:nth-child(2) {
                    grid-area: side1;
                }

                &:nth-child(2) {
                    grid-area: side1;
                }

                &:nth-child(3) {
                    grid-area: side2;
                }

                &:nth-child(3) {
                    grid-area: side2;
                }
            }
        }

        .section-5 > div > div {
            grid-template-columns: 1fr;
        }

        .section-5 .image-container {
            height: 100px;
            img {
                transform: translate(-50%, 0);
                left: 50%;
                max-height: 230px;
            }
        }
        .section-5 .content-container {
            text-align: center;
            align-items: center;
            .flex-wrap {
                flex-wrap: wrap;
            }
            .justify-content-center {
                justify-content: center !important;
            }
        }
    }

    @media (max-width: 550px) {
        .featured-products {
            grid-template-columns: 1fr 1fr;

            .featured-product-card {
                height: 50vw;

                .flex-end {
                    .btn {
                        font-size: 10px;
                    }
                }
            }
        }
        .section-5 > div > div .image-container img {
            max-height: 230px;
        }
        .our-journal .items .item:not(:first-child) {
            grid-template-columns: 40% 1fr;
        }
    }
    @media (max-width: 530px) {
        .floating-leaf.faded-light {
            opacity: 0.5;
        }
        .floating-leaf.left-leaf {
            transform: translate(-21%, 19%);
        }
        .floating-leaf.right-leaf {
            transform: translate(20.2%, 19%);
        }
        .floating-leaf.right-wax {
            transform: translate(64%, 218%);
        }
        .floating-leaf.left-wax {
            transform: translate(-68%, 191%);
        }
        .floating-leaf.left-vine {
            transform: translate(-20%, 21%);
        }
        .floating-leaf.right-vine {
            transform: translate(20%, 21%);
        }
    }

    @media (max-width: 500px) {
        .grid-2-auto-1fr {
            grid-template-columns: 1fr;
            justify-items: center;
        }
        .whats-inside .container > div .grid img {
            max-width: 150px;
        }
        .whats-inside .container > div .grid .txt-medium {
            text-align: center;
        }
    }
    @media (max-width: 400px) {
        .our-journal .items .item:not(:first-child) > img {
            height: 120px;
        }
    }
    @media (max-width: 340px) {
        .section-5 > div > div {
            padding: 40px;
        }
    }
}
