.checkout-page {
    min-height: calc(100vh - 390px + 78px - 100px);

    .flex-wrap {
        flex-wrap: wrap;
    }

    .txt-12-px {
        font-size: 12px !important;
    }

    .txt-14-px {
        font-size: 14px !important;
    }

    .container {
        max-width: 970px;

        .breadcrumbs {
            color: $green;

            span,
            a {
                font-family: $cerapro-medium;

                &.active {
                    color: $lGreen;
                }
            }
        }

        .order-summary {
            background-color: $lYellow;
            padding: 15px 10px;
            border-radius: 4px;

            .items {
                .item {
                    display: grid;
                    grid-template-columns: 60px 1fr;
                    gap: 10px;

                    img {
                        width: 100%;
                        height: auto;
                        object-position: center;
                        object-fit: contain;
                    }

                    .quantity-control {
                        display: flex;

                        .btn {
                            height: 32px;
                            width: 37px;
                            border-radius: 7px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 0;
                        }

                        input {
                            border: none;
                            background: none;
                            width: 70px;
                            text-align: center;

                            &:focus {
                                outline: none;
                            }
                        }
                    }
                }
            }

            .voucher-container {
                display: flex;
                background-color: $white;
                border: 1px solid $lightGray;
                border-radius: 9px;
                padding: 1px;

                input {
                    padding: 5px;
                    background: none;
                    border: none;
                    width: 100%;
                }

                .btn {
                    border-radius: 9px;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 68px;
                    height: 36px;
                    padding: 10px 32px;
                }
            }
        }

        .payment {
            .billing-container {
                outline: 1px solid #cbcbcb;
                outline-offset: -1px;

                .billing-form {
                    max-height: 0;
                    transition: max-height 0.8s cubic-bezier(0, 1, 0, 1);
                    overflow: hidden;

                    &.active {
                        max-height: 820px;
                        transition: max-height 0.5s ease-in-out;
                    }
                }
            }
        }
    }

    @media (max-width: 767px) {
        .payment-row {
            flex-direction: column-reverse;
        }
    }
}

.shipping-summary-item {
    display: flex;
    border: 1px solid $lighterGray;
    padding: 8px 20px;

    .title {
        width: 100%;
        max-width: 100px;
        color: $lightMedGray;
    }

    .content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    .action {
        overflow: hidden;
        color: $lGreen;
        width: 100%;
        max-width: 85px;
        background: none;
        border: none;
        padding: 0;
        text-align: right;
    }
}

.select-method {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border: 1px solid $lighterGray;

    .icon-holder {
        box-shadow: inset 0 0 2px $lGreen;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            transform: scale(0);
            transition: 0.3s;
            color: $lGreen;
            font-size: 20px;
        }
    }
    @media (max-width: 384px) {
        .title{
            max-width: 100px
        }
    }
    @media (max-width: 322px) {
        .title{
            max-width: 95px
        }
    }

    .img-right {
        height: 24px;

        img {
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }

    &.active {
        .icon-holder {
            i {
                transform: scale(1);
            }
        }
    }

    @media (max-width: 382px) {
        .title {
            max-width: 100px;
        }

        .img-right {
            height: 20px;
        }
    }
}
