.elements {
    &.free-shipping-container {
        display: flex;
        color: $green;
        > i {
            font-size: 26px;
            align-self: center;
        }
        .text {
            display: flex;
            flex-direction: column;
            font-size: 12px;
            padding: 5px 10px;
            .main {
                font-family: $quicksand-bold;
                font-size: 14px;
                line-height: 14px;
            }
        }
    }
}

.skeleton {
    display: block;
    min-height: 10px;
    width: 100%;
    position: relative;
    background-color: $lightGray;
    opacity: 0.95;
    overflow: hidden;

    &.center {
        margin-left: auto;
        margin-right: auto;
    }

    &.clear {
        opacity: 0.5;
    }

    &::before {
        animation-duration: 4s;
        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 50%;
        z-index: 1;
        width: 500%;
        margin-left: -250%;
        -webkit-animation: phAnimation 2s linear infinite;
        animation: phAnimation 2s linear infinite;
        background: linear-gradient(
                to right,
                rgba(255, 255, 255, 0) 46%,
                rgba(255, 255, 255, 0.35) 50%,
                rgba(255, 255, 255, 0) 54%
            )
            50% 50%;
    }

    &.width {
        &-50 {
            width: 50px;
        }
    }
}

.custom-dropdown-select {
    padding: 5px 10px;
    border-radius: 100px;
    border: 1px solid #d6d6d6;
    background: #f7f7f7;

    .selection {
        position: absolute;
        left: 0;
        top: 100%;
        border-radius: 10px;
        border: 1px solid #d6d6d6;
        background: #f7f7f7;
        padding: 5px 0;
        min-width: 100%;
        opacity: 0;
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
        pointer-events: none;
        transition: 0.3s;
        z-index: 2;

        .select-option {
            padding: 0 10px;
        }
    }

    &.open {
        .selection {
            pointer-events: auto;
            -webkit-transform: translateY(5px);
            transform: translateY(5px);
            opacity: 1;
        }
    }
}
