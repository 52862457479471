.testimonial-section {
    color: black;

    .slick-arrow {
        font-size: $xlarge;
        color: $green;
        font-size: 32px;
        width: auto;
        height: auto;

        &::before {
            display: none;
        }
    }

    .items-container {
        padding: 20px;

        .slick-active {
            padding-left: 5px;
            padding-right: 5px;
        }

        .item {
            background-color: $white;
            position: relative;
            padding: 110px 20px 30px;
            border-radius: 40px;

            opacity: 0.4;
            transform: scale(0.9);
            transition: all 0.3s ease;
            margin-top: 100px;

            .txt-small {
                font-size: 14px !important;
                line-height: 21px;
            }

            h5 {
                font-size: 12px !important;
            }

            .txt-18-px {
                font-size: 18px !important;
            }
            img {
                max-width: 100%;
                position: absolute;
                top: 0;
                left: 50%;
                max-height: 200px;
                max-width: 100%;
                transform: translate(-50%, -50%);
            }

            &.active {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
}
