.journals {
    background-color: $lYellow;
    position: relative;
    overflow: hidden;

    .txt-shadow {
        text-shadow: 0px 3px 6px #00000029;
    }
    .banner {
        position: relative;
        min-height: 400px;
        color: $white;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        p {
            margin-bottom: 0;
        }
        .content {
            line-height: 72px;
            min-height: 250px;
            display: flex;
            flex-flow: column;
            justify-content: flex-end;
            @media (max-width: 360px) {
                div {
                    font-size: 45px !important;
                }
            }
        }
        .container {
            position: relative;
        }
    }

    .end-section {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent
                linear-gradient(180deg, #00000000 0%, rgba(0, 0, 0, 0.8) 100%)
                0% 0% no-repeat padding-box;
        }
    }
    .start-section {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0.1;
            background: #164e36;
        }
        @media (max-width: 360px) {
            .container {
                font-size: 45px !important;
            }
        }
    }

    .txt-shadow {
        text-shadow: 0px 3px 6px #00000029;
    }

    .green {
        font-size: 17px !important;
        color: #1c5b44;
    }
    .journal-content {
        h2 {
            line-height: 70px;
        }
        h3 {
            font-size: 17px !important;
        }
        img {
            width: 100%;
        }
        .content {
            line-height: 30px;
            max-width: 85%;
        }
    }

    .with-border-bottom {
        border-bottom: 1px solid $lightMedGray;
    }

    .go-back {
        display: flex;
        align-items: center;
        img {
            margin-right: 10px;
        }
        .back {
            margin-bottom: 0;
        }
    }

    .btn-no-style {
        border: none;
        background: none;
    }

    &-container {
        @include commonBackground();
        padding-left: 0;
        padding-right: 0;
        margin-top: 50px;
    }

    .floating-leaf {
        position: absolute;
        pointer-events: none;

        &.left {
            left: 0;

            &-top {
                top: 0;
                transform: translateX(5px);
            }

            &-vine {
                top: 0;
                transform: translate(-20%, -3%);
            }

            &-wax {
                transform: translate(-68%, 138%);
            }

            &-leaf {
                transform: translate(-21%, 4%);
            }

            &-slider-leaf {
                bottom: 50%;
                transform: translate(-67%, 33%);
            }

            &-slider-lower {
                top: 100%;
                transform: translate(10%, -55%);
            }

            &-wave {
                transform: translate(-40%, 0);
                mix-blend-mode: screen;
            }

            &-wave2 {
                transform: translate(-50%, -13%);
            }
        }

        &.right {
            right: 0;

            &-top {
                top: 0;
                transform: translateX(-5px);
            }

            &-vine {
                top: 0;
                transform: translate(20%, -3%);
            }

            &-wax {
                transform: translate(64%, 160%);
            }

            &-leaf {
                transform: translate(20.2%, 4%);
            }

            &-slider-leaf {
                bottom: 50%;
                transform: translate(67%, 33%);
            }

            &-slider-lower {
                top: 100%;
                transform: translate(-10%, -55%);
            }

            &-wave {
                transform: translate(40%, 0);
                mix-blend-mode: screen;
            }

            &-wave2 {
                transform: translate(53%, -13%);
            }
        }

        &.faded {
            opacity: 0.3;

            &-light {
                opacity: 0.7;
            }

            &-9 {
                opacity: 0.9;
            }
        }
    }

    .journals-section {
        .latest-news {
            position: relative;
            .floating-leaf {
                position: absolute;
                pointer-events: none;
                // bottom: 0;

                &.left {
                    left: 0;
                    &-upper {
                        transform: translate(-40%, -40%);
                    }
                    &.faded {
                        &-3 {
                            opacity: 0.3;
                        }
                    }
                }

                &.right {
                    right: 0;
                    &-upper {
                        transform: translate(40%, -40%);
                    }
                    &.faded {
                        &-3 {
                            opacity: 0.3;
                        }
                    }
                }
            }
        }
        .journal-view {
            .floating-leaf {
                &.left {
                    left: 0;
                    &-upper {
                        transform: translate(-70%, -40%);
                    }
                }

                &.right {
                    right: 0;
                    &-upper {
                        transform: translate(70%, -40%);
                    }
                }
            }
        }

        .journals-grid,
        .journals-grid-view {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-column-gap: 20px;
            grid-row-gap: 30px;
            .title {
                font-size: 17px !important;
            }
            .date {
                font-size: 14px !important;
            }
            .all-caps {
                text-transform: uppercase;
            }
            .journal {
                position: relative;
                z-index: 1;

                .no-wrap {
                    white-space: nowrap;
                    overflow: hidden;
                }
                .img-container {
                    position: relative;
                    width: 100%;
                    height: 250px;
                    margin-bottom: 20px;
                    overflow: hidden;
                    .on-hover {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 10;
                        opacity: 0;
                        transition: all 0.35s ease;
                    }
                    .background-overlay {
                        background-color: black;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        z-index: 5;
                        opacity: 0;
                        transition: all 0.35s ease;
                    }
                    img {
                        position: absolute;
                        width: 100%;
                        height: 250px;
                        object-fit: cover;
                        object-position: center;
                        transition: all 0.35s ease;
                    }
                }
                &:hover {
                    .img-container {
                        .on-hover {
                            opacity: 1;
                        }
                        .background-overlay {
                            opacity: 0.2;
                        }
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
        .btn-load {
            // font-weight: 500;
            display: flex;
            margin: auto;
            font-size: $regular;
            border: none;
            border-radius: 25px;
            padding: 10px 45px;
            background: $lGreen;
            // color: $dirtyWhite;
            max-width: 200px;
        }
    }

    .with-top-margin {
        margin-top: 20px;
    }

    .section-5 {
        > div {
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;

            > div {
                background-color: $green;
                padding: 50px;
                border-radius: 150px;
                display: grid;
                width: 100%;
                min-height: 270px;
                grid-template-columns: 40% 1fr;
                grid-gap: 50px;

                .image-container {
                    img {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        max-width: 100%;
                        // max-height: 230px;
                    }
                }

                .content-container {
                    h2 {
                        line-height: 48px;
                        color: $white;
                    }

                    > div {
                        background-color: $lYellow;
                        border-radius: 25px;
                        max-height: 40px;
                        padding: 5px 20px;
                        max-width: 90%;

                        a {
                            img {
                                max-height: 100%;
                            }
                        }
                    }
                }
            }
        }

        .btn-shop {
            width: 120px;
            border: 1px solid $lYellow;
            border-radius: 25px;
            background: $lYellow;
            color: $green;
            padding: 10px 0;
            text-align: center;
        }
    }

    @media (max-width: 991px) {
        &-container {
            display: block;
            padding: 20px 50px;

            > div {
                padding: 0;
            }
        }
        .journals-section .journals-grid {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        .journals-section .journals-grid-view {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        .journal-content {
            .content {
                max-width: 100%;
            }
        }
        .section-5 > div > div .image-container img {
            max-height: 300px;
        }
        .journals-section .journals-grid-view .journal {
            &:nth-child(3) {
                transform: translateX(50%);
            }
        }
    }

    @media (max-width: 767px) {
        &-container {
            display: block;
            padding: 20px;

            > div {
                padding: 0;
            }
        }
        .floating-leaf.left-bottom {
            transform: translate(-22%, 23%);
        }
        .floating-leaf.right-bottom {
            transform: translate(22%, 23%);
        }
    }

    @media (max-width: 700px) {
        .section-5 > div > div {
            grid-template-columns: 1fr;
        }

        .section-5 .image-container {
            height: 100px;
            img {
                transform: translate(-50%, 0);
                left: 50%;
                max-height: 230px;
            }
        }

        .section-5 .content-container {
            text-align: center;
            align-items: center;
        }
    }

    @media (max-width: 575px) {
        .journals-section .journals-grid {
            grid-template-columns: 1fr;
        }
        .section-5 > div > div .image-container img {
            max-height: 230px;
        }
        .journals-section .journals-grid-view {
            grid-template-columns: 1fr;
        }
        .journals-section .journals-grid .journal .no-wrap {
            white-space: unset !important;
        }
        .journals-section .journals-grid-view .journal {
            &:nth-child(3) {
                transform: translateX(0);
            }
        }
    }
}

.journal-leaf {
    &.floating-leaf {
        position: absolute;
        pointer-events: none;

        &.left {
            left: 0;

            &-top {
                top: 0;
                transform: translateX(5px);
            }

            &-vine {
                top: 0;
                transform: translate(-20%, -3%);
            }

            &-wax {
                transform: translate(-68%, 138%);
            }

            &-leaf {
                transform: translate(-21%, 4%);
            }

            &-slider-leaf {
                bottom: 50%;
                transform: translate(-67%, 33%);
            }

            &-slider-lower {
                top: 100%;
                transform: translate(10%, -55%);
            }

            &-wave {
                transform: translate(-40%, 0);
                mix-blend-mode: screen;
            }

            &-wave2 {
                transform: translate(-50%, -13%);
            }
        }

        &.right {
            right: 0;

            &-top {
                top: 0;
                transform: translateX(-5px);
            }

            &-vine {
                top: 0;
                transform: translate(20%, -3%);
            }

            &-wax {
                transform: translate(64%, 160%);
            }

            &-leaf {
                transform: translate(20.2%, 4%);
            }

            &-slider-leaf {
                bottom: 50%;
                transform: translate(67%, 33%);
            }

            &-slider-lower {
                top: 100%;
                transform: translate(-10%, -55%);
            }

            &-wave {
                transform: translate(40%, 0);
                mix-blend-mode: screen;
            }

            &-wave2 {
                transform: translate(53%, -13%);
            }
        }

        &.faded {
            opacity: 0.3;

            &-light {
                opacity: 0.7;
            }

            &-3 {
                opacity: 0.3;
            }

            &-9 {
                opacity: 0.9;
            }
        }
    }
}
