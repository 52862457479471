.main-navbar {
    .nav-fixed {
        // position:fixed;
        width: 100%;
        z-index: 99999;
    }

    .nav-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: none;
        cursor: pointer;
        z-index: 1;
        &.open {
            display: block;
        }
    }

    .mobile-search-container {
        display: none;
        padding: 10px;
        background: $orange;

        > .search-container {
            background: #fff;
        }
    }

    .nav-disclaimer {
        background: $defaultGradient;
        color: white;
        padding: 30px;
        position: relative;

        img {
            position: absolute;
            width: 400px;
            height: 100%;
            object-fit: contain;
            object-position: right bottom;
            right: 82px;
            top: 0;
        }
    }

    .navbar {
        background-color: #fff;
        padding: 0;
        transition: $transitionFast;

        .user-indicator {
            position: absolute;
            right: 0;
            top: 0;
            transform: translateX(20%) translateY(-20%);
            background: #fff;
            color: $red !important;
            pointer-events: none;
        }

        .hamburger {
            width: 22px;
            height: 15px;
            position: relative;
            transform: rotate(0deg);
            transition: 0.5s ease-in-out;
            cursor: pointer;
            z-index: 1;
            // margin-right: 30px;
            margin-right: 15px;
            // margin-left: auto;
            // display: none;

            span {
                display: block;
                background-color: $lGreen;
                border-radius: 50px;
                position: absolute;
                height: 3px;
                width: 100%;
                opacity: 1;
                left: 0;
                transform: rotate(0deg);
                transition: 0.25s ease-in-out;

                &:nth-child(1) {
                    top: 0px;
                }
                &:nth-child(2),
                &:nth-child(3) {
                    top: 7px;
                }
                &:nth-child(3) {
                    top: 14px;
                }
            }
        }

        .leaf {
            position: absolute;
            top: 0;
            height: 100%;
            opacity: 0.4;
            max-width: none !important;
            width: auto !important;

            &-left {
                left: 0;
            }

            &-right {
                right: 0;
            }
        }

        .container {
            max-width: 95%;
            width: 100%;
            padding: 15px 0;
            position: relative;

            > * {
                display: flex;
                align-items: center;
                align-self: center;
            }

            .auto-suggest-container {
                transition: $transitionFast;
                opacity: 0;
                transform: translateY(-30px);

                &.show {
                    transform: translateY(0px);
                    opacity: 1;
                }

                .dismiss-search {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    display: none;
                }

                .mobile-label {
                    display: none;
                }
            }

            .search-container {
                // margin-right: 60px;
                min-width: 500px;
                width: 100%;
            }

            .navbar-link {
                margin: 0;
                padding: 0;
                font-size: 1rem;
                margin-left: 20px;
                font-family: $poppins-light;
                position: relative;
                color: $white;

                &:nth-child(2) {
                    margin-left: 40px;
                }

                &::after {
                    content: "";
                    position: absolute;
                    bottom: -14px;
                    left: 50%;
                    width: 100%;
                    max-width: 0;
                    transform: translateX(-50%);
                    height: 6px;
                    border-radius: 10px;
                    opacity: 0;
                    background-color: $lGreen;
                    transition: max-width 0.3s ease;
                }

                &:hover,
                &.active {
                    &::after {
                        max-width: 50px;
                        opacity: 1;
                    }
                }
            }

            .navbar-header {
                .navbar-brand {
                    margin: 0;
                    padding: 0;

                    img {
                        max-height: 47px;
                        // max-width: none;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }

            .navbar-right {
                .navbar-link {
                    margin-left: 0;
                    margin-right: 20px;

                    .fa-user {
                        font-size: 12px;
                        transform: translateY(-11%);
                    }

                    .fa-circle {
                        font-size: 8px;
                        transform: translateY(-35%);
                        color: $lGreen;
                    }
                }
            }

            @media (max-width: 430px) {
                .navbar-right {
                    justify-content: space-between;
                }
            }

            .nav--search-btn {
                display: none;
            }
        }

        .nav-stickout {
            position: fixed;
            left: 0;
            top: 0;
            background: #fff;
            width: 100%;
            padding: 0;
            z-index: 100;
            transform: translateY(-100%);
            transition: $transitionFast;

            img {
                // max-width: 100px;
                // width: 100%;
            }

            .container {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                padding: 10px;

                .navbar-header {
                    height: 40px;
                }
            }
        }
    }

    .profile-dropdown {
        button {
            background: none;
            border: none;

            &:active {
                background: none !important;
                box-shadow: none !important;
                border: none !important;
                transform: none !important;
            }
        }

        ul {
            padding: 0 15px;

            li {
                a {
                    display: block;
                    padding: 5px 0;
                }

                &:last-of-type {
                    a {
                        border-top: 1px solid $lighterGray;
                    }
                }
            }
        }
    }

    .nav-sidebar-container {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 999999;
        transition: 0.3s;
        transition-delay: 0.3s;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        color: $black;
        transform: translateX(-100%);
        opacity: 0;
        color: $black;

        .link-container {
            background: $lYellow;
            min-width: 300px;
            max-width: 400px;
            max-height: 100%;
            width: 100%;
            height: 100%;
            box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.2);
            transition: 0.3s;
            transform: translateX(-100%);
        }

        .action-container {
            min-height: 30px;

            .customer-details {
                box-shadow: inset 0 -10px 10px rgba(0, 0, 0, 0.1);
                text-align: center;
                background: rgb(22, 78, 54);
                color: $white;

                img {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    border-radius: 100%;
                }
            }

            .sidenav-toggle-btn {
                position: absolute;
                right: 10px;
                top: 10px;
                color: $white;

                i {
                    font-size: 18px;
                }
            }
        }

        .menu-list {
            overflow: auto;
            position: relative;

            ul {
                li {
                    font-size: 14px;
                    font-family: $cerapro-bold;
                    position: relative;

                    a {
                        position: relative;
                        display: block;
                        z-index: 2;
                        padding: 12px 50px;
                    }
                }
            }

            > ul {
                li {
                    a {
                        position: relative;
                        transition: $transitionFast;

                        &:after {
                            content: "";
                            bottom: 0;
                            left: 0;
                            height: 100%;
                            width: 8px;
                            background-color: $green;
                            position: absolute;
                            transform: translateX(-100%);
                            transition: $transitionFast;
                            color: #fff;
                        }

                        &.active,
                        &:hover {
                            color: $green;

                            &:after {
                                transform: translateX(0);
                            }
                        }
                    }
                }
            }

            .mobile-linkouts {
                // background: $defaultGradient;
                border-top: 1px solid #ccc;
            }
        }

        .quick-links {
            border-top: 1px solid #dfdfdf;

            a {
                display: block;
                color: #9d9d9d;
                padding: 12px 50px;
                transition: $transitionFast;

                &:hover {
                    color: $black;
                }
            }
        }

        .click-out {
            position: fixed;
            right: 0;
            width: calc(100vw - 400px);
            height: 100%;
        }

        &.open {
            transform: translateX(0);
            opacity: 1;
            transition: opacity 0.3s;
            transition-delay: 0s;

            .link-container {
                transition-delay: 0.3s;
                transform: translateX(0);
            }
        }
    }

    &.moved {
        .navbar {
            .nav-stickout {
                transform: translateY(0);
                box-shadow: 0 0px 12px 8px rgba(0, 0, 0, 0.3);
            }
        }
    }

    @media (max-width: 1450px) {
        .nav-disclaimer {
            img {
                right: 0;
            }
        }

        .nav-links {
            padding: 0;

            .link {
                padding: 10px;
            }
        }
    }

    @media (max-width: 991px) {
        .nav-disclaimer {
            img {
                width: 200px;
            }
        }
    }

    @media (max-width: 767px) {
        .navbar {
            .container {
                // grid-template-columns: auto auto 1fr auto auto;
                grid-gap: 5px;
                margin: auto;
                width: 95%;
                max-width: 100%;
                padding: 10px;

                .modal-registration-button,
                .checkout-btn,
                .line-separator,
                .modal-login-btn-container,
                .nav--checkout-btn {
                    display: none;
                }

                .auto-suggest-container,
                .auto-suggest-container.show {
                    position: fixed;
                    width: 100%;
                    height: 100%;
                    background: #fff;
                    left: 0;
                    top: 0;
                    z-index: 10;
                    display: flex;
                    align-items: center;
                    padding: 20px;
                    transform: translateY(-20px);
                    opacity: 0;
                    pointer-events: none;

                    .search-container {
                        // padding: 5px 10px;
                        position: relative;
                    }

                    .dismiss-search,
                    .mobile-label {
                        display: block;
                    }

                    &.mobile-show {
                        transform: translateY(0);
                        opacity: 1;
                        pointer-events: auto;
                    }
                }
            }
        }
    }

    @media (max-width: 500px) {
        .mobile-search-container {
            display: none;
        }

        .nav-disclaimer {
            img {
                width: 200px;
            }
        }

        .navbar {
            .container {
                // grid-template-columns: auto 1fr auto auto;
                grid-gap: 10px;

                .search-container {
                    // display: none;
                    min-width: 0;
                }

                .nav--search-btn {
                    display: block;
                }
            }
        }
    }

    @media (max-width: 420px) {
        .navbar {
            .container {
                .modal-login-button {
                    i {
                        font-size: 20px;
                        margin: 0;
                    }

                    span {
                        display: none;
                    }
                }
            }
        }
    }
}

@media (max-width: 355px) {
    .main-navbar .navbar .container .navbar-header .navbar-brand img {
        // width: 115px;
        max-height: 35px;
    }
}

.cart-dropdown {
    button {
        background: none;
        border: none;

        &:active {
            background: none !important;
            box-shadow: none !important;
            border: none !important;
            transform: none !important;
        }
    }
    .nav-circle-container{
        i{
            font-size: 18px;
        }
        .cart-indicator {
            position: absolute;
            top: 2px;
            right: 15px;
            border: 1px solid $white;
            border-radius: 100%;
            height: 12px;
            width: 12px;
            background-color: $redPastel;
        }
    }

    .dropdown-menu{
        transform: translate3d(-90px, 42px, 0px) !important;
        &.show{
            width: 300px !important;
        }
    }
}


.cart-items{
    .cart-container{
        padding: 10px;
        .mb-10{
            margin-bottom: 10px;
        }
        .product-details{
            display: flex;
            justify-content: space-between;
        }
        .item{
            border: 1px solid $lightGray;
            padding: 5px;
            img{
                margin-top: 24px;
                height: 80px;
                width: 80px;
            }
            .delete-item{
                text-align: right;
                cursor: pointer;
            }
        }
        .item-details{
            display: flex;
            justify-content: space-between;
        }
    }
    .cart-popup-footer{
        padding: 0 10px 10px 10px;
        .price-container{
            display: flex;
            justify-content: space-between;
        }
        .btn-container{
            margin-top: 5px;
            text-align: right;
        }
    }
}

// .nav-circle-container {
//     width: 33px;
//     height: 33px;
//     border: 0;
//     border-radius: 100%;
//     text-align: center;
//     cursor: pointer;
    
//     &.cart {
//         color: $white;
//         position: relative;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         background-color: $primary;

//         .cart-indicator {
//             position: absolute;
//             top: -3px;
//             right: -4px;
//             border: 1px solid $white;
//             border-radius: 100%;
//             height: 12px;
//             width: 12px;
//             background-color: $secondary;
//         }
//     }
//     img {
//         border: 0;
//         border-radius: 100%;
//         object-fit: cover;
//         object-position: center;
//         height: 100%;
//         width: 100%;
//     }
// }