.auto-suggest {

    &.search-container {
        display: flex;
        align-items: center;
        border-radius: 13px;
        border: solid 1px $lightGray;
        overflow: hidden;

        > i {
            color: $green;
            margin-left: 15px;
        }

        button {
            border-radius: 0;
        }

        input {
            border: 0;
            height: 100%;
            width: 100%;
            padding: 10px 15px;

            &:focus {
                box-shadow: none;
            }
        }
    }
}