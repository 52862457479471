.floating-slider {
    position: relative;
    overflow: hidden;
    color: $white;

    .white-bottom {
        content: "";
        position: absolute;
        height: 185px;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: $lYellow;

        img {
            transform: translate(-50%, -45%);
        }
    }

    .items {
        max-width: none;
        margin-top: 20px;

        .slick-slider {
            border-radius: 20px;
            overflow: hidden;
        }

        .item {
            min-height: 450px;
            border-radius: 20px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            > div > p{
                line-height: 24px !important;
            }

            &:after {
                content: "";
                background: transparent
                    linear-gradient(
                        180deg,
                        #00000000 0%,
                        rgba(0, 0, 0, 0.1) 100%
                    )
                    0% 0% no-repeat padding-box;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                border-radius: 20px;
            }

            > * {
                max-width: 400px;
                margin-left: 20px;
                margin-right: 20px;
                line-height: 1.5rem;
                position: relative;
                z-index: 1;
            }
        }

        .slick-dots {
            bottom: 15px;

            li {
                button {
                    background-color: $green;
                    border-color: $green;
                }

                &.slick-active,
                &:hover {
                    button {
                        background-color: $lGreen;
                        border-color: $lGreen;
                        transform: scale(1);
                        opacity: 0.9;
                    }
                }
            }
        }
    }

    .floating-leaf {
        position: absolute;
        pointer-events: none;

        &.left {
            left: 0;

            &-top {
                top: 0;
                transform: translateX(5px);
            }

            &-vine {
                top: 0;
                transform: translate(-20%, -3%);
            }

            &-wax {
                transform: translate(-68%, 138%);
            }

            &-leaf {
                transform: translate(-21%, 4%);
            }

            &-slider-leaf {
                bottom: 50%;
                transform: translate(-67%, 33%);
            }

            &-slider-lower {
                top: 100%;
                transform: translate(10%, -55%);
            }

            &-wave {
                transform: translate(-40%, 0);
                mix-blend-mode: screen;
            }

            &-wave2 {
                transform: translate(-50%, -13%);
            }
        }

        &.right {
            right: 0;

            &-top {
                top: 0;
                transform: translateX(-5px);
            }

            &-vine {
                top: 0;
                transform: translate(20%, -3%);
            }

            &-wax {
                transform: translate(64%, 160%);
            }

            &-leaf {
                transform: translate(20.2%, 4%);
            }

            &-slider-leaf {
                bottom: 50%;
                transform: translate(67%, 33%);
            }

            &-slider-lower {
                top: 100%;
                transform: translate(-10%, -55%);
            }

            &-wave {
                transform: translate(40%, 0);
                mix-blend-mode: screen;
            }

            &-wave2 {
                transform: translate(53%, -13%);
            }
        }

        &.faded {
            opacity: 0.3;

            &-light {
                opacity: 0.7;
            }

            &-9 {
                opacity: 0.9;
            }
        }
    }
}
