.about-page {
    .banner {
        min-height: 500px;
        color: $white;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        .txt-upsized{
            line-height: 72px;
        }
        p {
            margin-bottom: 0;
        }
    }
    .txt-shadow{
        text-shadow: 0px 3px 6px #00000029;
    }
    .txt-14-px{
        font-size: 14px !important;
    }

    .end-section {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0.1;
            background: #164E36 0% 0% no-repeat padding-box;
        }
        @media(max-width: 415px){
            .container{
                .txt-upsized{
                    font-size: 45px !important;
                }
            }
        }
    }

    .about-us {
        .content {
            max-width: 750px;
            h3{
                font-size: 24px !important;
                line-height: 32px;
            }
            .description{
                line-height: 30px;
            }
        }
    }

    .testimonials, .white-bottom{
        background: white !important;
        .landscape{
            transform: scaleY(-1);
        }
    }

    .testimonials-section {
        background: white;
        .latest-news {
            position: relative;
        }
        .testimonials-grid {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-column-gap: 20px;
            grid-row-gap: 30px;
    
            .item {
                position: relative;
                padding: 110px 0 0;
                border-radius: 40px;
    
                transition: all 0.3s ease;
                margin-top: 100px;
    
                .txt-small{
                    font-size: 14px !important;
                    line-height: 21px;
                }
    
                h5{
                    font-size: 17px !important;
                }

                .txt-10-px{
                    font-size: 10px !important;
                }
    
                .txt-18-px{
                    font-size: 18px !important;
                }
                img {
                    max-width: 100%;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    max-height: 200px;
                    max-width: 100%;
                    transform: translate(-50%, -50%);
                }
    
            }
        }

        .btn-load {
            // font-weight: 500;
            display: flex;
            margin: auto;
            font-size: $medium;
            border: none;
            border-radius: 25px;
            padding: 10px 45px;
            background: $lGreen;
            // color: $dirtyWhite;
            max-width: 200px;
        }
    }
    @media (max-width: 991px) {
        .testimonials-section .testimonials-grid {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }


    @media (max-width: 575px) {
        .testimonials-section .testimonials-grid {
            grid-template-columns: 1fr;
        }
    }
}
