.cart-bag-container {
    .bag-icon-container {
        height: 100%;
        color: $gray;
        position: relative;
        font-size: 25px;
        cursor: pointer;

        .bag-count {
            background-color: white;
            color: var(--tooltip-color);
            border-radius: 100%;
            border: 1px solid var(--tooltip-color);
            width: 20px;
            height: 20px;
            font-size: $small;
            font-weight: bold;
            right: -5px;
            top: -5px;
            position: absolute;
            transition: $transitionFast;
        }

        i {
            color: var(--tooltip-color);
        }

        &:hover {
            .bag-count {
                top: -10px;
                transform: scale(1.2);
            }
        }
    }

    .cart-bag-dropdown-menu {
        margin-top: 0.5rem;
        padding: 20px 15px;
        // width: 300px;

        .cart-header {
            display: flex;
            align-items: center;

            > div:first-child {
                fleX: 1;
            }
        }

        .item-list {
            max-height: 300px;
            overflow: auto;
        }

        .cart-item {
            position: relative;
            border-radius: 5px;
            border: 1px solid $lightGray;
            margin-bottom: 10px;
            padding: 10px;
            width: 370px;

            .image-container {
                width: 50px;
                height: 50px;
                position: relative;
                margin-right: 10px;

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    -o-object-fit: contain;
                    object-fit: contain;
                    -o-object-position: center;
                    object-position: center;
                    background-color: #aaa;
                }
            }

            .item-details {
                flex: 1;
                position: relative;

                .delete-item {
                    position: absolute;
                    right: 0;
                    top: 0;
                    cursor: pointer;
                    transition: $transition;
                    opacity: 1;

                    &:hover {
                        opacity: .6;
                    }
                }

                .item-title {
                    font-size: 0.875rem;
                }

                .item-qty {
                    font-size: 0.875rem;
                }

                .item-price {
                    font-size: 0.75rem;
                }
            }
        }

        .seperator {
            margin-top: 20px;
            margin-bottom: 20px;
            width: 100%;
            height: 1px;
            background-color: $lightGray;
        }

        .cart-subtotal-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 10px 0;
            .subtotal-number {
                text-align: right;
            }
        }
        .button-container {
            .btn:last-child {
                margin-left: auto;
            }
        }
    }

    .user-links-dropdown {
        margin-top: 0.5rem;
        padding: 20px 15px;
        max-width: 400px;
        font-size: $small;

        ul {

            li {
                padding: 10px 0;
                position: relative;

                i {
                    width: 20px;
                    color: $gray;
                    transition: $transitionFast;
                }
                
                &:hover {

                    i:not(.user-indicator) {
                        transform: scale(1.3);
                        color: $orange;
                    }
                }
            }
        }
    }

    @media (max-width: 550px) {
        .cart-bag-dropdown-menu {

            .cart-item {
                max-width: 270px;
            }
        }
    }

    @media (max-height: 500px) {
        .cart-bag-dropdown-menu {
            .item-list {
                max-height: 200px;
            }
        }
    }
}