// ::-webkit-input-placeholder {
// 	font-style: italic;
// 	font-size: inherit;
//  }
//  :-moz-placeholder {
// 	font-style: italic;
// 	font-size: inherit;  
//  }
//  ::-moz-placeholder {
// 	font-style: italic;
// 	font-size: inherit;  
//  }
//  :-ms-input-placeholder {  
// 	font-style: italic;
// 	font-size: inherit; 
//  }

.form-control {
    &.form-control-lg {
        height: calc(1.5em + 1rem + 10px);
        font-size: 0.875rem;
        font-family: $quicksand-regular;
        color: $gray;
	}
	
	&.with-bottom-margin {
		margin-bottom: 20px;
	}

	&:focus {
		border-color:#ced4da;
		box-shadow: none;
	}
}
.form-group {
	margin-bottom: 0;
	width: 100%;
	> select {
		&.form-control {
			&.loading {
				background: url('../../assets/img/Loading_icon.gif') no-repeat right #ced4da;
				-webkit-appearance: none;
				background-size: 20px;
				background-position-x: calc(100% - 15px);
			}
		}
	}
	label {
		font-size: $medium;

		&.lbl-sm {
			font-size: $small;
		}
	}

}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus, .btn-primary:focus, .btn-primary.focus {
    box-shadow: none;
}
.btn {
	&.loading {
		pointer-events: none;
		position: relative;
	}
	
    .icon-container {
		padding-left: 20px;
		&.right {
			padding-right: 20px;
			padding-left: 0;
		}
    }
}
.input-with-icon-container {
	display: grid;
	grid-template-columns: 1fr auto;
	input {
		border-radius: 20px;
	}
	&.right-icon {
		input {
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
			border-right: 0;
		}
		.icon {
			background-color: $green;
			height: 100%;
			display: flex;
			align-items: center;
			color: #fff;
			padding: 0 20px;
			border-top-right-radius: 20px;
			border-bottom-right-radius: 20px;
			cursor: pointer;
		}
	}
}