.flex {
	display: flex;
	
	.flex-1 {
		flex: 1;
	}

	&.align-center {
		align-items: center;
	}

	&.justify-center {
		justify-content: center;
    }

	&.justify-between {
		justify-content: space-between;
    }

	&.justify-around {
		justify-content: space-around;
    }
    
    &.flex-end {
        justify-content: flex-end;
    }

    &.direction-column {
        flex-direction: column;
    }
}

.flex-flow {
    &-column {
        flex-flow: column;
    }
}
.align-items {
    &-center {
        align-items: center;
    }
}
.justify-content {
    &-end {
        justify-content: flex-end;
    }
}
.align-all {
    &-center {
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 991px) {

    .flex {

        &-rm-md {
            display: block;
        }
    }
}

@media (max-width: 767px) {

    .flex {

        &-rm-xs {
            display: block;
        }
    }
}