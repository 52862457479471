.shipping-form {
    .row {
        > div {
            margin-top: 5px;
            margin-bottom: 5px;
        }

        input,
        select {
            width: 100%;
            border: 1px solid $lighterGray;
            color: $lightMedGray;
            height: 40px;
            border-radius: 3px;
            padding: 6px 12px;
        }
    }
}
