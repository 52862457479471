@font-face {
    font-family: "quicksand-regular";
    src: url("../fonts/Quicksand-Regular.otf") format("opentype");
}

@font-face {
    font-family: "quicksand-dash";
    src: url("../fonts/Quicksand_Dash.otf") format("opentype");
}

@font-face {
    font-family: "quicksand-bold";
    src: url("../fonts/Quicksand-Bold.otf") format("opentype");
}

@font-face {
    font-family: "quicksand-bold-italic";
    src: url("../fonts/Quicksand-BoldItalic.otf") format("opentype");
}

@font-face {
    font-family: "quicksand-italic";
    src: url("../fonts/Quicksand-Italic.otf") format("opentype");
}

@font-face {
    font-family: "quicksand-light";
    src: url("../fonts/Quicksand-Light.otf") format("opentype");
}

@font-face {
    font-family: "quicksand-light-italic";
    src: url("../fonts/Quicksand-LightItalic.otf") format("opentype");
}

// POPPINS

@font-face {
    font-family: "poppins-regular";
    src: url("../fonts/Poppins-Regular.otf") format("opentype");
}

@font-face {
    font-family: "poppins-bold";
    src: url("../fonts/Poppins-Bold.otf") format("opentype");
}

@font-face {
    font-family: "poppins-italic";
    src: url("../fonts/Poppins-Italic.otf") format("opentype");
}

@font-face {
    font-family: "poppins-light";
    src: url("../fonts/Poppins-Light.otf") format("opentype");
}

@font-face {
    font-family: "poppins-light-italic";
    src: url("../fonts/Poppins-LightItalic.otf") format("opentype");
}

@font-face {
    font-family: "poppins-semi-bold";
    src: url("../fonts/Poppins-SemiBold.otf") format("opentype");
}

// CERA PRO

@font-face {
    font-family: "cerapro-regular";
    src: url("../fonts/CeraPro-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "cerapro-bold";
    src: url("../fonts/CeraPro-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "cerapro-medium";
    src: url("../fonts/CeraPro-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "cerapro-black";
    src: url("../fonts/CeraPro-Black.ttf") format("truetype");
}

.txt {
    // SIZE
    &-giantsize {
        font-size: $giantSize;
    }

    &-oversized {
        font-size: $oversized;
    }

    &-upsized {
        font-size: $upsized;
    }

    &-header {
        font-size: $h1;

        &-2 {
            font-size: $h2;
        }
    }
    &-large {
        font-size: $large;
    }
    &-xlarge {
        font-size: $xlarge;
    }
    &-xxlarge {
        font-size: $xxlarge;
    }
    &-medium {
        font-size: $medium;
    }
    &-small {
        font-size: $small;
    }

    // COLOR
    &-primary {
        color: $green !important;
    }

    &-light-green {
        color: $lGreen !important;
    }

    &-light-red {
        color: $lRed !important;
    }

    &-error {
        color: $red !important;
    }

    &-success {
        color: $green !important;
    }

    &-warning {
        color: $orange !important;
    }

    &-gray {
        color: $gray;

        &-med {
            color: $medGray;
            &-light {
                color: $lightMedGray;
            }
        }

        &-light {
            color: $lightGray;
        }

        &-lighter {
            color: $lighterGray;
        }

        &-dark {
            color: $dGray;
        }

        &-2 {
            color: $gray-2;
        }
    }

    &-white {
        color: #fff;
    }

    &-link {
        color: #356077;
    }

    &-dark-link {
        color: #284356;
    }

    // WEIGHT
    &-bold {
        font-weight: bold;

        &-qs-bold {
            font-family: $quicksand-bold;
        }

        &-pn-semi {
            font-family: $poppins-semi-bold;
        }

        &-black {
            font-weight: 800;
        }
    }

    &-regular {
        font-weight: normal;
    }

    &-light {
        font-family: $quicksand-light;
    }

    // CERA PRO
    &-cerapro {
        &-regular {
            font-family: $cerapro-regular;
        }

        &-medium {
            font-family: $cerapro-medium;
        }

        &-bold {
            font-family: $cerapro-bold;
        }

        &-black {
            font-family: $cerapro-black;
        }
    }

    //SETTINGS
    &-uppercase {
        text-transform: uppercase;
    }

    &-capitalize {
        text-transform: capitalize;
    }

    &-underlined {
        text-decoration: underline;
    }

    &-strike {
        text-decoration: line-through;

        &-element {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 2px;
                background-color: $lightGray;
                margin: auto 0;
            }
        }
    }

    &-lineheight-1 {
        line-height: 1;
    }

    @media (max-width: 991px) {
        &-oversized {
            font-size: $h1;
        }
        &-header {
            font-size: $h2;

            &-2 {
                font-size: $xxlarge;
            }
        }
        &-large {
            font-size: $medium;
        }
        &-xlarge {
            font-size: $large;
        }
        &-xxlarge {
            font-size: $xlarge;
        }
        &-medium {
            font-size: $small;
        }
    }

    @media (max-width: 767px) {
        &-oversized {
            font-size: $h2;
        }
        &-header {
            font-size: $xxlarge;

            &-2 {
                font-size: $xlarge;
            }
        }

        &-large {
            font-size: $medium;
        }
        &-xlarge {
            font-size: $medium;
        }
        &-xxlarge {
            font-size: $large;
        }
    }
}
