.products-page {

    .category-banner-header {
        height: 500px;
        background-size: cover;
        background-position: center center;
        overflow: hidden;
        
        .text-details {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: rgba(0, 0, 0, 0.5);

            > div {

                .header-icon {
                    height: 95px;
                    width: 95px;
                    object-fit: contain;
                    object-position: center;
                }

                .category-tags {
                    border-radius: 100px;
                    background: rgba(255, 255, 255, 0.2);

                    p {
                        margin: 0;
                    }
                }
            }
        }

        .transition-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            bottom: 0;
            background-color: #fff;
            animation-name: slideLeft;
            animation-duration: 1s;
            // animation-delay: 0.5s;
            animation-fill-mode: forwards;
            overflow: hidden;

            > div {
                background-size: cover;
                background-position: center center;
                position: relative;
            }
        }
    }

    .main-products-content {
        margin-left: 50px;

        .gray-control {
            border-radius: 50px;
            position: relative;
        }

        .icon-overlay {
            position: absolute;
            border-radius: 50px;
            height: 50%;
            background: #fff;
            pointer-events: none;
            right:5px;
            top: 25%;
            padding: 0 5px;
            display: flex;
            align-items: center;
        }
    }

    .category-section {
        max-width: 400px;
        width: 100%;
    }
    
    .product-filter-category-list {
        position: relative;
        margin-bottom: 30px;
        width: 100%;

        .mobile-category-toggle {
            position: fixed;
            bottom: 20px;
            right: 0px;
            z-index: 100;
            display: none;
            transform: translateX(100%);
            transition: $transitionFast;
        }

        .category-list  {
            padding: 0 50px;
        }

        .category-container {
            padding: 15px 20px;
            opacity: .6;
            transition: $transition;
            cursor: pointer;
            // border-top: 1px solid $medGray;

            .category-title {
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .sub-category-options {
                padding: 0 20px;
                transition: $transitionFast;
                max-height: 0px;
                overflow: hidden;

                .sub-option {
                    padding-top: 10px;
                    
                    span {
                        &:hover {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 991px) {
        // padding-top: 20px;

        .category-banner-header {
            height: 400px;
            .text-details {
    
                > div {
    
                    .header-icon {
                        height: 50px;
                        width: 50px;
                        object-fit: contain;
                        object-position: center;
                    }
                }

                .txt-giantsize {
                    font-size: 50px;
                }
            }
        }

        .category-section {
            max-width: 100%;
        }

        .page-container {
            padding-top: 0px;
        }

        .main-products-content {
            margin-left: 0;
            
            .category-header {
                height: 300px;
                background-size: cover;
                background-position: center center;
            }
        }
        
        .product-filter-category-list {
            max-width: 100%;
            
            .mobile-category-toggle {
                display: block;
                transform: translateX(-20px);
            }

            .category-nav-list {
                position: fixed;
                width: 300px;
                height: 100%;
                left: 0;
                top: 0;
                bottom: 0;
                z-index: 99999;
                background: #eee;
                padding: 30px;
                overflow: auto;
                transition: $transitionFast;
                transform: translateX(-100%);

                &.in {
                    transform: translateX(0%);
                }
            }
        }
    }

    @media (max-width: 767px) {

        .category-banner-header {
            height: 300px;
            text-align: center;

            .text-details {
    
                > div {
    
                    .header-icon {
                        height: 30px;
                        width: 30px;
                        object-fit: contain;
                        object-position: center;
                        margin: auto;
                        
                    }
                }

                .txt-giantsize {
                    font-size: 30px;
                }
            }
        }

        .main-products-content {

            .category-header {
                height: 200px;
            }
        }
    }
}