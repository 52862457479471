.product-view {
    .txt-green {
        color: #1d600c;
    }
    .line-height-21 {
        line-height: 21px;
    }
    .line-height-30 {
        line-height: 30px;
    }
    .modal-caboodle {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;
        background-color: rgba(0, 0, 0, 0.3);
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease-in-out;

        .floating-leaf {
            position: absolute;
            pointer-events: none;
            z-index: 5;

            &.left {
                left: 0;
                &-upper {
                    transform: translate(-33%, -70%);
                    width: 769px;
                }
                &.faded {
                    &-3 {
                        opacity: 0.3;
                    }
                }
            }

            &.right {
                right: 0;
                &-upper {
                    transform: translate(33%, -70%);
                    width: 769px;
                }
                &.faded {
                    &-3 {
                        opacity: 0.3;
                    }
                }
            }

            &.faded {
                opacity: 0.3;

                &-light {
                    opacity: 0.7;
                }

                &-9 {
                    opacity: 0.9;
                }
            }
        }

        &.open {
            pointer-events: auto;
            opacity: 1;
        }

        .modal-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 40px;

            .modal-ingredient,
            .modal-review {
                position: relative;
                background-color: $lYellow;
                text-align: center;
                width: 100%;

                .modal-exit {
                    position: absolute;
                    right: 0;
                    top: 0;
                    transform: translateX(150%);
                    border: none;
                    background-color: $white;
                    color: $gray;
                    border-radius: 20px;
                }

                .star-container {
                    button {
                        border: none;
                        background: none;
                        outline: none;
                        color: $lGreen;
                    }
                }
                .review-information {
                    height: 100%;
                    overflow-y: auto;
                    overflow-x: hidden;
                    position: relative;
                    z-index: 20;
                    /* width */
                    &::-webkit-scrollbar {
                        width: 5px;
                    }

                    /* Track */
                    &::-webkit-scrollbar-track {
                        background: #f1f1f1;
                    }

                    /* Handle */
                    &::-webkit-scrollbar-thumb {
                        background: rgb(151, 151, 151);
                        opacity: 0.5;
                    }

                    /* Handle on hover */
                    &::-webkit-scrollbar-thumb:hover {
                        background: rgb(122, 122, 122);
                        cursor: pointer;
                    }
                }
            }

            .modal-review {
                // overflow: hidden;
                position: relative;
                .images-container {
                    width: 100%;
                    .image {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        overflow: hidden;
                    }
                }
            }

            .modal-ingredient {
                padding: 30px 20px;
                max-width: 450px;
                border-radius: 10px;
                // height: 100%;
                .product-information {
                    height: 100%;
                    overflow-y: auto;
                    /* width */
                    &::-webkit-scrollbar {
                        width: 5px;
                    }

                    /* Track */
                    &::-webkit-scrollbar-track {
                        background: #f1f1f1;
                    }

                    /* Handle */
                    &::-webkit-scrollbar-thumb {
                        background: rgb(151, 151, 151);
                        opacity: 0.5;
                    }

                    /* Handle on hover */
                    &::-webkit-scrollbar-thumb:hover {
                        background: rgb(122, 122, 122);
                        cursor: pointer;
                    }
                    > img {
                        width: inherit;
                        margin-top: 20px;
                        margin-bottom: 20px;
                        max-width: 100%;
                        width: 70%;
                    }

                    .title {
                        margin-bottom: 20px;
                        color: $green;
                    }
                }
            }

            .modal-review {
                padding: 40px 50px;
                max-width: 650px;
                @media (max-width: 420px) {
                    padding: 40px 30px;
                }
            }
        }
    }

    .customer-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        background-color: $lRed;
        color: $white;
        border-radius: 50px;
    }

    .grid {
        .image-container {
            background-color: $lYellow;
            height: 100%;
            position: relative;
            overflow: hidden;

            > div {
                height: calc(100vh - 78px);
                position: relative;

                .main-asset {
                    width: 500px;
                    height: 500px;
                    margin: 0 auto;
                    max-height: 70vh;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        object-position: center;
                        object-fit: contain;
                    }
                }

                .nav-assets {
                    display: flex;
                    justify-content: center;
                    gap: 20px;
                    margin: 0 auto;
                    width: 550px;

                    button {
                        background: none;
                        background-color: rgba(0, 0, 0, 0.3);
                        border: none;
                        opacity: 0.2;
                        transition: opacity 0.15s ease-in-out;
                        color: $white;

                        &:hover {
                            opacity: 0.3;
                        }
                    }

                    > div {
                        height: 100px;
                        max-width: 120px;
                        width: 100%;
                        border: 1px dashed #cbcbcb;
                        border-radius: 10px;
                        padding: 15px;

                        img {
                            height: 100%;
                            width: 100%;
                            object-position: center;
                            object-fit: contain;
                        }
                    }
                }
            }
        }

        .content-container {
            > div {
                min-height: calc(50vh - 8px);
                padding-left: 30px;

                &.details-container {
                    .details {
                        padding-top: 50px;
                        padding-bottom: 50px;
                        height: 100%;
                        max-width: 550px;
                        .description {
                            line-height: 30px;
                            color: #6e6e6e;
                        }

                        .product-specification {
                            font-size: $medium;
                            i,
                            em {
                                margin-right: 5px;
                                border-radius: 50px;
                                background-color: $lYellow;
                                border: 1px solid $lightGray;
                                color: $green;
                                height: 25px;
                                width: 25px;
                                display: inline-flex;
                                align-items: center;
                                justify-content: center;
                                font-size: $small;
                            }
                        }

                        .variants-container {
                            button {
                                border: 2px solid $green;
                                background: none;
                                padding: 5px;
                                color: $green;
                                transition: all 0.15s ease;
                                min-width: 80px;

                                &.selected {
                                    background: $green;
                                    color: $white;
                                }

                                &:not(:last-child) {
                                    margin-right: 10px;
                                }
                            }
                        }

                        .price-container {
                            .price {
                                font-size: 32px !important;
                                min-width: 100px;
                            }

                            .quantity-container {
                                display: grid;
                                grid-template-columns: 1fr 1fr 1fr;
                                width: 130px;
                                height: 45px;
                                border: 1px solid $medGray;
                                border-radius: 30px;
                                color: $black;

                                > * {
                                    border: none;
                                    outline: none;
                                    height: 100%;
                                    width: 100%;
                                    text-align: center;
                                    background: none;
                                }
                            }

                            .checkout-btn {
                                min-width: 220px;
                            }
                        }
                    }
                }

                &.reviews-ingredients-container {
                    background: transparent
                        linear-gradient(180deg, #efefef 0%, #f4f4f4 100%) 0% 0%
                        no-repeat padding-box;
                    position: relative;
                    padding-bottom: 50px;

                    .r-i-navigation {
                        position: absolute;
                        top: 0;
                        left: 30px;
                        transform: translateY(-100%);
                        max-width: 550px;
                        width: 100%;
                        border-bottom: 1px solid $medGray;

                        button {
                            background: none;
                            border: none;
                            padding-bottom: 10px;

                            &.active {
                                color: $lGreen;
                                border-bottom: 1px solid $lGreen;
                            }
                        }
                    }

                    .reviews-ingredients {
                        max-width: 550px;
                        position: relative;
                        padding-top: 20px;

                        .ingredients-container,
                        .reviews-container {
                            opacity: 0;
                            pointer-events: none;
                            transition: opacity 0.15s ease-in-out;
                            width: 100%;
                            padding-right: 5px;
                            display: none;

                            /* width */
                            &::-webkit-scrollbar {
                                width: 5px;
                            }

                            /* Track */
                            &::-webkit-scrollbar-track {
                                background: #f1f1f1;
                            }

                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: rgb(151, 151, 151);
                                opacity: 0.5;
                            }

                            /* Handle on hover */
                            &::-webkit-scrollbar-thumb:hover {
                                background: rgb(122, 122, 122);
                                cursor: pointer;
                            }

                            &.active {
                                opacity: 1;
                                pointer-events: auto;
                                height: auto;
                                min-height: calc(50vh - 95px);
                                display: block;
                            }
                        }

                        .reviews-container {
                            .write-review {
                                background-color: $white;
                                border-radius: 15px;
                                padding: 15px;

                                .content {
                                    max-width: 250px;
                                }

                                .btn-clear {
                                    border-radius: 10px;
                                    border-width: 2px;
                                }
                            }

                            .review-item {
                                border-bottom: 1px solid $lighterGray;
                            }
                        }

                        .ingredients-container {
                            table {
                                width: 100%;

                                tr {
                                    border-bottom: 1px solid $medGray;

                                    td {
                                        padding: 8px 20px 8px 0;

                                        &:last-child {
                                            padding-right: 0;
                                        }

                                        .fa-circle {
                                            font-size: 8px;
                                            color: $lGreen;
                                            transform: translateY(-20%);
                                        }

                                        h4 {
                                            margin: 0;
                                        }

                                        .ingredient-description {
                                            max-width: 300px;
                                            width: 100%;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            color: $gray;

                                            p {
                                                white-space: nowrap;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                margin: 0;
                                            }
                                        }

                                        button {
                                            border: none;
                                            background: none;
                                            color: $lGreen;
                                            height: 100%;
                                            width: 30px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1100px) {
        .grid {
            .image-container {
                > div {
                    .main-asset {
                        width: 350px;
                    }

                    .nav-assets {
                        gap: 10px;
                        width: 400px;
                        max-width: 100%;
                        padding: 10;

                        > div {
                            height: 80px;
                            max-width: 80px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 820px) {
        .grid {
            grid-template-columns: 1fr;

            .image-container {
                height: auto;

                > div {
                    height: 380px;

                    .main-asset {
                        height: 250px;
                    }
                }
            }

            .content-container {
                > div {
                    padding-right: 30px;

                    .details {
                        margin: 0 auto;
                    }

                    &.reviews-ingredients-container {
                        .reviews-ingredients,
                        .r-i-navigation {
                            max-width: 100%;
                        }

                        .r-i-navigation {
                            width: auto;
                            right: 35px;
                        }
                    }
                }
            }
        }
    }
}

// .product-view-body {
//     position: relative;

//     .background-overlay {
//         position: absolute;
//         width: 100%;
//         height: 100%;
//         left: 0;
//         top: 0;
//         opacity: 0.1;
//     }
// }

// .product-view {
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     grid-gap: 20px;
//     padding-bottom: 30px;
//     margin-bottom: 50px;

//     .line-separator {
//         position: relative;
//         &:before {
//             content: '';
//             position: absolute;
//             top: 0;
//             right: -50%;
//             width: 200%;
//             height: 1px;
//             background: $lightGray;
//         }
//     }

//     .product-specifications {
//         padding-left: 15px;

//         li {
//             list-style-type: disc;
//         }
//     }

//     .related-product-list {
//         grid-column: 1/3;
//         padding-top: 30px;
//         margin-top: 60px;
//         // border: 0;
//     }

//     .product-review-container {
//         grid-column: 1/3;
//         padding-top: 60px;
//     }

//     .product-images {
//         margin: 0;
//         .main-image {
//             // @include commonBackground();
//             // background-size: contain;
//             // padding-top: 70%;
//             // height: 0;
//             object-fit: contain;
//             object-position: center;
//             height: 500px;
//             width: 100%;
//             transition: $transition;
//             background: #fff;
//         }

//         .thumbnail-list {

//             .thumbnail {
//                 width: calc(100% - 50px);
//             // height: 0;
//             // padding-top: 70%;
//             // @include commonBackground();
//                 object-fit: contain;
//                 object-position: center;
//                 margin: auto;
//                 transition: $transitionFast;
//                 cursor: pointer;
//                 background: #fff;

//                 &.selected {
//                     transform: scale(1.1);
//                     box-shadow: 0 5px 10px -3px rgba(0, 0, 0, 0.2);
//                 }
//             }

//             &.flex {
//                 > div {

//                     .thumbnail {
//                         width: 100%;
//                     }
//                 }
//             }
//         }

//         .slider-control {
//             background-color: rgba(0, 0, 0, 0.5);
//             // width: 50px;
//             position: relative;
//             padding: 10px;
//             color: #fff;
//             display: flex;
//             align-items: center;
//         }

//         &.ph-item {
//             .main-image,
//             .thumbnail {
//                 background-color: #ced4da;
//             }
//         }
//     }

//     .product-info {
//         padding: 0 30px;

//         .actions-container {
//             .action {

//                 &-qty {
//                     .form-group {
//                         width: 50px;
//                         input {
//                             width: 100%;
//                             border-radius: 10px;
//                             -webkit-appearance: textfield;
//                             -moz-appearance: textfield;
//                             appearance: textfield;
//                             &::-webkit-inner-spin-button,
//                             &::-webkit-outer-spin-button {
//                                 -webkit-appearance: none;
//                             }
//                         }
//                     }
//                     button {
//                         padding: 0;
//                         height: 30px;
//                         width: 30px;
//                     }
//                 }
//             }
//         }

//         .description-container {
//             line-height: 2;
//         }
//     }

//     .overall-rating {
//         border-radius: 10px;
//         border: 1px solid #eee;
//         box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.1);
//         background: #f5f5f5;
//         padding: 10px 15px;
//     }

//     .product-stars {
//         color: $orange;

//         i {
//             margin-right: 2px;
//         }
//     }
//     .product-variant-helper {
//         position: absolute;
//         width: 100%;
//         top: 0;
//         left: 0;
//         background: $orange;
//         border-radius: 5px;
//         transform: translateY(calc(-90% - 15px));
//         transition: $transitionFast;
//         pointer-events: none;
//         opacity: 0;
//         color: #fff;

//         &.show {
//             opacity: 1;
//             transform: translateY(calc(-100% - 15px));
//         }
//     }

//     @media (max-width: 1400px) {
//         .product-images {
//             .main-image {
//                 width: 100%;
//                 height: 300px;
//                 padding-top: 0;
//             }

//             .thumbnail-list {

//                 .thumbnail {
//                     width: calc(100% - 30px);
//                 }
//             }
//         }
//     }

//     @media (max-width: 991px) {
//         grid-template-columns: 1fr;

//         .related-product-list, .product-review-container {
//             grid-column: unset;
//         }

//         .product-images {

//             .thumbnail-list {

//                 .thumbnail {
//                     width: 50%;
//                 }
//             }
//         }
//     }

//     @media (max-width: 767px) {
//         .product-info {
//             padding: 0;
//         }

//         .product-images {
//             .main-image {
//                 height: 200px;
//             }
//         }
//     }

//     @media (max-width: 650px) {
//         > div {
//             margin: 0 7.5px;
//         }

//         .product-images {

//             .thumbnail-list {

//                 .thumbnail {
//                     width: 70%;
//                 }
//             }
//         }
//     }

//     @media (max-width: 400px) {

//         .action-qty {
//             text-align: center;

//             .flex {
//                 justify-content: center;
//             }
//         }

//         .action-buttons {
//             display: block;

//             button {
//                 width: 100%;
//                 margin: 10px 0;
//             }
//         }

//         .product-images {

//             .thumbnail-list {

//                 .thumbnail {
//                     width: 90%;
//                 }
//             }
//         }
//     }
// }

// .go-back {
//     grid-column: 1/3;
//     >a {
//         border-bottom: 1px solid $black;
//     }
// }

// .product-info {
//     .product-variant-helper {
//         position: absolute;
//         width: 100%;
//         top: 0;
//         left: 0;
//         background: $orange;
//         border-radius: 5px;
//         transform: translateY(calc(-90% - 15px));
//         transition: $transitionFast;
//         pointer-events: none;
//         opacity: 0;
//         color: #fff;

//         &.show {
//             opacity: 1;
//             transform: translateY(calc(-100% - 15px));
//         }
//     }
// }
