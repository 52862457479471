// Variables will be place here

$dirtyWhite: #f8f8f8;
$blue: #009ee2;
$lightBlue: #00aeef;
$darkBlue: #22314e;

$quicksand-regular: "quicksand-regular";
$quicksand-dash: "quicksand-dash";
$quicksand-bold: "quicksand-bold";
$quicksand-italic: "quicksand-italic";
$quicksand-light: "quicksand-light";
$quicksand-light-italic: "quicksand-light-italic";

// POPPINS
$poppins-regular: "poppins-regular";
$poppins-dash: "poppins-dash";
$poppins-bold: "poppins-bold";
$poppins-semi-bold: "poppins-semi-bold";
$poppins-italic: "poppins-italic";
$poppins-light: "poppins-light";
$poppins-light-italic: "poppins-light-italic";
$placeholderBg: #ced4da;

// CERA PRO
$cerapro-regular: "cerapro-regular";
$cerapro-bold: "cerapro-bold";
$cerapro-medium: "cerapro-medium";
$cerapro-black: "cerapro-black";

$green: #164e36;
$lGreen: #58a300;
$lYellow: #fcf7ea;
$black: #000000;
$dGray: #1d1d1b;
$gray: #4e4e4e;
$medGray: #5e5e5e;
$lightMedGray: #6f6f6f;
$lightGray: #c9c9c9;
$lighterGray: #cbcbcb;
$gray-2: #6e6e6e;
$orange: #fe4f00;
$lRed: #ff8464;
$red: #e20612;
$redPastel: #e52632;
$transition: 0.6s;
$transitionFast: 0.3s;
$navTopSpacing: 120px;
$navTopMobileSpacing: 140px;
$defaultGradient: linear-gradient(90deg, #e85523, #f47a21, #f19f19);

$giantSize: 120px;
$oversized: 70px;
$upsized: 60px;
$h1: 48px;
$h2: 36px;
$small: 12px;
$regular: 16px;
$medium: 15px;
$large: 18px;
$xlarge: 20px;
$xxlarge: 30px;

//bootstrap variables
$theme-colors: (
    "primary": $green,
    "secondary": $orange
);
